import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { environmentIndicatorActions as actions } from '.';
import { apiRequest } from '../../../../utils/request';
import { authProviderActions } from '../../AuthProvider/slice';

function* resetDemo() {
  try {
    const payload = yield call(apiRequest, {
      url: 'reset-demo',
      method: 'post',
      data: {},
    });

    yield put(authProviderActions.loadUserDataRequest());
    yield put(actions.resetDemoSuccess());
  } catch (error) {
    yield put(actions.resetDemoFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* environmentIndicatorSaga() {
  yield takeLatest(actions.resetDemoRequest.type, resetDemo);
}
