/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { green, grey, red } from '@mui/material/colors';
import {
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import Highcharts, {
  Chart,
  SeriesMapbubbleOptions,
  SeriesMapOptions,
  Tooltip,
  TooltipOptions,
} from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import map from '@highcharts/map-collection/custom/world.geo.json';
import { FlagIcon } from '../../../../components/FlagIcon';
import { ReportGraphicProps } from '../index';
import { useLocation } from 'react-router';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../../utils/usePrevious';

HighchartsMap(Highcharts);
HighchartsMore(Highcharts);

interface Props extends ReportGraphicProps {}

export function WorldMap(props: Props) {
  const { data, reportSettings } = props.graphic.data!;

  let chart;

  const [metric, setMetric] = React.useState<keyof typeof metricOptions>(
    reportSettings.metric || 'customsValue',
  );

  const [viewMode, setViewMode] = React.useState<keyof typeof viewModes>(
    reportSettings.viewMode || 'originCountry',
  );

  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    reportSettings.selectedCountry || null,
  );

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }
    if (
      reportSettings.selectedCountry &&
      !isEqual(reportSettings.selectedCountry, selectedCountry)
    ) {
      setSelectedCountry(reportSettings.selectedCountry);
    }
  }, [props.graphic.data]);

  React.useEffect(() => {
    if (props.graphic.data) props.onSettingsChange({ viewMode, metric });
  }, [metric, viewMode]);

  const oldSelectedCountry = usePrevious(selectedCountry);
  React.useEffect(() => {
    if (props.full && selectedCountry !== reportSettings.selectedCountry) {
      props.onSettingsChange({ metric, selectedCountry, viewMode }, true);
    }
    if (!selectedCountry) return;
    if (!props.full) {
      props.loadFullReport({ metric, viewMode, selectedCountry });
    }
  }, [selectedCountry]);

  if (!data) {
    return null;
  }

  const formatCurrency = variableFormatCurrency(1000000000);

  const metricOptions = {
    customsValue: {
      label: 'Customs Value',
      formatter: value => formatCurrency(value),
    },
    customsDutyPaid: {
      label: 'Customs Duty Paid',
      formatter: formatCurrency,
    },
    frequency: {
      label: 'Frequency',
      formatter: value => `${value} entries`,
    },
  };

  const viewModes = {
    originCountry: {
      label: 'Origin Country',
    },
    dispatchCountry: {
      label: 'Dispatch Country',
    },
  };

  const toggleSelectedCountry = code => {
    if (selectedCountry === code) {
      setSelectedCountry('');
    } else {
      setSelectedCountry(code);
    }
  };

  const afterChartCreated = (highcharts: Chart) => {
    if (!chart) chart = highcharts;
  };

  const getOptions = (): Highcharts.Options => ({
    credits: {
      enabled: false,
    },
    chart: {
      zoomType: 'xy',
      renderTo: 'container',
      events: {},
    },
    colorAxis: {
      minColor: (metric === 'customsDutyPaid' ? red : green).A100,
      maxColor: (metric === 'customsDutyPaid' ? red : green)['A700'],
    },

    title: {
      text: '',
    },

    legend: {
      enabled: false,
    },

    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },

    series: [
      {
        mapData: map,
        name: 'Countries',
        color: '#E0E0E0',
        enableMouseTracking: false,
      },
      {
        mapData: map,
        type: 'mapbubble',
        name: metricOptions[metric].label,
        allAreas: false,
        joinBy: ['iso-a2', 'code'],
        data: data.values[viewMode][metric]
          .filter(v => v.value)
          .map(v => ({
            code: v.code === 'EU' ? 'DE' : v.code,
            z: v.value,
            realCode: v.code,
            zAxis: selectedCountry === v.code ? 1000 : 0,
            color: selectedCountry
              ? selectedCountry === v.code
                ? undefined
                : grey['500']
              : undefined,
          })),

        minSize: 5,
        maxSize: '30%',
        events: {
          click: function (q) {
            toggleSelectedCountry((q.point as any).code);
          },
        },
        tooltip: {
          pointFormatter: function () {
            let country = data.values[viewMode][metric]
              .filter(v => v.code === (this as any).options.realCode)
              .reduce(v => v);

            return `<b>${country.country}</b> <br />${metricOptions[
              metric
            ].formatter(country.value)}`;
          },
        },
      },
    ] as Array<SeriesMapOptions | SeriesMapbubbleOptions>,
  });

  return (
    <Box sx={{ px: 3, py: 3, width: '100%' }}>
      <Grid
        container
        sx={{
          mb: 2,
          justifyContent: {
            xs: 'flex-start',
            lg: 'flex-end',
          },
        }}
        alignItems={'center'}
      >
        <Grid item xs={12} md={12} lg={'auto'}>
          <Typography component={'div'} variant={'subtitle2'}>
            Filter by
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={'auto'}>
          <Box
            sx={{
              ml: { xs: 0, lg: 2 },
              p: 2,
              backgroundColor: 'grey.A100',
            }}
          >
            <FormControl>
              <RadioGroup row>
                {Object.keys(viewModes).map(vmk => (
                  <FormControlLabel
                    key={`view-mode-options-${vmk}`}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    control={
                      <Radio
                        color="primary"
                        checked={viewMode === vmk}
                        onClick={() =>
                          setViewMode(vmk as keyof typeof viewModes)
                        }
                      />
                    }
                    label={`Showing ${viewModes[vmk].label}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={'auto'}>
          <Box
            sx={{
              ml: { xs: 0, md: 0.1 },
              p: 2,
              backgroundColor: 'grey.A100',
            }}
          >
            <FormControl>
              <RadioGroup row>
                {Object.keys(metricOptions).map(mk => (
                  <FormControlLabel
                    key={`metric-options-${mk}`}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    control={
                      <Radio
                        color="primary"
                        checked={metric === mk}
                        onClick={() =>
                          setMetric(mk as keyof typeof metricOptions)
                        }
                      />
                    }
                    label={`${metricOptions[mk].label}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={4} md={3}>
          <Box sx={{ backgroundColor: 'grey.A100', p: 2 }}>
            <Typography variant="subtitle2">
              Top Countries ({metricOptions[metric].label})
            </Typography>

            <Box sx={{ mt: 2 }}>
              {data.values[viewMode][metric]
                .slice(0, 5)
                .filter(d => !!d.code)
                .map(d => (
                  <ButtonBase
                    key={`country-button-${d.code}`}
                    component={'div'}
                    onClick={() => toggleSelectedCountry(d.code)}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>
                          <FlagIcon code={d.code} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{d.country}</Typography>
                        </Grid>
                      </Grid>
                      {metricOptions[metric].formatter(d.value)}
                    </Box>
                  </ButtonBase>
                ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions()}
            constructorType={'mapChart'}
            key={'worldMap'}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            callback={afterChartCreated}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
