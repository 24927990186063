import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { adjustmentReportsActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';

function* loadAdjustmentReports(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/submitted-reports`,
      method: 'get',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.loadAdjustmentReportsSuccess(payload));
  } catch (error) {
    yield put(actions.loadAdjustmentReportsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadAdjustmentReport(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/submitted-reports/${action.payload.id}`,
      method: 'get',
      data: {
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.loadAdjustmentReportSuccess(payload));
  } catch (error) {
    yield put(actions.loadAdjustmentReportFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* updateAdjustmentReport(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/update-report`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.updateAdjustmentReportSuccess(payload));
  } catch (error) {
    yield put(actions.updateAdjustmentReportFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* adjustmentReportsSaga() {
  yield takeLatest(
    actions.loadAdjustmentReportsRequest.type,
    loadAdjustmentReports,
  );
  yield takeLatest(
    actions.loadAdjustmentReportRequest.type,
    loadAdjustmentReport,
  );
  yield takeLatest(
    actions.updateAdjustmentReportRequest.type,
    updateAdjustmentReport,
  );
}
