import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { apiCallInitialState, ResultMetaData } from 'types/ApiCall';
import { customersSliceSaga } from './saga';
import { Customer, CustomersSliceState } from './types';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: CustomersSliceState = {
  customers: {
    loading: false,
    data: [],
  },
  save: apiCallInitialState,
  delete: apiCallInitialState,
  customer: {
    loading: false,
    data: undefined,
  },
};

const slice = createSlice({
  name: 'customersSlice',
  initialState,
  reducers: {
    loadCustomersRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.customers.loading = true;
      state.customers.error = undefined;
    },
    loadCustomersSuccess(
      state,
      action: PayloadAction<{ data: Array<any>; meta: ResultMetaData }>,
    ) {
      state.customers.loading = false;
      state.customers.data = action.payload.data;
      state.customers.meta = action.payload.meta;
    },
    loadCustomersFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.customers.loading = false;
      state.customers.error = action.payload;
    },
    loadCustomerUnmount(state, action: PayloadAction<void>) {
      state.customer.loading = false;
      state.customer.error = undefined;
      state.customer.data = undefined;
      state.save.loading = false;
      state.save.error = undefined;
      state.save.data = undefined;
      state.delete.loading = false;
      state.delete.error = undefined;
      state.delete.data = undefined;
    },
    loadCustomerRequest(state, action: PayloadAction<{ id: number }>) {
      state.customer.loading = true;
      state.customer.error = undefined;
    },
    loadCustomerSuccess(
      state,
      action: PayloadAction<{
        data: Customer;
      }>,
    ) {
      state.customer.loading = false;
      state.customer.data = action.payload.data;
    },
    loadCustomerFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.customer.loading = false;
      state.customer.error = action.payload;
    },
    deleteCustomerRequest(state, action: PayloadAction<{ id: number }>) {
      state.delete.loading = true;
      state.delete.error = undefined;
    },
    deleteCustomerSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.delete.loading = false;
      state.delete.data = action.payload.status;
    },
    deleteCustomerFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    saveCustomerRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.save.loading = true;
      state.save.error = undefined;
    },
    saveCustomerUnmount(state, action: PayloadAction<void>) {
      state.save.loading = true;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    saveCustomerSuccess(state, action: PayloadAction<{ data: Customer }>) {
      state.save.loading = false;
      state.save.data = action.payload.data;
    },
    saveCustomerFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.save.loading = false;
      state.save.error = action.payload;
    },
  },
});

export const { actions: customersSliceActions } = slice;

export const useCustomersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customersSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCustomersSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
