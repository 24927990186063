/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectIsLoggedIn,
  selectUserData,
} from 'app/containers/AuthProvider/slice/selectors';
import useHotjar from 'react-use-hotjar';
import { GlobalStyle } from '../styles/global-styles';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Login } from './containers/Login/Loadable';
import { Portal } from './containers/Portal';
import { ForgotPassword } from './containers/ForgotPassword/Loadable';
import { ResetPassword } from './containers/ResetPassword/Loadable';
import { EnvironmentIndicator } from './containers/EnvironmentIndicator';
import { TUserInfo } from 'react-use-hotjar/dist/types';
import {
  selectCurrentCustomerName,
  selectCurrentCustomers,
} from './containers/CustomerSelector/slice/selectors';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Box, Container } from '@mui/material';

export function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const userData = useSelector(selectUserData);
  const currentCustomerName = useSelector(selectCurrentCustomerName);
  const defaultPage = '/portal';
  const { identifyHotjar, stateChange } = useHotjar();
  const siteId = process.env.REACT_APP_HOTJAR_SITEID || 0;

  useEffect(() => {
    if (!siteId) return;

    if (isLoggedIn && userData && currentCustomerName) {
      const user: TUserInfo = {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        env: process.env.REACT_APP_ENV || '',
        customer_name: currentCustomerName,
      };
      identifyHotjar(userData.id as any, user, () => {
        //console.info('HJ Identify: ' + userData.id, user);
      });
    }
  }, [isLoggedIn, userData, currentCustomerName]);

  useEffect(() => {
    if (!siteId) return;
    stateChange(location.pathname + location.search, () => {
      //console.info('HJ State Change: ', location);
    });
  }, [location.pathname + location.search]);

  return (
    <Box sx={{ minWidth: 360 }}>
      <EnvironmentIndicator />
      <Helmet titleTemplate="%s - CAT" defaultTitle="CAT">
        <meta name="description" content="Customs Analytics Tool" />
      </Helmet>
      <Switch>
        <ProtectedRoute
          exact
          path="/forgot-password"
          isAccessible={!isLoggedIn}
          redirectToPath="/portal"
          component={ForgotPassword}
        />
        <ProtectedRoute
          exact
          path="/reset-password"
          isAccessible={!isLoggedIn}
          redirectToPath="/portal"
          component={ResetPassword}
        />
        <ProtectedRoute
          exact
          isAccessible
          path="/login/:key"
          redirectToPath="/portal"
          component={Login}
        />
        <ProtectedRoute
          exact
          path="/login"
          isAccessible={!isLoggedIn}
          redirectToPath="/portal"
          component={Login}
        />
        <ProtectedRoute
          path={'/portal'}
          isAccessible={isLoggedIn}
          redirectToPath="/login"
          component={Portal}
        />
        <Route render={() => <Redirect to={defaultPage} />} />
      </Switch>
      <GlobalStyle />
    </Box>
  );
}
