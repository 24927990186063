import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { get } from 'lodash';

const selectSlice = (state: RootState) =>
  state.customerSelector || initialState;

export const selectCustomerSelector = createSelector(
  [selectSlice],
  state => state,
);

export const selectCurrentCustomers = createSelector(
  [selectSlice],
  state => state.currentCustomers,
);

export const selectAvailableCustomers = createSelector(
  [selectSlice],
  state => state.availableCustomers,
);

export const selectCurrentCustomerName = createSelector([selectSlice], state =>
  state.currentCustomers.length === 1
    ? (
        state.availableCustomers.find(
          customer => customer.id === state.currentCustomers[0],
        ) || {}
      ).name
    : null,
);

export const selectOpen = createSelector([selectSlice], state => state.open);
