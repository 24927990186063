import React from 'react';
import {
  AppBar,
  Badge,
  Box,
  ButtonBase,
  Container,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Attachment,
  BarChart,
  Close,
  Home,
  Menu,
  Person,
  PersonOutlineRounded,
  PowerSettingsNew,
} from '@mui/icons-material';
import { CustomerSelectButton } from 'app/containers/CustomerSelector';
import { Link } from 'react-router-dom';
import { User, UserPermission } from 'app/containers/AuthProvider/slice/types';
import { ImportStatusData } from 'app/containers/ImportData/slice/types';
import { authProviderActions } from '../../containers/AuthProvider/slice';
import { useConfirm } from '../ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'images/logo-white.svg';
import { hasPermission } from '../../../utils/user';
import { selectPortalMenuOpen } from '../../containers/Portal/slice/selectors';
import { portalActions } from '../../containers/Portal/slice';
import { selectUserData } from '../../containers/AuthProvider/slice/selectors';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    '&.Mui-disabled': {
      color: theme.palette.grey[500],
      opacity: 0.6,
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0),
    },
  },
  title: {
    padding: '8px 8px 8px 0',
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    '& svg': {
      maxHeight: 40,
      width: 'auto',
    },
  },
  envTag: {
    position: 'absolute',
    zIndex: 40,
  },

  portalMenu: {
    marginLeft: 'auto',
  },
}));

export interface Props {
  userData: User;
  importDataStatus?: ImportStatusData;
  currentCustomers: Array<number>;
}

export default function PortalAppBar(props: Props) {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const menuOpen = useSelector(selectPortalMenuOpen);
  const userData = useSelector(selectUserData);

  const setMenu = (openState: boolean) =>
    dispatch(portalActions.setMenuOpen(openState));

  const importBadgeIcon = (
    <Badge
      variant="dot"
      color={
        props.importDataStatus?.status === 'pending' ? 'error' : 'secondary'
      }
      invisible={
        !props.importDataStatus?.status ||
        props.importDataStatus?.status === 'clean' ||
        props.currentCustomers.length !== 1
      }
    >
      <Attachment />
    </Badge>
  );

  return (
    <React.Fragment>
      <AppBar elevation={0} square position="sticky">
        <Toolbar>
          <Box className={classes.title}>
            <ButtonBase component={Link} to={'/portal'}>
              <Typography variant="h6">
                <Logo />
              </Typography>
            </ButtonBase>
          </Box>
          <CustomerSelectButton />
          <Box className={classes.portalMenu}>
            <Tooltip title={'Home Dashboard'}>
              <Box component={'span'}>
                <IconButton
                  sx={{
                    display: {
                      xs: 'inline-flex',
                      sm: 'none',
                    },
                  }}
                  color="inherit"
                  aria-label="menu"
                  className={classes.menuButton}
                  component={Link}
                  to={'/portal'}
                >
                  <Home />
                </IconButton>
                <Button
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'inline-flex',
                    },
                  }}
                  variant="text"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  component={Link}
                  to={'/portal'}
                  size="large"
                >
                  Dashboard
                </Button>
              </Box>
            </Tooltip>

            <Tooltip title={'Import Data'}>
              <Box component={'span'}>
                <IconButton
                  sx={{
                    display: {
                      xs: 'inline-flex',
                      sm: 'none',
                    },
                  }}
                  disabled={
                    !(
                      props.currentCustomers.length === 1 &&
                      hasPermission(props.userData, UserPermission.UPLOAD_DATA)
                    )
                  }
                  className={classes.menuButton}
                  component={Link}
                  to={'/portal/import'}
                  size="large"
                  color="inherit"
                  aria-label="menu"
                >
                  {importBadgeIcon}
                </IconButton>
                <Button
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'inline-flex',
                    },
                  }}
                  variant="outlined"
                  startIcon={importBadgeIcon}
                  disabled={
                    !(
                      props.currentCustomers.length === 1 &&
                      hasPermission(props.userData, UserPermission.UPLOAD_DATA)
                    )
                  }
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  component={Link}
                  to={'/portal/import'}
                  size="large"
                >
                  Import data
                </Button>
              </Box>
            </Tooltip>

            <Tooltip title={'Toggle Menu'}>
              <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={ev => setMenu(!menuOpen)}
                size="large"
              >
                {menuOpen ? <Close /> : <Menu />}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor={'right'}
        open={menuOpen}
        onClose={ev => setMenu(false)}
        onOpen={ev => setMenu(true)}
        PaperProps={{
          sx: {
            backgroundColor: theme => theme.palette.grey[400],
          },
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            width: '100vw',
          }}
        >
          <Box sx={{ px: 2, pt: 8, pr: 8, position: 'relative' }}>
            <Box sx={{ position: 'fixed', top: 0, right: 0, p: 1 }}>
              <IconButton onClick={ev => setMenu(false)} size={'large'}>
                <Close fontSize={'large'} />
              </IconButton>
            </Box>
            <PersonOutlineRounded
              sx={{ fontSize: 40, border: 2, borderRadius: 50 }}
            />
            {!!userData && (
              <Box sx={{ my: 2 }}>
                <Typography variant={'h5'}>
                  {userData.first_name} {userData.last_name}
                </Typography>
                <Typography variant={'body1'}>{userData.email}</Typography>
              </Box>
            )}
          </Box>
          <Box>
            <List
              sx={{
                a: {
                  color: 'grey.800',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                },
              }}
            >
              <ListItem sx={{ mt: 2 }}>
                <ListItemText primary={<b>Data management &amp; analysis</b>} />
              </ListItem>
              <ListItem component={Link} to={'/portal'}>
                <ListItemText primary={<>Home Dashboard</>} />
              </ListItem>
              <ListItemButton
                component={Link}
                to={'/portal/import'}
                disabled={
                  !(
                    props.currentCustomers.length === 1 &&
                    hasPermission(props.userData, UserPermission.UPLOAD_DATA)
                  )
                }
              >
                <ListItemText primary={<>Import Data</>} />
              </ListItemButton>
              <ListItem sx={{ mt: 2 }}>
                <ListItemText primary={<b>Adjustment Reporting</b>} />
              </ListItem>
              <ListItem component={Link} to={'/portal/report/all'}>
                <ListItemText primary={<>All Reports</>} />
              </ListItem>
              <ListItem component={Link} to={'/portal/report/admended-items'}>
                <ListItemText primary={<>Amended Items</>} />
              </ListItem>
              <ListItem component={Link} to={'/portal/report/pending-approval'}>
                <ListItemText primary={<>Pending Approval</>} />
              </ListItem>
              <ListItem component={Link} to={'/portal/report/accepted'}>
                <ListItemText primary={<>Accepted Adjustment Reports</>} />
              </ListItem>
              <ListItem component={Link} to={'/portal/report/import'}>
                <ListItemText primary={<>Import Mass Adjustment</>} />
              </ListItem>

              <ListItem sx={{ mt: 2 }}>
                <ListItemText primary={<b>Settings</b>} />
              </ListItem>

              <ListItem component={Link} to={'/portal/accounts/update'}>
                <ListItemText primary={<>Account Settings</>} />
              </ListItem>
              <ListItemButton
                component={Link}
                to={'/portal/suppliers'}
                disabled={
                  !(
                    props.currentCustomers.length === 1 &&
                    hasPermission(props.userData, UserPermission.UPLOAD_DATA)
                  )
                }
              >
                <ListItemText primary={<>Manage suppliers</>} />
              </ListItemButton>
              {hasPermission(userData, UserPermission.ADMINISTER_SYSTEM) && (
                <>
                  <ListItem sx={{ mt: 2 }}>
                    <ListItemText primary={<b>Admin</b>} />
                  </ListItem>

                  <ListItem component={Link} to={'/portal/accounts/users'}>
                    <ListItemText primary={<>Manage users</>} />
                  </ListItem>
                  <ListItem component={Link} to={'/portal/accounts/customers'}>
                    <ListItemText primary={<>Manage customers</>} />
                  </ListItem>
                </>
              )}

              <ListItem
                sx={{ mt: 4 }}
                onClick={ev => {
                  confirm({
                    description: 'Log out of the portal - are you sure?',
                  })
                    .then(() =>
                      dispatch(authProviderActions.userLogoutRequest()),
                    )
                    .catch(() => {});
                }}
              >
                <ListItemText primary={<>Log out</>} />
              </ListItem>
            </List>
          </Box>
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
