import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GA4React, { useGA4React } from 'ga-4-react';
import { useSelector } from 'react-redux';
import { selectUser } from '../app/containers/Users/slice/selectors';
import { selectUserData } from '../app/containers/AuthProvider/slice/selectors';

export const usePageTracking = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const user = useSelector(selectUserData);
  const code = process.env.REACT_APP_GA_CODE;
  const location = useLocation();

  useEffect(() => {
    const ga4 = new GA4React(code || '');
    ga4
      .initialize()
      .then(ga4 => {
        setInitialized(() => true);
        ga4.gtag('config', 'GA_MEASUREMENT_ID', {
          user_id: (user || {}).id,
        });
        ga4.pageview(location.pathname + location.search);
      })
      .catch(e => console.log('GA already initialised.'));
  }, [user]);
};
