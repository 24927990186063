/**
 *
 * AdjustmentReports
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdjustmentReportsSlice } from './slice';

import {
  selectLoadAdjustmentReport,
  selectUpdateAdjustmentReport,
} from './slice/selectors';
import {
  Paper,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useRouteMatch } from 'react-router';
import { ManagementTable } from '../../components/ManagementTable';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { format, parseISO } from 'date-fns';
import { ChevronLeft, Close, Delete, Edit, Undo } from '@mui/icons-material';
import { useConfirm } from '../../components/ConfirmDialog';
import { lineFieldActions } from '../LineAdjustmentManager/slice';
import { ChangeAction } from '../LineAdjustmentManager/slice/types';
import { PopoverButton } from '../../components/PopoverButton';
import { AdjustmentDetails } from '../../components/AdjustmentDetails';
import { amendedItemsActions } from 'app/containers/AmendedItems/slice';
import { selectCurrentCustomers } from '../CustomerSelector/slice/selectors';
import { selectConfirmAdjustment } from '../LineAdjustmentManager/slice/selectors';
import { selectRevertAdjustments } from '../AmendedItems/slice/selectors';
import { Link } from 'react-router-dom';
import { serveFile } from '../../../utils/request';
import { useDeepCompareMemoize } from '../../../utils/useDeepCompareMemoize';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { useEffect, useRef } from 'react';
import { useHasChanged, usePrevious } from '../../../utils/usePrevious';
import { slugify } from '../../../utils/slugify';

interface Props {
  id?: number;
}

export function AdjustmentReport(props: Props) {
  const { actions } = useAdjustmentReportsSlice();

  interface MatchParams {
    id: string;
  }

  const match = useRouteMatch<MatchParams>();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const currentCustomers = useSelector(selectCurrentCustomers);
  const currentCustomer =
    currentCustomers.length === 1 ? currentCustomers[0] : '';
  const adjustmentReport = useSelector(selectLoadAdjustmentReport);
  const confirmAdjustment = useSelector(selectConfirmAdjustment);
  const revertAdjustments = useSelector(selectRevertAdjustments);
  const updateAdjustmentReport = useSelector(selectUpdateAdjustmentReport);

  const [selectedItems, setSelectedItems]: [
    selectedItems: number[],
    setSelectedItems: Function,
  ] = React.useState([]);

  const [editingNotes, setEditingNotes] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>('');

  const loadReport = () => {
    dispatch(actions.loadAdjustmentReportRequest({ id: +match.params.id }));
  };

  const saveNotes = () => {
    setEditingNotes(false);
    dispatch(
      actions.updateAdjustmentReportRequest({
        action: 'update-notes',
        notes,
        id: adjustmentReport.data!.id,
      }),
    );
  };

  const getExportedData = () => {
    serveFile(`line-data/export-report/${adjustmentReport.data!.id}`, {
      customer_id: currentCustomer,
    })
      .then(() => console.log('OK'))
      .catch(e => {
        console.log(e);
      });

    dispatch(
      actions.exportAdjustmentReportRequest({ id: adjustmentReport.data!.id }),
    );
  };

  React.useEffect(() => {
    loadReport();
    return () => {
      dispatch(actions.initialise());
    };
  }, []);

  React.useEffect(() => {
    if (adjustmentReport.data) {
      setNotes(adjustmentReport.data.notes || '');
    } else {
      setNotes('');
    }
  }, [adjustmentReport]);

  useCustomerSelector(loadReport);

  const updateLoadingHasChanged = useHasChanged(updateAdjustmentReport.loading);
  const confirmLoadingHasChanged = useHasChanged(confirmAdjustment.loading);
  const revertLoadingHasChanged = useHasChanged(revertAdjustments.loading);

  useEffect(() => {
    if (
      (confirmLoadingHasChanged && !confirmAdjustment.loading) ||
      (revertLoadingHasChanged && !revertAdjustments.loading) ||
      (updateLoadingHasChanged && !updateAdjustmentReport.loading)
    ) {
      loadReport();
    }
  });

  /*
  
    const prev = usePrevious(updateAdjustmentReport.loading);
  React.useEffect(() => {
    if (prev === undefined) return;
    if (updateAdjustmentReport.loading) loadReport();
  }, [updateAdjustmentReport.loading]);

  React.useEffect(() => {
    if (!confirmAdjustment.loading) {
      loadReport();
    }
  }, [confirmAdjustment.loading]);

  React.useEffect(() => {
    if (!revertAdjustments.loading) {
      loadReport();
    }
  }, [revertAdjustments.loading]);

  */
  //  if (!adjustmentReport.data) return null;

  const editAdjustments = (change_type: ChangeAction, ids: number[]) => {
    confirm({
      description:
        'Editing adjustments - you will need to resubmit this report if you continue. Are you sure?',
    }).then(() =>
      dispatch(
        lineFieldActions.adjustmentIntentRequest({
          change: change_type,
          ids: ids,
        }),
      ),
    );
  };
  const removeAdjustments = (removed_ids: Array<number>, revert = false) => {
    const remaining_ids = adjustmentReport
      .data!.adjustments.filter(adj => removed_ids.indexOf(adj.id) === -1)
      .map(adj => adj.id);

    if (revert) {
      confirm({
        description: 'Revert adjustment and remove from amended list.',
      })
        .then(() =>
          dispatch(
            amendedItemsActions.revertAdjustmentsRequest({
              ids: removed_ids,
            }),
          ),
        )
        .catch();
    } else {
      confirm({
        description:
          'Returning adjustment(s) to Amended list and removing' +
          ' from this report. Are you sure?',
      }).then(() => {
        dispatch(
          actions.updateAdjustmentReportRequest({
            id: adjustmentReport.data!.id,
            adjustment_ids: remaining_ids,
            action: 'sync-adjustments',
          }),
        );
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={'h1'} color={'primary'}>
          Viewing adjustment report
        </Typography>
        <Button
          variant="text"
          startIcon={<ChevronLeft />}
          sx={{ marginLeft: 'auto' }}
          component={Link}
          to={`/portal/report/${slugify(
            adjustmentReport.data ? adjustmentReport.data.status : '',
          )}`}
        >
          Back to reports
        </Button>
      </Box>
      <Paper sx={{ my: 4, width: '100%' }}>
        {!adjustmentReport.data || adjustmentReport.loading ? (
          <LoadingIndicator minHeight={360} />
        ) : (
          <>
            <Box sx={{ minHeight: 450 }}>
              <Box>
                <ManagementTable
                  rows={adjustmentReport.data.adjustments}
                  columns={[
                    {
                      label: 'Adjusted Date',
                      value: row =>
                        format(
                          parseISO(row.adjusted_date as string),
                          'dd/MM/yyyy',
                        ),
                    },
                    {
                      label: 'Entry Date',
                      value: row =>
                        format(
                          parseISO(row.entry_date as string),
                          'dd/MM/yyyy',
                        ),
                    },
                    {
                      label: 'Line Reference',
                      value: 'line_code',
                    },
                    {
                      label: 'Adjustment type',
                      value: row => row.change_type,
                    },
                    {
                      label: 'Adjustment',
                      value: row => {
                        return (
                          <PopoverButton
                            sx={{ display: 'inline-block' }}
                            buttonProps={{
                              size: 'small',
                              variant: 'outlined',
                              color: 'secondary',
                            }}
                            buttonText={'Show changes'}
                            popoverContent={
                              <AdjustmentDetails actions={row.actions} />
                            }
                          />
                        );
                      },
                    },
                    ...(adjustmentReport.data.status === 'pending approval'
                      ? [
                          {
                            classes: 'align-right',
                            value: row => {
                              return (
                                <>
                                  <Tooltip title={'Revert adjustment'}>
                                    <IconButton
                                      onClick={() =>
                                        removeAdjustments([row.id], true)
                                      }
                                      size="large"
                                    >
                                      <Close />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={'Return to amended list'}>
                                    <IconButton
                                      onClick={() =>
                                        removeAdjustments([row.id])
                                      }
                                      size="large"
                                    >
                                      <Undo />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={'Edit this adjustment.'}>
                                    <IconButton
                                      onClick={() =>
                                        editAdjustments(row.change_type, [
                                          row.line_item_id,
                                        ])
                                      }
                                      size="large"
                                    >
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              );
                            },
                          },
                        ]
                      : []),
                  ]}
                  onClick={() => {}}
                  page={1}
                  onChangePage={() => {}}
                  rowsPerPage={1000}
                  rowsPerPageOptions={[]}
                  count={1000}
                  loading={adjustmentReport.loading}
                  loadingComponent={<LoadingIndicator />}
                  rowClasses={{}}
                  noDataMessage={'No adjustments included for this report.'}
                  onSelectionChange={ids => {
                    setSelectedItems(ids);
                  }}
                />
              </Box>
              <Box sx={{ p: 2, mt: 2 }}>
                <Typography variant={'h6'}>
                  {!editingNotes && (
                    <IconButton
                      edge={'start'}
                      size="small"
                      onClick={() => setEditingNotes(true)}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  Report Notes
                </Typography>

                {editingNotes ? (
                  <>
                    <Box sx={{ pt: 1, pb: 1 }}>
                      <TextField
                        value={notes}
                        multiline
                        fullWidth
                        onChange={ev => setNotes(ev.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Button
                        size={'small'}
                        variant="text"
                        onClick={() => setEditingNotes(false)}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        color={'primary'}
                        size={'small'}
                        onClick={() => saveNotes()}
                      >
                        Save notes
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {!!notes && (
                      <Box sx={{ pt: 2, py: 4 }}>
                        <Typography>{notes}</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                color={'primary'}
                size={'large'}
                onClick={() => getExportedData()}
              >
                Download exported data
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
}
