import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { Customer, CustomerSelectorState } from './types';
import { loadState } from '../../../../store/localStorage';

export const initialState: CustomerSelectorState = {
  currentCustomers: [],
  availableCustomers: [],
  ...(loadState().customerSelector || {}),
  open: false,
};

const slice = createSlice({
  name: 'customerSelector',
  initialState,
  reducers: {
    initialiseCurrentCustomers(state, action: PayloadAction<void>) {},
    setAvailableCustomers(state, action: PayloadAction<Array<Customer>>) {
      state.availableCustomers = action.payload;
    },
    setCurrentCustomers(state, action: PayloadAction<Array<number>>) {
      state.currentCustomers = action.payload;
    },
    setCustomerSelectOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
  },
});

export const { actions: customerSelectorActions } = slice;

export const useCustomerSelectorSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
