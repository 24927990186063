import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { apiRequest, setToken } from '../../../../utils/request';
import { accountSettingsActions as actions } from './';

function* changePassword(action: {
  type: string;
  payload: {
    old_password: string;
    new_password: string;
    password_confirmation: string;
  };
}) {
  try {
    const {
      old_password,
      new_password,
      password_confirmation,
    } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'users/change-password',
      method: 'put',
      data: {
        old_password,
        new_password,
        password_confirmation,
      },
    });
    if (payload.token) {
      yield call(setToken, payload.token);
    }

    yield put(actions.changePasswordSuccess(payload));
  } catch (error) {
    yield put(actions.changePasswordFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* accountSettingsSaga() {
  yield takeLatest(actions.changePasswordRequest.type, changePassword);
}
