/**
 *
 * Customers
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomersSlice } from './slice';
import { ManagementTable } from '../../components/ManagementTable';
import { selectCustomers } from './slice/selectors';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Person, PersonAdd, Search } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { useDebounce } from '../../../utils/useDebounce';

interface Props {}

export function List(props: Props) {
  const match = useRouteMatch();
  const { actions } = useCustomersSlice();
  const dispatch = useDispatch();
  const customers = useSelector(selectCustomers);

  const [pageSize, setPageSize] = React.useState<number>(100);
  const [page, setPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(undefined);
  const [filters, setFilters] = React.useState<{ [key: string]: any }>({
    name: '',
  });

  const debouncedFilters = useDebounce(filters, 700);
  React.useEffect(() => {
    loadCustomers();
  }, [dispatch, debouncedFilters]);

  const loadCustomers = () => {
    dispatch(
      actions.loadCustomersRequest({
        ...filters,
        [`page[number]`]: page,
        [`page[size]`]: pageSize,
        sort,
      }),
    );
  };

  return (
    <Box sx={{ my: 4 }}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Grid item>
          <Typography variant="h1" color={'primary'}>
            All customers
          </Typography>
        </Grid>
        <Grid item sm>
          <Paper>
            <TextField
              placeholder={'Search by customer name'}
              InputProps={{
                sx: { p: 1 },
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={filters.name}
              onChange={ev => setFilters({ ...filters, name: ev.target.value })}
              variant={'standard'}
            />
          </Paper>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            size={'large'}
            startIcon={<PersonAdd color={'secondary'} />}
            component={Link}
            to={`${match.path}/new`}
          >
            Add new customer
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ my: 2 }}>
        <ManagementTable
          rows={customers.data}
          columns={[
            {
              value: 'name',
              label: 'Customer Name',
            },
            {
              value: 'contact_name',
              label: 'Contact Name',
            },
            {
              classes: 'align-right',
              value: v => {
                return (
                  <Button
                    size={'large'}
                    component={Link}
                    to={{
                      pathname: `/portal/accounts/users`,
                      state: {
                        customer_id: v.id,
                      },
                    }}
                    startIcon={<Person color={'secondary'} />}
                  >
                    {v.users_count}
                  </Button>
                );
              },
            },
            {
              classes: 'align-right',
              value: v => {
                return (
                  <Button
                    sx={{ my: 1 }}
                    variant={'contained'}
                    onClick={() => {}}
                    color={'primary'}
                    component={Link}
                    to={`${match.path}/${v.id}`}
                  >
                    Edit Customer
                  </Button>
                );
              },
            },
          ]}
          onClick={() => {}}
          page={1}
          onChangePage={() => {}}
          rowsPerPage={100}
          rowsPerPageOptions={[100]}
          count={5}
          loading={customers.loading}
          loadingComponent={
            <LoadingIndicator minHeight={300} message={'Loading data'} />
          }
        />
      </Paper>
    </Box>
  );
}
