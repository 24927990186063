import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loggingProviderSaga } from './saga';
import { IUserAction, LoggingProviderState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: LoggingProviderState = {
  logUserActions: apiCallInitialState,
  userActionQueue: [],
};

const slice = createSlice({
  name: 'loggingProvider',
  initialState,
  reducers: {
    pushActionToQueue(state, action: PayloadAction<IUserAction>) {
      state.userActionQueue = [...state.userActionQueue, action.payload];
    },
    logUserActionsRequest(state, action: PayloadAction<void>) {
      state.logUserActions.loading = true;
    },
    logUserActionsSuccess(state, action: PayloadAction<void>) {
      state.logUserActions.loading = false;
      state.userActionQueue = [];
    },
    logUserActionsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.logUserActions.loading = false;
      state.logUserActions.error = action.payload;
    },
  },
});

export const { actions: loggingProviderActions } = slice;

export const useLoggingProviderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loggingProviderSaga });
  return { actions: slice.actions };
};
