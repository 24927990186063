import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reportSaga } from './saga';
import { ReportState, Report } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: ReportState = {
  reports: {},
  export: apiCallInitialState,
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    test(state, action: PayloadAction<any>) {
      console.log('TEST');
    },
    reportMount(state, action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.id]: {
            graphic: apiCallInitialState,
            table: apiCallInitialState,
          },
        },
        export: apiCallInitialState,
      };
    },
    reportRequest(
      state,
      action: PayloadAction<{ id: string; [key: string]: any }>,
    ) {
      state.reports[action.payload.id].graphic.loading = true;
    },
    reportSuccess(
      state,
      action: PayloadAction<{ id: string; report: Report }>,
    ) {
      state.reports[action.payload.id].graphic = {
        loading: false,
        data: action.payload.report.graphic,
      };
    },
    reportFailed(
      state,
      action: PayloadAction<{ id: string; error: ResponseErrorPayload }>,
    ) {
      state.reports[action.payload.id].graphic.error = action.payload.error;
    },
    reportDataRequest(
      state,
      action: PayloadAction<{ id: string; [key: string]: any }>,
    ) {
      state.reports[action.payload.id].table.loading = true;
    },
    reportDataSuccess(
      state,
      action: PayloadAction<{ id: string; report: Report }>,
    ) {
      state.reports[action.payload.id].table = {
        loading: false,
        data: action.payload.report.table,
      };
    },
    reportDataFailed(
      state,
      action: PayloadAction<{ id: string; error: ResponseErrorPayload }>,
    ) {
      state.reports[action.payload.id].table.error = action.payload.error;
    },
    exportRequest(
      state,
      action: PayloadAction<{ id: string; [key: string]: any }>,
    ) {
      state.export.loading = true;
    },
    exportSuccess(state, action: PayloadAction<boolean>) {
      state.export.loading = false;
      state.export.data = action.payload;
    },
    exportFailed(state, action: PayloadAction<void>) {
      state.export.loading = false;
      state.export.error = { message: 'Export of data failed.' };
    },

    reportUnmount(state, action: PayloadAction<{ id: string }>) {
      delete state.reports[action.payload.id];
      state.export = apiCallInitialState;
    },
  },
});

export const { actions: reportActions } = slice;

export const useReportSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: reportSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useReportSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
