/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { throttle } from 'lodash';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import * as serviceWorker from 'serviceWorker';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import 'sanitize.css/sanitize.css';
// Initialize languages
import './locales/i18n';
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { saveState } from './store/localStorage';
import { AuthProvider } from './app/containers/AuthProvider';
import { muiTheme } from './styles/theme/themes';
import { PusherProvider } from './app/containers/PusherProvider';
import { ConfirmProvider } from './app/components/ConfirmDialog';
import { LoggingProvider } from './app/containers/LoggingProvider';
import { BrowserRouter } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Fira Sans', {});
const headerObserver = new FontFaceObserver('Montserrat', {});

// When Inter is loaded, add a font-family using Inter to the body
Promise.all([openSansObserver.load(), headerObserver.load()]).then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();

// todo - might need to use this https://edvins.io/how-to-use-redux-persist-with-redux-toolkit
// instead of the loadState idea in the CustomerSelector slice that has been used
store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState({
      customerSelector: {
        currentCustomers: (state.customerSelector || {}).currentCustomers,
      },
    });
  }, 1500),
);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

const ConnectedApp = ({ Component }: Props) => {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    const siteId = process.env.REACT_APP_HOTJAR_SITEID || 0;
    if (!siteId) return;
    initHotjar(+siteId, 6, process.env.REACT_APP_ENV !== 'production');
  }, [initHotjar]);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <ConfirmProvider>
            <AuthProvider>
              <SnackbarProvider>
                <PusherProvider>
                  <HelmetProvider>
                    <React.StrictMode>
                      <BrowserRouter>
                        <Component />
                      </BrowserRouter>
                    </React.StrictMode>
                  </HelmetProvider>
                </PusherProvider>
              </SnackbarProvider>
            </AuthProvider>
          </ConfirmProvider>
        </MuiThemeProvider>
      </ThemeProvider>
      <LoggingProvider />
    </Provider>
  );
};

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
