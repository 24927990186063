import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { selectCurrentCustomers } from 'app/containers/CustomerSelector/slice/selectors';
import { apiRequest } from 'utils/request';
import { filterSliceActions as actions } from '.';

function* loadAvailableFieldFilters() {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `filter-settings`,
      method: 'post',
      data: {
        customers: currentCustomers,
      },
    });

    yield put(actions.loadAvailableFieldFiltersSuccess(payload));
  } catch (error) {
    yield put(actions.loadAvailableFieldFiltersFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* filterSliceSaga() {
  yield takeLatest(
    actions.loadAvailableFieldFiltersRequest.type,
    loadAvailableFieldFilters,
  );
}
