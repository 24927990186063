/**
 *
 * AdjustmentReports
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdjustmentReportsSlice } from './slice';
import { useRouteMatch } from 'react-router';
import {
  selectLoadAdjustmentReports,
  selectUpdateAdjustmentReport,
} from './slice/selectors';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import {
  ChevronLeft,
  Close,
  Edit,
  FolderOpen,
  Undo,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useConfirm } from '../../components/ConfirmDialog';
import { ManagementTable } from '../../components/ManagementTable';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { formatParsedISO } from '../../../utils/dates';
import { hasPermission } from '../../../utils/user';
import { UserPermission } from '../AuthProvider/slice/types';
import { selectUserData } from '../AuthProvider/slice/selectors';
import { useHasChanged } from '../../../utils/usePrevious';
import { AdjustmentReportStatus } from './slice/types';
import { useCallback, useMemo } from 'react';

interface Props {
  status?: AdjustmentReportStatus;
}

export function AdjustmentReports(props: Props) {
  const { status = 'pending approval' } = props;
  const confirm = useConfirm();
  const match = useRouteMatch();
  const { actions } = useAdjustmentReportsSlice();
  const dispatch = useDispatch();
  const loadAdjustmentReports = useSelector(selectLoadAdjustmentReports);
  const updateAdjustmentReport = useSelector(selectUpdateAdjustmentReport);
  const userData = useSelector(selectUserData);

  const [selectedItems, setSelectedItems]: [
    selectedItems: number[],
    setSelectedItems: Function,
  ] = React.useState([]);

  const getAdjustmentReports = () =>
    dispatch(actions.loadAdjustmentReportsRequest({ status }));

  React.useEffect(() => {
    getAdjustmentReports();
    return () => {
      dispatch(actions.initialise());
    };
  }, []);

  const updateLoadingHasChanged = useHasChanged(updateAdjustmentReport.loading);
  React.useEffect(() => {
    if (
      updateLoadingHasChanged &&
      !updateAdjustmentReport.loading &&
      updateAdjustmentReport.data
    )
      getAdjustmentReports();
  });

  useCustomerSelector(getAdjustmentReports);

  const rows = loadAdjustmentReports.data.map(item => ({
    ...item,
  }));

  const title = useMemo(() => {
    switch (status) {
      case 'accepted':
        return 'Accepted Adjustment Reports';
      case 'pending approval':
        return 'Pending Approval';
      default:
        return '';
    }
  }, [status]);

  const actionButtons = useCallback(
    row => {
      if (status === 'accepted') {
        return (
          <>
            <Tooltip title={'Roll back adjustment report'}>
              <IconButton
                disabled={
                  !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
                }
                edge="start"
                onClick={() =>
                  confirm({
                    description:
                      'Rolling back adjustment report - are you sure?',
                  }).then(() => {
                    dispatch(
                      actions.updateAdjustmentReportRequest({
                        id: row.id,
                        action: 'rollback',
                      }),
                    );
                  })
                }
                size="large"
              >
                <Undo />
              </IconButton>
            </Tooltip>
            <Tooltip title={'View adjustment report'}>
              <IconButton
                disabled={
                  !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
                }
                component={Link}
                to={`${match.path}/${row.id}`}
                size="large"
              >
                <FolderOpen />
              </IconButton>
            </Tooltip>
          </>
        );
      }
      if (status === 'pending approval') {
        return (
          <>
            <Tooltip title={'Cancel adjustment report'}>
              <IconButton
                disabled={
                  !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
                }
                edge="start"
                onClick={() =>
                  confirm({
                    description: 'Cancelling adjustment report - are you sure?',
                  }).then(() => {
                    dispatch(
                      actions.updateAdjustmentReportRequest({
                        id: row.id,
                        action: 'cancel',
                      }),
                    );
                  })
                }
                size="large"
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Tooltip title={'View/amend adjustment report'}>
              <IconButton
                disabled={
                  !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
                }
                component={Link}
                to={`${match.path}/${row.id}`}
                size="large"
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Confirm this adjustment report.'}>
              <Button
                disabled={
                  !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
                }
                size={'small'}
                onClick={() =>
                  confirm({
                    description:
                      'Confirm adjustment report has been accepted and merge new line values into dataset?',
                  })
                    .then(() => {
                      dispatch(
                        actions.updateAdjustmentReportRequest({
                          id: row.id,
                          action: 'accept',
                        }),
                      );
                    })
                    .catch()
                }
                variant={'contained'}
              >
                Mark accepted
              </Button>
            </Tooltip>
          </>
        );
      }
    },
    [userData, status, dispatch],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={'h1'} color={'primary'}>
          {title}
        </Typography>
      </Box>
      <Paper sx={{ my: 4 }}>
        <Box sx={{ minHeight: 300 }}>
          <ManagementTable
            columns={[
              {
                label: 'Submitted Date',
                value: row => formatParsedISO(row.submitted_date),
              },
              {
                value: 'status',
                label: 'Status',
              },
              {
                value: 'notes',
                label: 'Notes',
              },
              {
                value: 'metadata.created_by',
                classes: 'narrow-cell',
              },
              {
                classes: 'align-right',
                value: actionButtons,
              },
            ]}
            loading={loadAdjustmentReports.loading}
            rows={rows}
            onSelectionChange={ids => setSelectedItems(ids.map(s => +s))}
            onClick={() => {}}
            page={1}
            onChangePage={() => {}}
            rowsPerPage={1000}
            rowsPerPageOptions={[]}
            count={1000}
            loadingComponent={<LoadingIndicator minHeight={200} />}
            rowClasses={{}}
            noDataMessage={'No adjustment reports currently awaiting approval.'}
          />
        </Box>
      </Paper>
    </>
  );
}
