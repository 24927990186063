/**
 *
 * FilterableReportTable
 *
 */
import React, {
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridSortingInitialState,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';

interface Props {
  identifier: string;
  formatCurrency: (value: number) => string;
  selectedItems: Array<string | number>;
  onSelectionChange: (selection: Array<string | number>) => void;
  columns: Array<{
    label: string;
    field: string;
    format?: 'number' | 'percentage' | 'currency';
    formatValue?: (v: any) => string;
    sortable?: boolean;
    sort?: 'asc' | 'desc';
    hidden?: boolean;
    wide?: boolean;
    filterable?: boolean;
  }>;
  data: Array<{ [key: string]: string | number }>;
}

export const FilterableReportTable = memo((props: Props) => {
  const [filter, setFilter] = useState<any>();
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const apiRef = useGridApiRef();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setWidth = debounce(() => {
      if (!ref.current) return;
      setContainerWidth(ref.current.clientWidth - 30);
    }, 200);
    window.addEventListener('resize', setWidth);

    setWidth();
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, [ref.current]);

  const rows = useMemo(() => {
    return props.data.map(row => {
      return props.columns.reduce((acc, column) => {
        acc[column.field] = row[column.field];

        return acc;
      }, {});
    });
  }, [props.data, props.columns]);

  const columns: GridColDef[] = useMemo(() => {
    return props.columns.map(column => {
      const width =
        (containerWidth -
          40 -
          props.columns.filter(c => !!c.wide).length * 200) /
        props.columns.filter(c => !c.wide).length;
      const col: GridColDef = {
        field: column.field,
        headerName: column.label,
        width,
        minWidth: 120,
        pinnable: false,
        hideable: false,
        filterable: true,
      };

      if (column.format === 'currency') {
        col.renderCell = params => props.formatCurrency(+params.value! || 0);
        col.valueFormatter = params => +params.value! || 0;
        col.type = 'number';
      }

      if (column.format === 'percentage') {
        col.valueFormatter = params => `${params.value}%`;
        col.type = 'number';
      }

      if (column.format === 'number') {
        col.type = 'number';
      }

      if (!!column.hidden) {
        col.hide = true;
        col.hideable = true;
        col.filterable = false;
      }

      if (!!column.wide) {
        col.width = 200;
      }

      return col;
    });
  }, [props.columns, containerWidth]);

  const initialSortState: GridSortingInitialState = {
    sortModel: props.columns
      .filter(c => !!c.sort)
      .map(c => ({
        field: c.field,
        sort: c.sort,
      })),
  };

  return (
    <Box ref={ref} sx={{ height: '70vh', maxHeight: 150 + 30 * rows.length }}>
      <DataGridPro
        apiRef={apiRef}
        checkboxSelection
        onSelectionModelChange={selectionModel => {
          props.onSelectionChange(selectionModel);
        }}
        selectionModel={props.selectedItems}
        initialState={{
          sorting: initialSortState,
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, props.identifier],
          },
        }}
        rowHeight={30}
        headerHeight={30}
        columns={columns}
        rows={rows}
        getRowId={row => row[props.identifier]}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <GridToolbarFilterButton />
                <GridToolbarExport sx={{ marginLeft: 'auto' }} />
              </Box>
            </GridToolbarContainer>
          ),
        }}
      />
    </Box>
  );
});
