/**
 *
 * AdjustmentDetails
 *
 */
import * as React from 'react';
import { startCase } from 'lodash';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import Stack from '@mui/material/Stack';

interface Props {
  actions: Array<{
    type: 'change' | 'new';
    suffix?: string;
    fields: Array<{ id: number | string; [key: string]: any }>;
  }>;
}

export function AdjustmentDetails(props: Props) {
  const { actions = [] } = props;
  return (
    <Stack spacing={2}>
      {actions.map(a => (
        <Box sx={{ p: 0 }}>
          <List>
            {((a.fields as Array<any>) || [])
              .filter(field => field.from !== field.to)
              .map((field, index, action) => (
                <ListItem
                  key={field.field}
                  divider={index !== action.length - 1}
                >
                  <ListItemText>
                    <b>{startCase(field.field)}</b> from "{field.from}" to "
                    {field.to}"
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </Box>
      ))}
    </Stack>
  );
}
