import React from 'react';
import { AppBar, Button, IconButton, Theme, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Attachment,
  BarChart,
  Home,
  Person,
  PowerSettingsNew,
  Settings,
} from '@mui/icons-material';
import { CustomerSelectButton } from 'app/containers/CustomerSelector';
import { authProviderActions } from 'app/containers/AuthProvider/slice';
import { useDispatch } from 'react-redux';
import { useConfirm } from '../ConfirmDialog';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../../utils/user';
import {
  User,
  UserPermission,
} from '../../containers/AuthProvider/slice/types';
import { ImportStatusData } from '../../containers/ImportData/slice/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export interface Props {
  userData: User;
  currentCustomers: Array<number>;
}

export default function PortalFooter(props) {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <AppBar elevation={0} square position="static">
        <Toolbar>
          <div className={classes.title}>
            <CustomerSelectButton />
          </div>
          <Button
            color="inherit"
            startIcon={<Home />}
            component={Link}
            to={'/portal'}
          >
            Home
          </Button>
          <Button
            color="inherit"
            startIcon={<Person />}
            component={Link}
            to={'/portal/accounts'}
          >
            Account Settings
          </Button>
          <Button
            color="inherit"
            startIcon={<BarChart />}
            component={Link}
            to={'/portal/report/all'}
          >
            Adjustment Reports
          </Button>
          {props.currentCustomers.length === 1 &&
            hasPermission(props.userData, UserPermission.UPLOAD_DATA) && (
              <Button
                color="inherit"
                startIcon={<Attachment />}
                component={Link}
                to={'/portal/import'}
              >
                Import Data
              </Button>
            )}
          {/*
          <Button color="inherit" startIcon={<Settings />}>
            Change Admin Settings
          </Button>
          
          */}
          <Button
            color="inherit"
            startIcon={<PowerSettingsNew />}
            onClick={ev => {
              confirm({ description: 'Log out of the portal - are you sure?' })
                .then(() => dispatch(authProviderActions.userLogoutRequest()))
                .catch(() => {});
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
