import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { importMassAdjustmentsActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';
import { createUploadFileChannel } from '../../../../utils/saga/createFileUploadChannel';

function* importMassAdjustment(action) {
  const currentCustomers = yield select(selectCurrentCustomers);
  const channel = yield call(createUploadFileChannel, 'adjustments/upload', {
    ...action.payload,
    customer_id: currentCustomers[0],
  });
  while (true) {
    const { progress = 0, err, success, response } = yield take(channel);
    if (err) {
      yield put(actions.importMassAdjustmentFailed(err));
      return;
    }
    if (success) {
      yield put(actions.importMassAdjustmentSuccess(JSON.parse(response)));
      return;
    }
    yield put(actions.importMassAdjustmentProgress({ progress }));
  }
}

function* confirmMassAdjustment(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `adjustments/confirm`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.confirmMassAdjustmentSuccess(payload));
  } catch (error) {
    yield put(actions.confirmMassAdjustmentFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* importMassAdjustmentsSaga() {
  yield takeLatest(
    actions.importMassAdjustmentRequest.type,
    importMassAdjustment,
  );
  yield takeLatest(
    actions.confirmMassAdjustmentRequest.type,
    confirmMassAdjustment,
  );
}
