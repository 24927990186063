/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { blue, green, grey, red } from '@mui/material/colors';
import {
  Box,
  capitalize,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
  Chart,
  SeriesColumnOptions,
  SeriesEventsOptionsObject,
  SeriesLineOptions,
} from 'highcharts';
import { ReportGraphic } from '../../slice/types';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { isEqual } from 'lodash';
import { useHasChanged, usePrevious } from '../../../../../utils/usePrevious';
import CustomEvents from 'highcharts-custom-events';
import { useState } from 'react';

interface Props extends ReportGraphicProps {}

export function Overview(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const [options, setOptions] = React.useState<
    Highcharts.Options | undefined
  >();

  const { data, reportSettings } = props.graphic.data!;

  const values: { [key: string]: { label: string; checkbox?: string } } = {
    customsValue: {
      label: 'Customs Value',
    },
    customsDutyPaid: {
      label: 'Customs Duty Paid',
      checkbox: 'customsSelected',
    },
    effectiveDutyRate: {
      label: 'Effective Duty Rate',
    },
    importVat: {
      label: 'Import VAT',
      checkbox: 'vatSelected',
    },
  };

  const [chart, setChart] = React.useState<Chart | undefined>();
  const [period, setPeriod] = React.useState<
    'monthly' | 'quarterly' | 'yearly'
  >(reportSettings.period || 'monthly');

  const [selectedPeriod, setSelectedPeriod] = React.useState<string | null>(
    reportSettings.selectedPeriod,
  );

  const [customsSelected, setCustomsSelected] = useState<boolean>(
    reportSettings.customsSelected,
  );

  const [vatSelected, setVatSelected] = useState<boolean>(
    reportSettings.vatSelected,
  );

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }

    if (
      props.graphic.data.reportSettings.selectedPeriod &&
      !isEqual(props.graphic.data.reportSettings.selectedPeriod, selectedPeriod)
    ) {
      setSelectedPeriod(props.graphic.data.reportSettings.selectedPeriod);
    }
  }, [props.graphic.data]);

  const oldPeriod = usePrevious(period);
  React.useEffect(() => {
    props.onSettingsChange({ period, selectedPeriod });
    if (oldPeriod !== undefined && oldPeriod !== period) {
      setSelectedPeriod(null);
    }
  }, [period]);

  const oldData = usePrevious(data);
  React.useEffect(() => {
    if (options) chart?.update(options);
  }, [options]);

  React.useEffect(() => {
    if (oldData && !isEqual(data, oldData)) {
      setSelectedPeriod(null);
    }
  }, [data]);

  const selectedPeriodHasChanged = useHasChanged(selectedPeriod);
  const vatSelectedHasChanged = useHasChanged(vatSelected);
  const customsSelectedHasChanged = useHasChanged(customsSelected);

  React.useEffect(() => {
    if (
      selectedPeriodHasChanged ||
      vatSelectedHasChanged ||
      customsSelectedHasChanged
    ) {
      if (props.full) {
        props.onSettingsChange(
          { period, selectedPeriod, vatSelected, customsSelected },
          true,
        );
      } else {
        props.loadFullReport({
          period,
          selectedPeriod,
          vatSelected,
          customsSelected,
        });
      }
    }
  });

  React.useEffect(() => {
    setOptions(getOptions());
  }, [period, selectedPeriod, data, reportSettings]);

  const xAxisClick = index => {
    const sp = `${period}-${index}`;
    setSelectedPeriod(selectedPeriod === sp ? null : sp);
  };

  if (!data) {
    return null;
  }

  const seriesEvents: SeriesEventsOptionsObject = {
    click: function (ev) {
      xAxisClick(data.xAxes[period].indexOf(ev.point.category));
    },
  };

  const getPointAttribute = (i, attr, def) => {
    return selectedPeriod !== null
      ? selectedPeriod === `${period}-${i}`
        ? attr
        : def
      : attr;
  };
  //

  const afterChartCreated = (highcharts: Chart) => {
    if (!chart) setChart(highcharts);
  };

  // todo - replace this but it's just acting as a normal currency format for now
  const formatCurrency = variableFormatCurrency(10000000000, data.currency);
  const compactCurrency = variableFormatCurrency(0, data.currency);

  const getOptions = (): Highcharts.Options => ({
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        categories: data.xAxes[period],
      },
    ],
    yAxis: [
      {
        showEmpty: false,
        labels: {
          formatter: function () {
            return compactCurrency(this.value);
          },
        },
        title: { text: `Customs Value` },
      },
      {
        showEmpty: false,
        labels: {
          formatter: function () {
            return compactCurrency(this.value);
          },
        },
        title: { text: `Customs Paid` },
      },
      {
        showEmpty: false,
        labels: {
          format: '{value}%',
        },
        opposite: true,
        title: { text: 'Effective Duty Rate' },
      },
    ],
    series: [
      {
        type: 'column',
        name: 'Customs Value',
        events: {
          ...seriesEvents,
        },
        color: blue.A200,
        data: data.values.customsValue.data[period].map((y, i) => {
          return {
            color: getPointAttribute(i, blue.A200, grey.A200),
            y,
          };
        }),
        tooltip: {
          pointFormatter: function () {
            return `${this.series.name}<br>${formatCurrency(this.y)}`;
          },
        },
      } as SeriesColumnOptions,
      {
        type: 'column',
        events: {
          ...seriesEvents,
        },
        color: red.A200,
        yAxis: 1,
        name: 'Customs Duty Paid',
        data: data.values.customsDutyPaid.data[period].map((y, i) => {
          return {
            color: getPointAttribute(i, red.A200, grey.A200),
            y,
          };
        }),
        tooltip: {
          pointFormatter: function () {
            return `${this.series.name}<br>${formatCurrency(this.y)}`;
          },
        },
      } as SeriesColumnOptions,
      {
        type: 'line',
        events: {
          ...seriesEvents,
        },

        color: selectedPeriod !== null ? grey.A200 : green.A200,
        name: 'Effective Duty Rate',
        yAxis: 2,
        data: data.values.effectiveDutyRate.data[period].map((y, i) => {
          return {
            marker: getPointAttribute(
              i,
              {
                lineWidth: 6,
                lineColor: green.A200,
              },
              {
                lineWidth: 3,
                lineColor: grey.A200,
              },
            ),
            color: getPointAttribute(i, green.A200, grey.A200),
            y,
          };
        }),
        tooltip: {
          pointFormatter: function () {
            return `${this.series.name}<br>${this.y}%`;
          },
        },
      } as SeriesLineOptions,
    ],
  });

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Stack divider={<Divider textAlign={'left'} />}>
            {(Object.keys(values) as Array<keyof typeof values>).map(k => (
              <Box
                key={`text-elements-${k}`}
                sx={{
                  my: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyItems: 'center',
                }}
              >
                <Box sx={{ mb: 0 }}>
                  <Typography variant="subtitle2">{values[k].label}</Typography>
                  <Typography variant="h4">
                    {k === 'effectiveDutyRate'
                      ? data.values[k].label[period]
                      : formatCurrency(data.values[k].label[period])}
                    <span style={{ fontSize: '0.8em' }}>
                      {k === 'effectiveDutyRate' ? '%' : ''}
                    </span>
                  </Typography>
                  {data.values[k].change[period] !== false && (
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 0,
                        color:
                          data.values[k].change[period] >= 0
                            ? green['700']
                            : red['800'],
                      }}
                    >
                      <Box>
                        {data.values[k].change[period] > 0 ? (
                          <TrendingUp />
                        ) : (
                          <TrendingDown />
                        )}
                        <Box component="span" sx={{ mx: 1 }}>
                          {Math.abs(data.values[k].change[period]) > 100000 ? (
                            <span style={{ fontSize: '1.4em' }}>∞ </span>
                          ) : (
                            <span>{data.values[k].change[period]}%</span>
                          )}
                        </Box>
                      </Box>
                    </Typography>
                  )}
                </Box>
                {values[k].checkbox === 'vatSelected' && (
                  <FormControlLabel
                    sx={{
                      backgroundColor: 'grey.A100',
                      mt: 1,
                      ml: 0,
                      pr: 2,
                    }}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    control={
                      <Checkbox
                        checked={vatSelected}
                        onClick={() => setVatSelected(!vatSelected)}
                      />
                    }
                    label={'Show VAT entries only'}
                  />
                )}
                {values[k].checkbox === 'customsSelected' && (
                  <FormControlLabel
                    sx={{
                      backgroundColor: 'grey.A100',
                      mt: 1,
                      ml: 0,
                      pr: 2,
                    }}
                    componentsProps={{
                      typography: {
                        variant: 'body2',
                      },
                    }}
                    control={
                      <Checkbox
                        checked={customsSelected}
                        onClick={() => setCustomsSelected(!customsSelected)}
                      />
                    }
                    label={'Show duty paid entries only'}
                  />
                )}
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box>
            <Grid
              sx={{
                mb: 2,
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'flex-end',
                },
              }}
              container
              alignItems={'center'}
            >
              <Grid item xs={12} sm={'auto'}>
                <Typography component={'div'} variant={'subtitle2'}>
                  Filter date range
                </Typography>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Box
                  sx={{
                    ml: { xs: 0, sm: 2 },
                    p: 2,
                    backgroundColor: 'grey.A100',
                  }}
                >
                  <FormControl>
                    <RadioGroup row>
                      {['monthly', 'quarterly', 'yearly'].map(p => (
                        <FormControlLabel
                          key={`period-options-${p}`}
                          componentsProps={{
                            typography: {
                              variant: 'body2',
                            },
                          }}
                          control={
                            <Radio
                              color="primary"
                              checked={period === p}
                              onClick={() =>
                                setPeriod(
                                  p as 'monthly' | 'quarterly' | 'yearly',
                                )
                              }
                            />
                          }
                          label={`Show ${capitalize(p)}`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            {!!options && (
              <HighchartsReact
                key={'overviewChart'}
                allowChartUpdate={true}
                updateArgs={[true, true, true]}
                callback={afterChartCreated}
                highcharts={Highcharts}
                options={options}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
