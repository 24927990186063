/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { useRef } from 'react';
import { red } from '@mui/material/colors';
import { get, isEqual } from 'lodash';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HighchartsReact from 'highcharts-react-official';
import { usePrevious } from 'utils/usePrevious';
import Highcharts, { SeriesPieOptions } from 'highcharts';
import {
  formatCurrencyFactory,
  variableFormatCurrency,
} from 'utils/formatCurrency';
import { ManagementTable } from '../../../../components/ManagementTable';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { ReportGraphicProps } from '../index';
import { useLocation } from 'react-router';
import { useWhatChanged } from '@simbathesailor/use-what-changed';
import { ErrorBoundary } from 'react-error-boundary';

interface Props extends ReportGraphicProps {}

const useStyles = makeStyles((theme: Theme) => ({
  selectedColumn: {
    backgroundColor: theme.palette.grey['200'],
    '.Mui-selected &': {
      backgroundColor: 'red',
    },
  },
  amendedCell: {
    backgroundColor: theme.palette.secondary.dark,
  },
  pendingApprovalCell: {
    backgroundColor: theme.palette.secondary.dark,
  },
  root: {
    border: 'none',
    '& .MuiDataGrid-row': {
      '&.countryCodeSelected': {
        backgroundColor: theme.palette.grey['200'],
      },
    },
  },
}));

export function PreferenceAnalysis(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const { data, reportSettings } = props.graphic.data!;
  const theme = useTheme();

  const classes = useStyles();
  const ref = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  const [options, setOptions] = React.useState<
    Highcharts.Options | undefined
  >();

  const [viewMode, setViewMode] = React.useState<keyof typeof viewModes>(
    reportSettings.viewMode || 'top5',
  );

  const [metric, setMetric] = React.useState<keyof typeof metricOptions>(
    reportSettings.metric || 'frequency',
  );

  const [preference, setPreference] = React.useState<string | null>(
    reportSettings.preference || undefined,
  );

  const [countryCode, setCountryCode] = React.useState<
    string | null | undefined
  >(reportSettings.countryCode || undefined);

  const [hiddenPoints, setHiddenPoints] = React.useState<string[]>(
    reportSettings.hiddenPoints || [],
  );

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }

    if (!isEqual(props.graphic.data.reportSettings.preference, preference)) {
      setPreference(props.graphic.data.reportSettings.preference);
    }
  }, [props.graphic.data]);

  React.useEffect(() => {
    if (!props.graphic.loading) setOptions(getOptions());
  }, [props.graphic.loading]);

  React.useEffect(() => {
    if (props.graphic.data) {
      props.onSettingsChange({
        viewMode,
        metric,
        preference,
        countryCode,
        hiddenPoints,
      });
    }
    setOptions(getOptions());
  }, [viewMode, metric, hiddenPoints]);

  const oldPreference = usePrevious(preference);
  const oldCommCode = usePrevious(countryCode);
  React.useEffect(() => {
    setOptions(getOptions());
    if (
      props.full &&
      (!isEqual(preference, oldPreference) ||
        !isEqual(countryCode, oldCommCode))
    ) {
      props.onSettingsChange(
        {
          viewMode,
          metric,
          preference,
          countryCode,
          hiddenPoints,
        },
        true,
      );
    }

    if (!preference && !countryCode) return;

    if (!props.full && (preference || countryCode)) {
      props.loadFullReport({
        viewMode,
        metric,
        preference,
        countryCode,
        hiddenPoints,
      });
    }
  }, [preference, countryCode]);

  if (!data) {
    return null;
  }

  const formatCurrency = variableFormatCurrency(100000000);

  const toggleCountryCode = code => {
    if (countryCode === code) {
      setCountryCode(undefined);
    } else {
      setCountryCode(code);
    }
  };

  const metricOptions = {
    customsValue: {
      label: 'Customs Value',
      formatter: value => formatCurrency(value),
    },
    customsDutySaved: {
      label: 'Customs Duty Saved',
      formatter: formatCurrency,
    },
    frequency: {
      label: 'Frequency',
      formatter: value => value,
    },
  };

  const viewModes = {
    top5: {
      label: 'Top 5',
    },
    bottom5: {
      label: 'Bottom 5',
    },
  };

  const getOptions = (): Highcharts.Options => {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        outside: true,
        padding: 16,
        headerFormat: '',
        pointFormatter: function () {
          return `<b>${this.series.name}: ${metricOptions[metric].formatter(
            this.y,
          )}</b><br><br><b>${this.options.name}:</b> ${
            this.options.description
          }`;
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },

      series: [
        {
          // @ts-ignore
          colors: Highcharts.getOptions().colors.map(function (color) {
            return {
              radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.6,
              },
              stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.2).get('rgb')], // darken
              ],
            };
          }),
          slicedOffset: 20,

          point: {
            events: {
              click: function (event) {
                if (this.selected) {
                  setPreference(null);
                } else {
                  setCountryCode(null);
                  setPreference(this.name);
                }
                event.preventDefault();
              },

              legendItemClick: function (event) {
                setHiddenPoints(
                  this.series.points
                    .filter(
                      p =>
                        (event.target.name !== p.name && !p.visible) ||
                        (event.target.name === p.name && event.target.visible),
                    )
                    .map(p => p.name),
                );
                event.preventDefault();
              },
            },
          },
          type: 'pie',
          name: `${metricOptions[metric].label}`,
          colorByPoint: true,
          data: data.values[viewMode][metric].map((v, i) => ({
            name: v.preference,
            color: v.color,
            description: v.description,
            y: v.value,
            sliced: preference === v.preference,
            selected: preference === v.preference,
            visible: hiddenPoints.indexOf(v.preference) === -1,
          })),
        } as SeriesPieOptions,
      ],
    };
  };

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box>
        <Grid
          container
          sx={{
            mb: 2,
            justifyContent: {
              xs: 'flex-start',
              lg: 'flex-end',
            },
          }}
          alignItems={'center'}
        >
          <Grid item xs={12} md={12} lg={'auto'}>
            <Typography component={'div'} variant={'subtitle2'}>
              Filter by
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={'auto'}>
            <Box
              sx={{
                ml: { xs: 0, lg: 2 },
                p: 2,
                backgroundColor: 'grey.A100',
              }}
            >
              <FormControl>
                <RadioGroup row>
                  {Object.keys(metricOptions).map(mk => (
                    <FormControlLabel
                      key={`metric-options-${mk}`}
                      control={
                        <Radio
                          color="primary"
                          checked={metric === mk}
                          onClick={() =>
                            setMetric(mk as keyof typeof metricOptions)
                          }
                        />
                      }
                      label={`${metricOptions[mk].label}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={props.full ? 5 : 12}>
          <Box sx={{}}>
            {!!options && (
              <HighchartsReact
                ref={ref}
                style={{ width: '100%' }}
                key={'preferenceAnalysisChart'}
                highcharts={Highcharts}
                options={options}
              />
            )}
          </Box>
        </Grid>
        {!!props.full && (
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  [theme.breakpoints.down('md')]: {
                    position: 'relative',
                  },
                }}
              >
                <ManagementTable
                  breakpoint={'sm'}
                  enableCheckboxSelection
                  disableMultipleSelection
                  defaultRowsSelected={countryCode ? [countryCode] : []}
                  loading={false}
                  onSelectionChange={ids => {
                    setCountryCode(ids.length ? (ids[0] as string) : null);
                    if (ids.length) setPreference(null);
                  }}
                  page={1}
                  onChangePage={() => {}}
                  rowsPerPage={1000}
                  rowsPerPageOptions={[]}
                  count={1000}
                  loadingComponent={<LoadingIndicator />}
                  rowClasses={{}}
                  noDataMessage={'No amended items.'}
                  onClick={r => {
                    //toggleCommCode(r.id as string);
                  }}
                  columns={[
                    {
                      value: 'name',
                      label: 'Country',
                    },
                    {
                      value: row =>
                        `${formatCurrency(
                          get(row, 'potential.potential_missed_savings', 0),
                        )} (${get(
                          row,
                          'potential.potential_missed_items',
                          0,
                        )} items)`,
                      label: 'Potential Savings',
                    },
                    ...(data.values.codes_in_use || []).map(code => ({
                      value: r => r[code],
                      label: code,
                    })),
                  ]}
                  rows={(data.values.potential_countries || []).map(
                    countryCode => {
                      const ret = {
                        id: countryCode.country,
                        name: countryCode.name,
                        code: countryCode.country,
                        potential: countryCode.potential,
                      };
                      (data.values.codes_in_use || []).forEach(code => {
                        if (countryCode.data[code]) {
                          ret[code] = formatCurrency(
                            get(countryCode.data[code], 'value'),
                          );
                        }
                      });
                      return ret;
                    },
                  )}
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
