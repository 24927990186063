/* --- STATE --- */
import { ApiCall } from 'types/ApiCall';
import { Customer } from '../../CustomerSelector/slice/types';

export interface AuthProviderState {
  login: ApiCall;
  logout: ApiCall;
  loadUser: UserApiCall;
  forgotPassword: ApiCall;
  resetPassword: ApiCall;
  activateAccount: ApiCall;
  initialised: boolean;
  loggedIn: boolean;
  refreshing: boolean;
  loading: boolean;
}

export interface UserApiCall extends ApiCall {
  data: User | undefined;
}

// todo - get this from the API
export enum UserPermission {
  VIEW_OWN_DATA = 'view own data',
  VIEW_ALL_DATA = 'view all data',
  ADMINISTER_SYSTEM = 'administer system',
  UPLOAD_DATA = 'upload data',
  CREATE_ADJUSTMENTS = 'create adjustments',
}
export type User = {
  id: number;
  uuid?: string;
  first_name: string;
  last_name: string;
  email: string;
  access_customers: Array<Customer>;
  permissions: Array<UserPermission>;
  pusher?: {
    app_key: string;
    cluster: string;
    tls: boolean;
  };
};

export type ContainerState = AuthProviderState;
