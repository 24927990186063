import { usePrevious } from '../../../utils/usePrevious';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentCustomers } from './slice/selectors';

export const useCustomerSelector = callback => {
  const currentCustomers = useSelector(selectCurrentCustomers);
  const previousCustomers = usePrevious(currentCustomers);
  React.useEffect(() => {
    if (previousCustomers) {
      callback();
    }
  }, [currentCustomers]);

  return currentCustomers;
};
