import { CurrencyFormatCustom } from '../app/components/InputMasks';

export const formatCurrency = (
  number: number,
  options: { [key: string]: any } = {},
  currency = 'GBP',
  locale = 'en-GB',
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    ...options,
  }).format(number);

export const formatCurrencyFactory = (
  options: { [key: string]: any } = {},
  currency = 'GBP',
  locale = 'en-GB',
) => number => {
  return formatCurrency(number, options, currency, locale);
};

export const variableFormatCurrency = (
  threshold = 10000,
  currency = 'GBP',
  locale = 'en-GB',
) => number => {
  const options: any = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  if (number > threshold) {
    options.notation = 'compact';
  }
  return formatCurrency(parseInt(number), options, currency, locale);
};

export const formatCurrencyTextField = value => {
  return {
    value: value ? +value : 0,
    InputProps: {
      inputComponent: CurrencyFormatCustom as any,
    },
  };
};
