import {
  User,
  UserPermission,
} from '../app/containers/AuthProvider/slice/types';
import { get } from 'lodash';

export const hasPermission = (
  userData: User | undefined,
  permission: UserPermission,
) => {
  return (
    !!userData &&
    get(userData, 'permissions', [] as UserPermission[]).indexOf(permission) !==
      -1
  );
};
