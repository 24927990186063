import React, { HTMLAttributes } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button, { ButtonProps } from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Box, BoxProps, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({}));

interface Props extends BoxProps {
  buttonText: string | React.ReactNode;
  popoverContent: string | React.ReactNode;
  buttonProps?: ButtonProps;
}

export function PopoverButton(props: Props) {
  const { buttonProps, buttonText, popoverContent, ...rProps } = props;
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });
  return (
    <Box {...rProps}>
      <Button
        variant="contained"
        {...(props.buttonProps || {})}
        {...bindTrigger(popupState)}
      >
        {props.buttonText}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>{props.popoverContent}</Box>
      </Popover>
    </Box>
  );
}
