/**
 *
 * CustomerSelector
 *
 */
import * as React from 'react';
import clsx from 'clsx';
import { useCustomerSelectorSlice } from './slice';
import { useSelector, useDispatch } from 'react-redux';
import { CustomerSelectButton as SelectButton } from '../../components/CustomerSelectButton';
import {
  selectAvailableCustomers,
  selectCurrentCustomers,
  selectCustomerSelector,
  selectOpen,
} from './slice/selectors';
import { selectUserData } from '../AuthProvider/slice/selectors';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  useMediaQuery,
  InputAdornment,
  useTheme,
  DialogActions,
  Button,
  Typography,
  Box,
  Hidden,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import { Customer } from './slice/types';
import {
  Person,
  PersonAddAlt1,
  PersonRemoveAlt1,
  Search,
} from '@mui/icons-material';
import { importDataActions } from '../ImportData/slice';

interface Props {
  children: React.ReactChild;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: 0,
  },
  selectedCustomer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  icon: {
    color: yellow[800],
  },
}));

export function CustomerSelector(props: Props) {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const customerSelectorState = useSelector(selectCustomerSelector);
  const { actions } = useCustomerSelectorSlice();

  // Clear if unmounted
  React.useEffect(() => {
    return () => {
      dispatch(actions.setAvailableCustomers([]));
    };
  }, []);

  React.useEffect(() => {
    dispatch(importDataActions.importStatusRequest());
  }, [customerSelectorState.currentCustomers]);

  React.useEffect(() => {
    if (!userData) return;

    const allowedSelectedCustomerIds = customerSelectorState.currentCustomers.filter(
      selectedCustomer =>
        userData.access_customers.find(
          access_customer => access_customer.id === selectedCustomer,
        ),
    );

    if (
      !allowedSelectedCustomerIds.length &&
      userData.access_customers.length > 0
    ) {
      dispatch(actions.setCurrentCustomers([userData.access_customers[0].id]));
    } else {
      dispatch(actions.setCurrentCustomers(allowedSelectedCustomerIds));
    }

    setTimeout(
      () => dispatch(actions.setAvailableCustomers(userData.access_customers)),
      1,
    );
  }, [userData]);

  return <>{React.Children.only(props.children)}</>;
}

export function CustomerSelectButton() {
  const [searchText, setSearchText] = React.useState('');
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const availableCustomers = useSelector(selectAvailableCustomers);
  const currentCustomers = useSelector(selectCurrentCustomers);

  const [selection, setSelection] = React.useState<Array<number>>(
    currentCustomers,
  );

  const open = useSelector(selectOpen);
  const dispatch = useDispatch();
  const { actions } = useCustomerSelectorSlice();

  const filteredAvailableCustomers = availableCustomers.filter(
    (customer: Customer) =>
      searchText.length
        ? customer.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        : true,
  );

  React.useEffect(() => {
    if (!open) setSelection(currentCustomers);
  }, [currentCustomers, open]);

  React.useEffect(() => {
    if (searchText)
      setSelection(
        selection.filter(
          id => filteredAvailableCustomers.map(c => c.id).indexOf(id) !== -1,
        ),
      );
  }, [searchText]);

  if (!currentCustomers.length && availableCustomers.length < 2) {
    return null;
  }

  const customers = availableCustomers.filter(
    c => currentCustomers.indexOf(c.id) > -1,
  );

  let text =
    customers.length === 1 ? customers[0].name : 'No customer selected';
  if (customers.length > 1) {
    text = `${customers.length} customers selected`;
  }

  const selectAllCustomers = () => {
    // dispatch(actions.setCurrentCustomers(availableCustomers.map(c => c.id)));
    setSelection(filteredAvailableCustomers.map(c => c.id));
  };

  const removeAllCustomers = () => {
    //dispatch(actions.setCurrentCustomers([]));
    setSelection([]);
  };

  const toggleCustomer = (id: number) => {
    if (
      selection.indexOf(id) === -1 &&
      availableCustomers.map(c => c.id).indexOf(id) !== -1
    ) {
      setSelection([...selection, id]);
    } else if (currentCustomers.indexOf(id) !== -1) {
      setSelection(selection.filter(c => c !== id));
    }
  };

  const close = (apply: boolean = true) => {
    if (selection.length) {
      if (apply) dispatch(actions.setCurrentCustomers(selection));
      dispatch(actions.setCustomerSelectOpen(false));
    }
  };

  if (availableCustomers.length === 0) {
    return null;
  }

  if (availableCustomers.length === 1) {
    return (
      <Hidden mdDown>
        <Box
          sx={{ color: 'white', display: 'flex', alignItems: 'center', mr: 2 }}
        >
          <Person color="secondary" fontSize={'large'} sx={{ mr: 1 }} />
          <Typography variant="body1" color={'inherit'}>
            {availableCustomers[0].name}
          </Typography>
        </Box>
      </Hidden>
    );
  }

  return (
    <>
      <SelectButton
        text={text}
        onClick={() => {
          dispatch(actions.setCustomerSelectOpen(true));
        }}
      />
      <Dialog
        open={open}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        onClose={(event, reason) => {
          close(false);
        }}
      >
        <DialogTitle>Select customers</DialogTitle>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            type="search"
            name={'search'}
            value={searchText}
            onChange={ev => setSearchText(ev.target.value)}
          />
        </Box>
        <DialogContent dividers sx={{ px: 0 }}>
          <List>
            <ListItem
              className={classes.listItem}
              title={'Select all customers'}
              button
              onClick={selectAllCustomers}
            >
              <ListItemText>Select all customers</ListItemText>
              <ListItemIcon>
                <PersonAddAlt1 color={'secondary'} className={classes.icon} />
              </ListItemIcon>
            </ListItem>
            <ListItem
              className={classes.listItem}
              title={'Remove all customers'}
              divider
              button
              onClick={removeAllCustomers}
            >
              <ListItemText>Remove all customers</ListItemText>
              <ListItemIcon>
                <PersonRemoveAlt1
                  color={'secondary'}
                  className={classes.icon}
                />
              </ListItemIcon>
            </ListItem>
            {filteredAvailableCustomers.map((customer: Customer) => (
              <ListItem
                key={`customer-${customer.code}-${customer.id}`}
                title={customer.name}
                button
                onClick={() => toggleCustomer(customer.id)}
                className={clsx(classes.listItem, {
                  [classes.selectedCustomer]:
                    selection.indexOf(customer.id) !== -1,
                })}
              >
                <ListItemText>{customer.name}</ListItemText>
                <ListItemIcon>
                  {selection.indexOf(customer.id) !== -1 ? (
                    <PersonRemoveAlt1
                      color={'secondary'}
                      className={classes.icon}
                    />
                  ) : (
                    <PersonAddAlt1
                      color={'secondary'}
                      className={classes.icon}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
            ))}
          </List>{' '}
        </DialogContent>
        <DialogActions>
          <Button disabled={!selection.length} onClick={() => close()}>
            Close and Apply Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
