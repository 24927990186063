/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useHasChanged } from 'utils/usePrevious';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { FilterableReportTable } from '../../../../components/FilterableReportTable';

interface Props extends ReportGraphicProps {}

export function SupplierAnalysis(props: Props) {
  const { data } = props.graphic.data!;
  const { reportSettings } = props;

  const [selectedSuppliers, setSelectedSuppliers] = React.useState<string[]>(
    reportSettings.selectedAnomaly || [],
  );

  const formatCurrency = variableFormatCurrency(1000000000, data.currency);

  const selectedSuppliersChanged = useHasChanged(selectedSuppliers);

  React.useEffect(() => {
    if (!selectedSuppliersChanged) return;
    if (props.full) {
      props.onSettingsChange({ selectedSuppliers }, true);
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!props.full ? (
          <Box>
            <List>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.suppliers',
                    '',
                  )} Suppliers`}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.unique_entries',
                    '',
                  )} Unique Entries`}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.anomalies',
                    '',
                  )} Anomalies`}
                  secondary={
                    <>
                      {Object.keys(
                        get(data, 'values.totals.anomaly_breakdown', {}),
                      )
                        .filter(key =>
                          get(
                            data,
                            `values.totals.anomaly_breakdown[${key}].count`,
                            0,
                          ),
                        )
                        .map(key => (
                          <Box
                            sx={{ my: 1 }}
                            key={`${key}-anomaly-breakdown-type`}
                          >
                            {get(
                              data,
                              `values.totals.anomaly_breakdown[${key}].label`,
                              '',
                            )}
                            :{' '}
                            {get(
                              data,
                              `values.totals.anomaly_breakdown[${key}].count`,
                              0,
                            )}
                          </Box>
                        ))}
                    </>
                  }
                />
              </ListItem>
            </List>
          </Box>
        ) : (
          <Box>
            <FilterableReportTable
              selectedItems={selectedSuppliers}
              onSelectionChange={selection => {
                setSelectedSuppliers(selection as Array<string>);
              }}
              formatCurrency={formatCurrency}
              identifier={'supplier_id'}
              columns={[
                {
                  label: 'ID',
                  field: 'supplier_id',
                  hidden: true,
                },
                {
                  label: 'Name',
                  field: 'supplier_name',
                  sort: 'asc',
                },
                {
                  label: 'Unique Entries',
                  field: 'unique_entries',
                  format: 'number',
                },
                {
                  label: 'Customs Value',
                  field: 'customs_value',
                  format: 'currency',
                },
                {
                  label: 'Duty Paid',
                  field: 'duty_paid',
                  format: 'currency',
                },
                {
                  label: 'Min Duty Rate',
                  field: 'duty_rate_min',
                  format: 'percentage',
                },
                {
                  label: 'Max Duty Rate',
                  field: 'duty_rate_max',
                  format: 'percentage',
                },
                {
                  label: 'Duty Variance',
                  field: 'duty_variance',
                  format: 'percentage',
                },
                {
                  label: 'Comm Codes',
                  field: 'commodity_codes',
                  format: 'number',
                },
                {
                  label: 'Origin Countries',
                  field: 'origin_countries',
                  format: 'number',
                },
                {
                  label: 'CPCs',
                  field: 'customs_procedure_codes',
                  format: 'number',
                },
                {
                  label: 'Anomalies',
                  field: 'anomalies',
                  format: 'number',
                },
              ]}
              data={data.values.all_suppliers || []}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
