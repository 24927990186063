import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

yup.addMethod<yup.StringSchema>(yup.string, 'checkPassword', function (
  message = 'Needs to match all of the password rules',
) {
  return this.test('regexes', message, function (value) {
    const rules = [
      {
        name: 'Min string length of 10 characters',
        pattern: /.{10,}/,
      },
      {
        name: 'Contains at least one numerical character',
        pattern: /[0-9]/,
      },
      {
        name: 'Contains at least one lowercase character',
        pattern: /[a-z]/,
      },
      {
        name: 'Contains at least one uppercase character',
        pattern: /[A-Z]/,
      },
      {
        name: 'Contains at least one special character',
        pattern: /[!@#$%^&*()_+={};':",.<>?|£€~¬¦\-\][/\\]/,
      },
    ];
    let count = 0;
    const passed: Array<any> = [];
    const failed: Array<any> = [];
    rules.forEach((rule: any) => {
      if (rule.pattern.test(typeof value === 'string' ? value : '')) {
        count += 1;
        passed.push(rule.name);
      } else {
        failed.push(rule.name);
      }
    });
    if (count > 4) return true;
    const { path, createError } = this;
    return createError({
      path,
      message: `${message} - ${failed.join(', ')}.`,
    });
  });
});

/*
 * Extend Typescript definitions
 * See https://github.com/jquense/yup/issues/312 for details on below
 */

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    checkPassword(): StringSchema<TType, TContext>;
  }
}

export default yup;
