import {
  call,
  cancelled,
  put,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { createUploadFileChannel } from 'utils/saga/createFileUploadChannel';
import { importDataActions as actions } from '.';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';
import { loggingProviderActions } from '../../LoggingProvider/slice';

function* importStatus() {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);

    if (currentCustomers.length !== 1) return;

    const payload = yield call(apiRequest, {
      url: 'line-imports/status',
      method: 'post',
      data: {
        customer_id: currentCustomers[0],
      },
    });
    yield put(actions.importStatusSuccess(payload));
  } catch (error) {
    yield put(actions.importStatusFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* importData(action) {
  const currentCustomers = yield select(selectCurrentCustomers);
  const channel = yield call(createUploadFileChannel, 'line-imports/upload', {
    ...action.payload,
    customer_id: currentCustomers[0],
  });
  while (true) {
    const { progress = 0, err, success, response } = yield take(channel);
    if (err) {
      yield put(actions.importDataFailed(err));
      return;
    }
    if (success) {
      yield put(actions.importStatusRequest());
      yield put(actions.importDataSuccess(response));
      return;
    }
    yield put(actions.importDataProgress({ progress }));
  }
}

function* confirmImport(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `line-imports/confirm/${action.payload.id}`,
      method: 'post',
      data: {
        ...action.payload,
      },
    });
    yield put(actions.confirmImportSuccess(payload));
    yield put(actions.importStatusRequest());
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'import',
        action: 'import-confirmed',
        params: '',
        data: action.payload,
      }),
    );
  } catch (error) {
    yield put(actions.confirmImportFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* rollbackImport(action) {
  const currentCustomers = yield select(selectCurrentCustomers);
  try {
    const payload = yield call(apiRequest, {
      url: `line-imports/rollback/${action.payload.id}`,
      method: 'post',
      data: {
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.rollbackImportSuccess(payload));
    yield put(actions.importStatusRequest());
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'import',
        action: 'import-rollback',
        params: '',
        data: action.payload,
      }),
    );
  } catch (error) {
    yield put(actions.rollbackImportFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* importDataSaga() {
  yield takeLatest(actions.importStatusRequest.type, importStatus);

  yield takeLatest(actions.importDataRequest.type, importData);
  yield takeLatest(actions.confirmImportRequest.type, confirmImport);
  yield takeLatest(actions.rollbackImportRequest.type, rollbackImport);
}
