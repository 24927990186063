/**
 *
 * TabbedContent
 *
 */
import * as React from 'react';
import { Box, Container, Paper, Tab, Tabs } from '@mui/material';
import { v4 } from 'uuid';
import { RouteComponentProps, useLocation, useRouteMatch } from 'react-router';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

interface Props {
  tabs: Array<{
    label: string;
    path: string;
    exact?: boolean;
    default?: boolean;
    hidden?: boolean;
    component?: any;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
    [key: string]: any;
  }>;
}

export function TabbedContent(props: Props) {
  const match = useRouteMatch();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const currentTabIndex = props.tabs.findIndex(
      tab => location.pathname.indexOf(`${match.path}${tab.path}`) === 0,
    );
    setValue(currentTabIndex > -1 ? currentTabIndex : 0);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const defaultPath = (props.tabs.find(tab => tab.default) || {}).path;

  return (
    <Box>
      <Paper square>
        <Tabs value={value} onChange={handleChange} centered>
          {props.tabs
            .filter(tab => !tab.hidden)
            .map(tab => (
              <Tab
                key={v4()}
                label={tab.label}
                component={Link}
                to={`${match.path}${tab.path}`}
              />
            ))}
        </Tabs>
      </Paper>

      <Container>
        <Switch location={location}>
          {props.tabs
            .filter(tab => !tab.hidden)
            .map(tab => (
              <Route
                key={tab.label}
                path={`${match.path}${tab.path}`}
                component={tab.component}
                render={tab.render}
                exact={tab.exact}
              />
            ))}
          <Route
            render={() => (
              <Redirect
                to={defaultPath ? `${match.path}${defaultPath}` : '/'}
              />
            )}
          />
        </Switch>
      </Container>
    </Box>
  );
}
