import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { lineFieldSaga } from './saga';
import {
  AdjustedItems,
  AdjustmentIntent,
  ChangeableField,
  ChangeAction,
  LineAdjustmentManagerState,
} from './types';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: LineAdjustmentManagerState = {
  adjustmentIntent: {
    loading: false,
  },
  adjustedItems: {
    loading: false,
  },
  confirmAdjustment: {
    loading: false,
    data: null,
  },
};

const slice = createSlice({
  name: 'lineAdjustmentManager',
  initialState,
  reducers: {
    adjustmentManagerMount(state, action: PayloadAction<void>) {
      state.adjustmentIntent = { ...initialState.adjustmentIntent };
      state.adjustedItems = { ...initialState.adjustedItems };
      state.confirmAdjustment = { ...initialState.confirmAdjustment };
    },
    adjustmentIntentRequest(
      state,
      action: PayloadAction<{ change: ChangeAction; ids: number[] }>,
    ) {
      state.adjustmentIntent = {
        ...initialState.adjustmentIntent,
        loading: true,
      };
    },
    adjustmentIntentSuccess(state, action: PayloadAction<AdjustmentIntent>) {
      state.adjustmentIntent.loading = false;
      state.adjustmentIntent.data = action.payload;
    },
    adjustmentIntentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.adjustmentIntent.loading = false;
      state.adjustmentIntent.error = action.payload;
    },
    adjustedItemsRequest(state, action: PayloadAction<any>) {
      state.adjustedItems = { ...initialState.adjustedItems, loading: true };
    },
    adjustedItemsSuccess(state, action: PayloadAction<AdjustedItems>) {
      state.adjustedItems.loading = false;
      state.adjustedItems.data = action.payload;
    },
    adjustedItemsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.adjustedItems.loading = false;
      state.adjustedItems.error = action.payload;
    },
    confirmAdjustmentRequest(
      state,
      action: PayloadAction<{
        change: ChangeAction;
        key: string;
        values: Array<{ [key: string]: any }>;
      }>,
    ) {
      state.confirmAdjustment = {
        ...initialState.confirmAdjustment,
        loading: true,
      };
    },
    confirmAdjustmentSuccess(state, action: PayloadAction<any>) {
      state.confirmAdjustment.loading = false;
      state.confirmAdjustment.data = {
        status: 'complete',
      };
    },
    confirmAdjustmentFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.adjustedItems.loading = false;
      state.adjustedItems.error = action.payload;
    },
  },
});

export const { actions: lineFieldActions } = slice;

export const useLineFieldSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: lineFieldSaga });
  return { actions: slice.actions };
};
