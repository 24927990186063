/**
 *
 * ImportStepper
 *
 */
import * as React from 'react';
import {
  Box,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useTheme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ImportStatus } from '../slice/types';

interface Props {
  status: ImportStatus;
}

export function ImportStepper(props: Props) {
  const steps = [
    { label: 'Select File', code: 'clean' },
    { label: 'Importing', code: 'importing' },
    { label: 'Review', code: 'pending' },
    { label: 'Confirming', code: 'confirming' },
  ];
  return (
    <Box sx={{ mb: 4 }}>
      <Stepper
        alternativeLabel
        activeStep={steps.findIndex(step => step.code === props.status) || 0}
      >
        {steps.map((step, index) => (
          <Step key={step.code}>
            <StepLabel>
              <Typography
                sx={{ textTransform: 'uppercase', fontWeight: 600 }}
                variant="h6"
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
