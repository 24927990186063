import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ResponseErrorPayload } from 'utils/request';
import { importDataSaga } from './saga';
import { ImportDataState } from './types';
import { apiCallInitialState, uploadApiCallInitialState } from 'types/ApiCall';

export const initialState: ImportDataState = {
  importStatus: {
    ...apiCallInitialState,
    data: { status: undefined, all: [], last_data_stream_id: undefined },
  },
  importData: uploadApiCallInitialState,
  confirmImport: apiCallInitialState,
  rollbackImport: apiCallInitialState,
};

interface ImportRequest {
  data_stream_id: number;
  fileContents: any;
  filename: string;
  password?: string;
  filesize: number;
  filetype: number;
}

const slice = createSlice({
  name: 'importData',
  initialState,
  reducers: {
    importStatusRequest(state, action: PayloadAction<void>) {
      state.importStatus.loading = true;
    },
    importStatusSuccess(state, action: PayloadAction<any>) {
      state.importStatus.loading = false;
      state.importStatus.data = action.payload;
      state.importStatus.error = undefined;
    },
    importStatusFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.importStatus.loading = false;
      state.importStatus.error = action.payload;
    },
    importStatusReset(state, action: PayloadAction<void>) {
      state.importStatus = initialState.importStatus;
    },
    importDataRequest(state, action: PayloadAction<ImportRequest>) {
      state.importData.loading = true;
      state.importData.error = undefined;
      state.importData.progress = 0;
    },
    importDataSuccess(state, action: PayloadAction<{ file: File }>) {
      state.importData.loading = false;
      state.importData.data = action.payload;
      state.importData.error = undefined;
    },
    importDataProgress(state, action: PayloadAction<{ progress: number }>) {
      state.importData.progress = action.payload.progress;
    },
    importDataFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.importData.loading = false;
      state.importData.progress = 0;
      state.importData.error = action.payload;
    },
    importDataReset(state, action: PayloadAction<void>) {
      state.importData = uploadApiCallInitialState;
    },
    confirmImportRequest(
      state,
      action: PayloadAction<{ id: number; lookups: Array<any> }>,
    ) {
      state.confirmImport.loading = true;
    },
    confirmImportSuccess(state, action: PayloadAction<any>) {
      state.confirmImport.loading = false;
      state.confirmImport.data = action.payload;
      state.confirmImport.error = undefined;
    },
    confirmImportFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.confirmImport.loading = false;
      state.confirmImport.error = action.payload;
    },
    rollbackImportRequest(state, action: PayloadAction<{ id: number }>) {
      state.rollbackImport.loading = true;
    },
    rollbackImportSuccess(state, action: PayloadAction<any>) {
      state.rollbackImport.loading = false;
      state.rollbackImport.data = action.payload;
      state.rollbackImport.error = undefined;
    },
    rollbackImportFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.rollbackImport.loading = false;
      state.rollbackImport.error = action.payload;
    },
  },
});

export const { actions: importDataActions } = slice;

export const useImportDataSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: importDataSaga });
  return { actions: slice.actions };
};
