import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { amendedItemsSaga } from './saga';
import { Adjustment, AmendedItemsState } from './types';
import { ResponseErrorPayload } from 'utils/request';
import { AdjustmentReport } from '../../AdjustmentReports/slice/types';

export const initialState: AmendedItemsState = {
  loadAdjustments: {
    loading: false,
    data: [],
  },
  revertAdjustments: {
    loading: false,
    data: null,
  },
  createAdjustmentReport: {
    loading: false,
    data: null,
  },
};

const slice = createSlice({
  name: 'amendedItems',
  initialState,
  reducers: {
    initialise(state, action: PayloadAction<void>) {
      state.loadAdjustments = initialState.loadAdjustments;
      state.revertAdjustments = initialState.revertAdjustments;
      state.createAdjustmentReport = initialState.createAdjustmentReport;
    },
    loadAdjustmentsRequest(
      state,
      action: PayloadAction<{ filters: { [key: string]: any } }>,
    ) {
      state.loadAdjustments.loading = true;
      state.loadAdjustments.error = undefined;
    },
    loadAdjustmentsSuccess(
      state,
      action: PayloadAction<{ data: Adjustment[] }>,
    ) {
      state.loadAdjustments.loading = false;
      state.loadAdjustments.data = action.payload.data;
      state.loadAdjustments.error = undefined;
    },
    loadAdjustmentsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadAdjustments.loading = false;
      state.loadAdjustments.error = action.payload;
    },
    revertAdjustmentsRequest(state, action: PayloadAction<{ ids: number[] }>) {
      state.revertAdjustments.loading = true;
      state.revertAdjustments.error = undefined;
    },
    revertAdjustmentsSuccess(state, action: PayloadAction<any>) {
      state.revertAdjustments.loading = false;
      state.revertAdjustments.data = action.payload;
      state.revertAdjustments.error = undefined;
    },
    revertAdjustmentsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.revertAdjustments.loading = false;
      state.revertAdjustments.error = action.payload;
    },
    createAdjustmentReportRequest(
      state,
      action: PayloadAction<{ reference?: string; ids: number[] }>,
    ) {
      state.createAdjustmentReport.loading = true;
      state.createAdjustmentReport.error = undefined;
    },
    createAdjustmentReportSuccess(
      state,
      action: PayloadAction<{ data: AdjustmentReport }>,
    ) {
      state.createAdjustmentReport.loading = false;
      state.createAdjustmentReport.data = action.payload.data;
      state.createAdjustmentReport.error = undefined;
    },
    createAdjustmentReportFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.createAdjustmentReport.loading = false;
      state.createAdjustmentReport.error = action.payload;
    },
  },
});

export const { actions: amendedItemsActions } = slice;

export const useAmendedItemsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: amendedItemsSaga });
  return { actions: slice.actions };
};
