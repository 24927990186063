/**
 *
 * InputMasks
 *
 */
import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface FormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const CommCodeFormatCustom = React.forwardRef<
  IMaskInput,
  FormatCustomProps
>(function CommCodeFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      mask="00 00 00 00 00"
      {...other}
      unmask={true} // true|false|'typed'
      inputRef={ref} // access to nested input
      onAccept={(value, mask) => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        });
      }}
      placeholder="Enter code here"
    />
  );
});

export const CurrencyFormatCustom = React.forwardRef<
  IMaskInput,
  FormatCustomProps
>(function CommCodeFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      mask={Number}
      {...other}
      radix="."
      mapToRadix={['.']}
      scale={2}
      padFractionalZeros={true}
      unmask={'typed'} // true|false|'typed'
      inputRef={ref} // access to nested input
      onAccept={(value, mask) => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        });
      }}
      placeholder="Enter value here"
    />
  );
});
