import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.amendedItems || initialState;

export const selectAmendedItems = createSelector([selectSlice], state => state);
export const selectLoadAdjustments = createSelector(
  [selectSlice],
  state => state.loadAdjustments,
);
export const selectRevertAdjustments = createSelector(
  [selectSlice],
  state => state.revertAdjustments,
);
export const selectCreateAdjustmentReport = createSelector(
  [selectSlice],
  state => state.createAdjustmentReport,
);
