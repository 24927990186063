/**
 *
 * ImportMassAdjustments
 *
 */
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useEffectOnce } from '../../../utils/useEffectOnce';
import { useImportMassAdjustmentsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectImportMassAdjustments } from './slice/selectors';
import { FileUploadFormElement } from '../../components/FileUploadFormElement';
import { getBase64 } from '../../../utils/saga/createFileUploadChannel';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';
import { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { MassAdjustmentPreview } from './MassAdjustmentPreview';
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { useConfirm } from '../../components/ConfirmDialog';

interface Props {}

export function ImportMassAdjustment(props: Props) {
  const [adjustment, setAdjustment] = useState<{ [key: string]: any } | null>(
    null,
  );
  const history = useHistory();
  const { actions } = useImportMassAdjustmentsSlice();
  const dispatch = useDispatch();
  const { importMassAdjustment, confirmMassAdjustment } = useSelector(
    selectImportMassAdjustments,
  );
  const match = useRouteMatch();
  const confirm = useConfirm();

  const snackbar = useSnackbar();
  const importingChanged = useHasChanged(importMassAdjustment.loading);
  const confirmingChanged = useHasChanged(confirmMassAdjustment.loading);

  const cancelImport = () => {
    dispatch(actions.initialise());
  };

  const confirmImport = () => {
    confirm({
      title: 'Confirm adjustment import?',
      description: <>This will create a pending adjustment report.</>,
    }).then(() =>
      dispatch(
        actions.confirmMassAdjustmentRequest({
          key: importMassAdjustment.data?.key!,
        }),
      ),
    );
  };

  useEffect(() => {
    if (importingChanged && !importMassAdjustment.loading) {
      if (importMassAdjustment.error) {
        snackbar.enqueueSnackbar(importMassAdjustment.error.message, {
          variant: 'error',
        });
      }
      if (importMassAdjustment.data) {
        setAdjustment(importMassAdjustment.data);
      }
    }

    if (confirmingChanged && !confirmMassAdjustment.loading) {
      if (confirmMassAdjustment.error) {
        snackbar.enqueueSnackbar(confirmMassAdjustment.error.message, {
          variant: 'error',
        });
      }
      if (confirmMassAdjustment.data) {
        dispatch(actions.initialise());
        snackbar.enqueueSnackbar(
          'Mass adjustment report created successfully',
          {
            variant: 'success',
          },
        );
        history.push('pending-approval');
      }
    }
  });

  useEffectOnce(() => {
    dispatch(actions.initialise());
    return () => {
      dispatch(actions.initialise());
    };
  });

  if (!importMassAdjustment.data) {
    return (
      <>
        <Paper sx={{ p: 4, my: 4 }}>
          <Stack spacing={3}>
            <Box sx={{}}>
              <Typography variant={'h3'}>Import mass adjustment</Typography>
              <Typography sx={{ mt: 2 }}>
                Use the button below to select a formatted mass adjustment
                sheet. You can preview your adjustments in the next step.
              </Typography>
            </Box>
            <FileUploadFormElement
              progress={importMassAdjustment.progress}
              loading={importMassAdjustment.loading}
              onFileChange={f => {
                if (f)
                  getBase64(f).then(fileContents =>
                    dispatch(
                      actions.importMassAdjustmentRequest({
                        fileContents: fileContents as string,
                        filename: f.name,
                        filesize: f.size,
                        filetype: f.type,
                      }),
                    ),
                  );
              }}
            />
          </Stack>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ p: 4, my: 4 }}>
        <Stack spacing={3}>
          <Box sx={{}}>
            <Typography variant={'h3'}>Preview adjustment</Typography>
            <Typography sx={{ mt: 2 }}>
              Check your adjustment below. Grey lines represent the base
              unchanged line and the white lines show the new data with field
              changes highlighted.
            </Typography>
          </Box>
          <MassAdjustmentPreview
            adjustments={importMassAdjustment.data?.adjustments}
          />
          <Divider />
          <Box>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Button variant={'text'} color={'error'} onClick={cancelImport}>
                Cancel
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={confirmImport}
              >
                Approve
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </>
  );
}
