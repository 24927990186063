import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { selectCurrentCustomers } from 'app/containers/CustomerSelector/slice/selectors';
import { apiRequest } from 'utils/request';
import { usersSliceActions as actions } from '.';
import { authProviderActions } from '../../AuthProvider/slice';

function* loadUsers(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `users`,
      method: 'get',
      data: {
        ...action.payload,
      },
    });

    const customers = yield call(apiRequest, {
      url: 'customers',
      method: 'get',
      data: { 'page[size]': 1000 },
    });

    yield put(actions.loadUsersSuccess({ ...payload, customers }));
  } catch (error) {
    yield put(actions.loadUsersFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadUser(action) {
  try {
    let payload;

    if (action.payload.id !== 'new') {
      payload = yield call(apiRequest, {
        url: `users/${action.payload.id}`,
        method: 'get',
        data: {},
      });
    }

    const customers = yield call(apiRequest, {
      url: 'customers',
      method: 'get',
      data: { 'page[size]': 1000 },
    });

    yield put(actions.loadUserSuccess({ ...payload, customers }));
  } catch (error) {
    yield put(actions.loadUserFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveUser(action) {
  try {
    const payload = yield call(apiRequest, {
      url: action.payload.id ? `users/${action.payload.id}` : 'users',
      method: action.payload.id ? 'put' : 'post',
      data: {
        ...action.payload,
      },
    });

    yield put(authProviderActions.loadUserDataRequest());
    yield put(actions.saveUserSuccess(payload));
  } catch (error) {
    yield put(actions.saveUserFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* deleteUser(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `users/${action.payload.id}`,
      method: 'delete',
      data: {},
    });

    yield put(authProviderActions.loadUserDataRequest());
    yield put(actions.deleteUserSuccess(payload));
  } catch (error) {
    yield put(actions.deleteUserFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* usersSliceSaga() {
  yield takeLatest(actions.loadUsersRequest.type, loadUsers);
  yield takeLatest(actions.loadUserRequest.type, loadUser);
  yield takeLatest(actions.saveUserRequest.type, saveUser);
  yield takeLatest(actions.deleteUserRequest.type, deleteUser);
}
