import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ISupplierAction, SupplierApiCall } from './slice/types';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { ApiCall } from '../../../types/ApiCall';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomAutocomplete from './CustomAutocomplete';

interface Props {
  suppliers: SupplierApiCall;
  supplierActions: ApiCall;
  actionRequest: (supplierActions: ISupplierAction[]) => void;
}

export function AssignSuppliers(props: Props) {
  const { suppliers, supplierActions, actionRequest } = props;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [inputValue, setInputValue] = useState('');

  const [selectedCandidateSuppliers, setSelectedCandidateSuppliers] = useState<
    { id: number | null | undefined; name: string }[]
  >([]);

  const [selectedTargetSupplier, setSelectedTargetSupplier] = useState<{
    id: number | null | undefined;
    name: string;
  } | null>(null);

  const candidateSupplierList = useMemo(() => {
    return suppliers.data
      .filter(s => s.id !== (selectedTargetSupplier || {}).id)
      .filter(supplier => supplier.supplier_names.length <= 1)
      .map(supplier => ({
        id: supplier.id,
        name: `${supplier.name}`,
      }));
  }, [suppliers, selectedTargetSupplier]);

  const targetSupplierList = useMemo(() => {
    return suppliers.data
      .filter(s => !selectedCandidateSuppliers.find(scs => scs.id === s.id))
      .map(supplier => ({
        id: supplier.id,
        name: `${supplier.name} (${supplier.supplier_names.length} name${
          supplier.supplier_names.length > 1 ? 's' : ''
        } assigned)`,
      }));
  }, [suppliers, selectedCandidateSuppliers]);

  const supplierActionsList: ISupplierAction[] = useMemo(() => {
    if (!selectedTargetSupplier || selectedCandidateSuppliers.length < 1)
      return [];
    return selectedCandidateSuppliers.map(scs => {
      return {
        type: 'assign-name',
        name: (suppliers.data.find(s => s.id === scs.id) || {}).name || '',
        supplier_id: +selectedTargetSupplier.id!,
      } as ISupplierAction;
    });
  }, [suppliers, selectedCandidateSuppliers, selectedTargetSupplier]);

  const submitActions = useCallback(() => {
    actionRequest(supplierActionsList);
  }, [supplierActionsList]);

  const getBaseName = useCallback(
    id => (suppliers.data.find(s => s.id === id) || {}).name || '',
    [suppliers.data],
  );

  const confirmationText = useMemo(() => {
    if (!selectedTargetSupplier || selectedCandidateSuppliers.length < 1)
      return '';
    return (
      <>
        Assigning <br />
        <b>
          {selectedCandidateSuppliers.map(s => getBaseName(s.id)).join(', ')}
        </b>
        <br /> to <br />
        <b>{getBaseName(selectedTargetSupplier!.id)}</b>
      </>
    );
  }, [selectedCandidateSuppliers, selectedTargetSupplier, getBaseName]);

  const supplierActionsLoadingChanged = useHasChanged(supplierActions.loading);
  const suppliersLoadingChanged = useHasChanged(suppliers.loading);

  useEffect(() => {
    setConfirmDialogOpen(false);
    if (!selectedTargetSupplier) {
      setSelectedCandidateSuppliers([]);
    }
  }, [selectedTargetSupplier]);

  useEffect(() => {
    if (supplierActionsLoadingChanged && !supplierActions.loading) {
      setConfirmDialogOpen(false);
      enqueueSnackbar(
        `Supplier name${
          selectedCandidateSuppliers.length > 1 ? 's' : ''
        } assigned successfully`,
        {
          variant: 'success',
        },
      );
      setSelectedCandidateSuppliers([]);
      setSelectedTargetSupplier(null);
    }
    if (suppliersLoadingChanged && !suppliers.loading) {
      setConfirmDialogOpen(false);
    }
  });

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <Paper
              elevation={0}
              square
              sx={{ my: 4, p: 4, backgroundColor: `grey.A100`, height: '100%' }}
            >
              <Stack spacing={2}>
                <Typography variant={'h6'}>
                  Select the target supplier
                </Typography>
                <Typography variant={'body1'}>
                  Select the target supplier that you will assign your selected
                  names to.
                </Typography>
                <Autocomplete
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  inputValue={inputValue}
                  sx={{ backgroundColor: 'white' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Select supplier(s) to assign'}
                    />
                  )}
                  options={targetSupplierList}
                  value={selectedTargetSupplier || null}
                  fullWidth
                  getOptionLabel={(option: any) => option.name ?? 'Select'}
                  onChange={(event, newValue) => {
                    setSelectedTargetSupplier(newValue);
                    setInputValue(newValue?.name || ''); // Ensure inputValue reflects the selected option
                  }}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              mb: 2,
              pointerEvents: selectedTargetSupplier ? undefined : 'none',
              opacity: selectedTargetSupplier ? 1 : 0,
            }}
          >
            <Paper
              elevation={0}
              square
              sx={{ my: 4, p: 4, backgroundColor: `grey.A100`, height: '100%' }}
            >
              <Stack spacing={2}>
                <Typography variant={'h6'}>
                  Select supplier names to assign
                </Typography>
                <Typography variant={'body1'}>
                  Select supplier names you would like to assign to the target
                  supplier.
                </Typography>
                <CustomAutocomplete
                  candidateSupplierList={candidateSupplierList}
                  selectedSuppliers={selectedCandidateSuppliers}
                  onChange={v => setSelectedCandidateSuppliers(v)}
                />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: `grey.A100`,
          textAlign: 'right',
        }}
      >
        <Button
          variant={'contained'}
          disabled={
            !selectedCandidateSuppliers.length || !selectedTargetSupplier
          }
          color={'primary'}
          onClick={() => {
            setConfirmDialogOpen(true);
          }}
        >
          Assign to selected supplier
        </Button>
      </Box>
      <Dialog
        open={
          confirmDialogOpen &&
          !(!selectedCandidateSuppliers.length || !selectedTargetSupplier)
        }
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown
      >
        <DialogTitle>Assigning supplier names - please confirm</DialogTitle>
        <DialogContent>
          <DialogContentText variant={'body1'}>
            CAT360 will consolidate individual entries from these suppliers for
            anomalies calculations and statistics purposes. The underlying MSS
            data will not be amended.
          </DialogContentText>
          {!!confirmationText && (
            <DialogContentText sx={{ mt: 2 }}>
              {confirmationText}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ mr: 'auto' }}
            variant="text"
            onClick={() => {
              setConfirmDialogOpen(false);
            }}
            disabled={supplierActions.loading}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              submitActions();
            }}
            disabled={supplierActions.loading}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
