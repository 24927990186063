import storage from 'local-storage-fallback';

export const loadState = () => {
  try {
    const serializedState = storage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

export const saveState = state => {
  try {
    storage.setItem('state', JSON.stringify(state));
  } catch (err) {
    // Ignore write errors.
    console.log(err);
  }
};
