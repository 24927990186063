/**
 *
 * ImportData
 *
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImportDataSlice } from './slice';
import { getBase64 } from 'utils/saga/createFileUploadChannel';
import {
  selectConfirmImport,
  selectImportDataStatus,
  selectImportDataUpload,
  selectRollbackImport,
} from './slice/selectors';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ImportDataForm } from './ImportDataForm';
import { useSnackbar } from 'notistack';
import { ConfirmImportForm } from './ConfirmImportForm';
import { ImportStepper } from './ImportStepper';
import { selectCurrentCustomers } from '../CustomerSelector/slice/selectors';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Close, Undo } from '@mui/icons-material';
import { green, orange, red } from '@mui/material/colors';
import { useConfirm } from '../../components/ConfirmDialog';
import { useInterval } from '../../../utils/useInterval';
import key from 'weak-key';
import { Helmet } from 'react-helmet-async';

interface Props {}

export function ImportData(props: Props) {
  const { actions } = useImportDataSlice();
  const importStatus = useSelector(selectImportDataStatus);
  const importUpload = useSelector(selectImportDataUpload);
  const rollbackImport = useSelector(selectRollbackImport);
  const confirmImport = useSelector(selectConfirmImport);
  const currentCustomers = useSelector(selectCurrentCustomers);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [searchText, setSearchText] = useState<string>('');

  // sets how many previous imports are shown
  const [recentShowCount, setRecentShowCount] = useState<number>(5);

  useInterval(() => {
    if (
      ['deleting', 'importing', 'confirming'].indexOf(
        importStatus.data.status!,
      ) !== -1
    ) {
      dispatch(actions.importStatusRequest());
    }
  }, 5000);

  // Load current status on mount
  React.useEffect(() => {
    if (currentCustomers.length !== 1) return;
    dispatch(actions.importStatusRequest());
    return () => {
      dispatch(actions.importDataReset());
    };
  }, [actions, dispatch]);

  React.useEffect(() => {
    if (importUpload.error) {
      enqueueSnackbar(importUpload.error.message, {
        variant: 'error',
      });
    }
  }, [importUpload.error, enqueueSnackbar, closeSnackbar]);

  React.useEffect(() => {
    if (rollbackImport.error) {
      enqueueSnackbar(rollbackImport.error.message, {
        variant: 'error',
      });
    }
  }, [rollbackImport.error, enqueueSnackbar, closeSnackbar]);

  React.useEffect(() => {
    if (confirmImport.error) {
      enqueueSnackbar(confirmImport.error.message, {
        variant: 'error',
      });
    }
  }, [confirmImport.error, enqueueSnackbar, closeSnackbar]);

  if (currentCustomers.length !== 1) {
    return <Redirect to="/" />;
  }

  /*
    if (!importStatus.data.status || importStatus.loading)
      return (
        <LoadingIndicator minHeight={300} message={'Retrieving import status'} />
      );
  */

  let component: any = null;
  switch (importStatus.data.status) {
    case 'pending':
      component = (
        <ConfirmImportForm
          line_import={importStatus.data.line_import}
          confirmRequest={lookups => {
            console.log('confirm');
            dispatch(
              actions.confirmImportRequest({
                id: importStatus.data!.line_import.id,
                lookups: lookups.map(lookup => lookup.action),
              }),
            );
          }}
          rollbackRequest={() => {
            console.log('rollback');
            dispatch(
              actions.rollbackImportRequest({
                id: importStatus.data!.line_import.id,
              }),
            );
          }}
        />
      );
      break;
    case 'clean':
      component = (
        <ImportDataForm
          lastDataStreamId={importStatus.data.last_data_stream_id}
          loading={importUpload.loading}
          progress={importUpload.progress}
          request={(file, data_stream_id, password) =>
            getBase64(file).then(fileContents =>
              dispatch(
                actions.importDataRequest({
                  data_stream_id,
                  password,
                  fileContents,
                  filename: file.name,
                  filesize: file.size,
                  filetype: file.type,
                }),
              ),
            )
          }
        />
      );
      break;

    case 'importing':
      component = (
        <LoadingIndicator
          minHeight={300}
          ProgressProps={{
            variant: 'determinate',
            value:
              (100 * importStatus.data.line_import.line_item_count) /
              importStatus.data.line_import.import_info.line_item_count,
          }}
          message={
            <>
              <Typography variant="h5">
                Data is importing -{' '}
                {importStatus.data.line_import.line_item_count} of{' '}
                {importStatus.data.line_import.import_info.line_item_count}{' '}
              </Typography>
              <Typography variant={'subtitle2'}>
                Time to completion approx{' '}
                {importStatus.data.line_import.time_left}
              </Typography>
            </>
          }
        />
      );
      break;
    case 'confirming':
      component = (
        <LoadingIndicator
          minHeight={300}
          message={<Typography variant="h5">Confirming data import</Typography>}
        />
      );
      break;
    case 'deleting':
      component = (
        <LoadingIndicator
          minHeight={300}
          message={<Typography variant="h5">Reverting data import</Typography>}
        />
      );
      break;
    default:
      component = <LoadingIndicator minHeight={300} />;
  }

  const showRecent =
    ['clean', 'importing'].indexOf(importStatus.data.status!) !== -1;
  return (
    <Container>
      <Helmet title={'Import Data'}></Helmet>
      <Box sx={{ my: 4 }}>
        <Paper sx={{ mt: 2 }}>
          <Box
            sx={{
              py: 3,
              px: 3,
              mb: 4,
              borderBottom: 1,
              borderColor: 'grey.300',
            }}
          >
            <Typography variant="h4" sx={{}}>
              Import Data
            </Typography>
          </Box>

          <ImportStepper status={importStatus.data!.status || 'clean'} />
          <Box sx={{ m: 3, pb: 3 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={showRecent ? 6 : 12}>
                <Box sx={{ mb: 3 }}>{component}</Box>
              </Grid>
              {showRecent && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Typography variant={'subtitle2'}>
                      Recent imports
                    </Typography>
                    <TextField
                      label={'Search by file name'}
                      variant={'outlined'}
                      type={'text'}
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setSearchText('')}
                            size={'large'}
                          >
                            <Close />
                          </IconButton>
                        ),
                      }}
                    />
                    {importStatus.data.all.length > 0 ? (
                      <>
                        <List>
                          {importStatus.data.all
                            .filter(
                              item =>
                                searchText === '' ||
                                item.import_info?.name
                                  .toLowerCase()
                                  .indexOf(searchText.toLowerCase()) !== -1,
                            )
                            .slice(0, recentShowCount)
                            .map(item => {
                              let chipColor = 'inherit';
                              switch (item.status) {
                                case 'pending':
                                  chipColor = red.A200;
                                  break;
                                case 'importing':
                                  chipColor = orange.A200;
                                  break;
                                case 'current':
                                  chipColor = green.A200;
                                  break;
                              }
                              return (
                                <ListItem
                                  key={key(item)}
                                  title={(item.import_info || {}).name}
                                  divider
                                >
                                  <ListItemIcon sx={{ minWidth: 100 }}>
                                    <Chip
                                      style={{
                                        marginLeft: -16,
                                        marginRight: 8,
                                        backgroundColor: chipColor,
                                      }}
                                      label={item.status?.toUpperCase()}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    title={item.status}
                                    primary={
                                      <Box>
                                        {item.import_info ? (
                                          <Typography>
                                            {item.import_info.name} (
                                            {item.import_info.line_item_count}{' '}
                                            lines)
                                          </Typography>
                                        ) : (
                                          '[Data file information not found]'
                                        )}
                                      </Box>
                                    }
                                    secondary={item.age}
                                  />
                                  <ListItemSecondaryAction>
                                    <Tooltip
                                      title={'Roll back / cancel this import'}
                                    >
                                      <IconButton
                                        onClick={() => {
                                          confirm({
                                            description:
                                              'Roll back this import. This action cannot be reversed. Are you sure?',
                                          })
                                            .then(() => {
                                              dispatch(
                                                actions.rollbackImportRequest({
                                                  id: item.id,
                                                }),
                                              );
                                            })
                                            .catch(null);
                                        }}
                                        size="large"
                                      >
                                        <Undo />
                                      </IconButton>
                                    </Tooltip>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                        </List>
                        {recentShowCount < importStatus.data.all.length && (
                          <Box sx={{ mt: 2 }}>
                            <Button
                              onClick={() => setRecentShowCount(rsc => rsc + 5)}
                            >
                              Show more
                            </Button>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Typography variant={'body1'}>
                        No recent imports found.
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
