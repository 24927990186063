import * as React from 'react';
import { isEqual, get } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdjustedItems, AdjustmentIntent } from '../slice/types';
import { DutyMeasure, LineItem } from '../../ImportData/slice/types';
import {
  formatCurrency,
  formatCurrencyFactory,
  formatCurrencyTextField,
} from '../../../../utils/formatCurrency';
import { LineAdjustmentFormProps } from './index';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

const fields = {
  customs_procedure_code: {
    label: 'Customs Procedure Code',
  },
};

type ValuesType = {
  [k in keyof typeof fields]: string;
};

interface Props extends LineAdjustmentFormProps {}

export function CpcAdjustmentForm(props: Props) {
  const [step, setStep] = React.useState<number>(1);
  const defaultValues = props.adjustmentIntent.changeable_fields.reduce(
    (acc: any, curr) => {
      acc[curr.field] = curr.default_value;
      return acc;
    },
    {},
  );

  const formatCurrency = formatCurrencyFactory();

  const [values, setValues] = React.useState<ValuesType>(defaultValues);

  type CalculatedValueType = {
    line_item_id: number | string;
    item_customs_duty_paid: number | string;
    item_vat_paid: number | string;
    message: number | string;
  };
  const [calculatedValues, setCalculatedValues] = React.useState<
    CalculatedValueType[]
  >([]);

  const getCalculatedValueByLineItemId = id => {
    return (calculatedValues || []).find(cv => cv.line_item_id === id);
  };

  const setCalculatedValueByLineItemId = (id, value: CalculatedValueType) => {
    const index = (calculatedValues || []).findIndex(
      cv => cv.line_item_id === id,
    );
    const newCalculatedValues = [...calculatedValues];
    if (index > -1) {
      newCalculatedValues[index] = value;
    } else {
      newCalculatedValues.push(value);
    }
    console.log(newCalculatedValues);
    setCalculatedValues(newCalculatedValues);
  };

  const makeCalculatedValue = (
    lineItem: LineItem,
    values?: {
      newCustomsDutyPaid: number | string;
      newVatPaid: number | string;
    },
  ) => {
    const proposed_values = get(lineItem, 'metadata.proposed_values', {
      duty_paid: lineItem.item_customs_duty_paid,
      vat_paid: lineItem.item_vat_paid,
      message: '',
    });

    if (!values) {
      // Set initial values by checking vat payable, duty payable and setting to 0 if N
      const initialValues = {
        vat_paid: lineItem.item_vat_paid,
        duty_paid: lineItem.item_customs_duty_paid,
      };

      return {
        line_item_id: lineItem.id,
        item_vat_paid: proposed_values.vat_paid,
        item_customs_duty_paid: proposed_values.duty_paid,
        message: proposed_values.message,
      } as CalculatedValueType;
    } else {
      return {
        line_item_id: lineItem.id,
        item_vat_paid: values.newVatPaid,
        item_customs_duty_paid: values.newCustomsDutyPaid,
        message: proposed_values.message,
      } as CalculatedValueType;
    }
  };

  const allCalculatedValuesSet = () => {
    return calculatedValues.reduce((acc: boolean, cv) => {
      return acc && cv.item_customs_duty_paid !== '' && cv.item_vat_paid !== '';
    }, true);
  };

  React.useEffect(() => {
    if (props.adjustedItems) {
      setCalculatedValues(
        props.adjustedItems.adjusted_items.map(ai => makeCalculatedValue(ai)),
      );
    }
  }, [props.adjustedItems]);

  React.useEffect(() => {
    if (!props.adjustedItemsLoading) {
      if (!props.adjustedItems) setStep(1);
      else if (step === 1) setStep(2);
    }
  }, [props.adjustedItemsLoading]);

  if (props.adjustedItemsLoading || props.confirmAdjustmentLoading) {
    return <LoadingIndicator minHeight={150} />;
  }

  if (step === 2) {
    const cpc = get(
      props.adjustedItems,
      'adjusted_items[0].customs_procedure_code',
      '',
    );
    const cpcName = get(
      props.adjustedItems,
      'adjusted_items[0].metadata.field_info.customs_procedure_code',
      '',
    );
    const cpcData = get(
      props.adjustedItems,
      'adjusted_items[0].metadata.cpc_data',
      {},
    );
    return (
      <>
        <Box sx={{ mb: 2 }}>
          {!!cpcName ? (
            <>
              <Typography variant={'h6'}>New code: {cpc}</Typography>
              <Typography variant={'subtitle1'}>{cpcName}</Typography>
              <Typography variant={'subtitle2'}>
                CPC data indicates this line item is{' '}
                {cpcData.duty_payable === 'Y'
                  ? ' subject to customs duty.'
                  : ' not subject to customs duty.'}
              </Typography>
              <Typography variant={'subtitle2'}>
                CPC data indicates this line item is{' '}
                {cpcData.vat_payable === 'Y'
                  ? ' subject to VAT.'
                  : ' not subject to VAT.'}
              </Typography>
            </>
          ) : (
            <Typography>No info is currently available for this CPC</Typography>
          )}
        </Box>

        <Box>
          {props.adjustedItems?.adjusted_items.map(item => {
            let cv =
              getCalculatedValueByLineItemId(item.id) ||
              makeCalculatedValue(item);
            return (
              <Card elevation={0} variant={'outlined'} sx={{ mt: 1 }}>
                <CardHeader
                  disableTypography
                  title={
                    <Typography variant={'body1'}>
                      Line ref: {item.line_code}
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs sm={6}>
                      <TextField
                        label="Customs duty paid"
                        helperText={`Original value: ${formatCurrency(
                          item.item_customs_duty_paid,
                        )}`}
                        {...formatCurrencyTextField(cv.item_customs_duty_paid)}
                        onChange={ev => {
                          if (
                            ev.target.value !== '' &&
                            cv.item_customs_duty_paid !== ''
                          ) {
                            console.log(cv.item_customs_duty_paid);
                            setCalculatedValueByLineItemId(
                              item.id,
                              makeCalculatedValue(item, {
                                newCustomsDutyPaid: +ev.target.value,
                                newVatPaid: cv.item_vat_paid,
                              }),
                            );
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm={6}>
                      <TextField
                        label="VAT paid"
                        helperText={`Original value: ${formatCurrency(
                          item.item_vat_paid,
                        )}`}
                        {...formatCurrencyTextField(cv.item_vat_paid)}
                        onChange={ev => {
                          if (
                            ev.target.value !== '' &&
                            cv.item_vat_paid !== ''
                          ) {
                            setCalculatedValueByLineItemId(
                              item.id,
                              makeCalculatedValue(item, {
                                newCustomsDutyPaid: cv.item_customs_duty_paid,
                                newVatPaid: +ev.target.value,
                              }),
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {!!cv.message && (
                    <Typography
                      sx={{ p: 1 }}
                      variant={'subtitle2'}
                      color={'error'}
                    >
                      {cv.message}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Box>
        <DialogActions>
          <Button
            sx={{ marginRight: 'auto' }}
            variant="text"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
          <Button
            disabled={!allCalculatedValuesSet()}
            onClick={() => props.confirmAdjustment(calculatedValues)}
          >
            Confirm adjustment
          </Button>
        </DialogActions>
      </>
    );
  }

  // Show first item
  return (
    <>
      <DialogContentText sx={{ mb: 3 }}>
        Set the new values for
        {props.adjustmentIntent.count
          ? ` ${props.adjustmentIntent.count} items`
          : ` the line item`}
      </DialogContentText>
      <Box>
        <Grid container spacing={2}>
          {Object.keys(fields).map(k => (
            <Grid item xs sm={6}>
              <TextField
                fullWidth
                value={values[k]}
                label={fields[k].label}
                name={k}
                onChange={ev =>
                  setValues({
                    ...values,
                    [k]: ev.target.value,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <DialogActions>
        <Button onClick={() => props.getAdjustedItems(values)}>Proceed</Button>
      </DialogActions>
    </>
  );
}
