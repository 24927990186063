/**
 *
 * AdjustmentReports
 *
 */
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { AdjustmentReport } from './AdjustmentReport';
import { AdjustmentReports as AR } from './AdjustmentReports';
import { Route, Switch } from 'react-router-dom';
import { AdjustmentReportStatus } from './slice/types';

interface Props {
  status?: AdjustmentReportStatus;
}

export function AdjustmentReports(props: Props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:id`}>
        <AdjustmentReport />
      </Route>
      <Route>
        <AR {...props} />
      </Route>
    </Switch>
  );
}
