/**
 *
 * LineAdjustmentForm
 *
 */
import * as React from 'react';
import { AdjustedItems, AdjustmentIntent, ChangeAction } from '../slice/types';
import { Box, FormControl, Input } from '@mui/material';
import { Case, Switch } from '../../../components/Switch';
import { MeasureAdjustmentForm } from './MeasureAdjustmentForm';
import { CpcAdjustmentForm } from './CpcAdjustmentForm';
import { CurrencyAdjustmentForm } from './CurrencyAdjustmentForm';
import { ItemPriceAdjustmentForm } from './ItemPriceAdjustmentForm';

export interface LineAdjustmentFormProps {
  [key: string]: any;
  adjustmentIntent: AdjustmentIntent;
  adjustedItems?: AdjustedItems;
  getAdjustedItems: Function;
  adjustedItemsLoading: Boolean;
  confirmAdjustment: Function;
  confirmAdjustmentLoading: Boolean;
}

export function LineAdjustmentForm(props: LineAdjustmentFormProps) {
  const change: ChangeAction = props.adjustmentIntent.change;

  let form: any = null;
  switch (change) {
    case 'measure':
      form = <MeasureAdjustmentForm {...props} />;
      break;
    case 'currency':
      form = <CurrencyAdjustmentForm {...props} />;
      break;
    case 'price':
      form = <ItemPriceAdjustmentForm {...props} />;
      break;
    case 'cpc':
      form = <CpcAdjustmentForm {...props} />;
      break;
  }

  return (
    <div>
      <Box>{form}</Box>
    </div>
  );
}
