/**
 *
 * Users
 *
 */
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { UserForm } from './UserForm';

interface Props {}

export function Users(props: Props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={List} />
      <Route exact path={`${match.path}/:id`} component={UserForm} />
    </Switch>
  );
}
