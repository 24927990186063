/**
 *
 * PusherProvider
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import PusherJS from 'pusher-js';
import Pusher, { setPusherClient } from 'react-pusher';
import { selectUserData } from 'app/containers/AuthProvider/slice/selectors';
import { getBroadcastingUrl, getToken } from 'utils/request';
import { useSnackbar } from 'notistack';
import { usePusherProviderSlice } from './slice';
import { selectPusherProvider } from './slice/selectors';
import { importDataActions } from 'app/containers/ImportData/slice';

interface Props {
  children: React.ReactChild;
}

export function PusherProvider(props: Props) {
  const userData = useSelector(selectUserData);
  const pusherProvider = useSelector(selectPusherProvider);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { actions } = usePusherProviderSlice();

  React.useEffect(() => {
    // todo
  }, []);

  const id = get(userData, 'id', null);

  React.useEffect(() => {
    if (!userData) {
      dispatch(actions.setPusherConnected(false));
      return;
    }

    if (userData.pusher) {
      PusherJS.logToConsole = true;

      const pusherClient = new PusherJS(userData.pusher.app_key, {
        cluster: userData.pusher.cluster,
        forceTLS: userData.pusher.tls,
        authEndpoint: getBroadcastingUrl('auth'),
        auth: {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + getToken(),
          },
        },
      });
      setPusherClient(pusherClient);
      dispatch(actions.setPusherConnected(true));
    }
  }, [id]);

  return (
    <>
      <PusherUserSubscriber
        event="message"
        onUpdate={data => {
          console.log(data);
          enqueueSnackbar(data.message.text, {
            variant: 'default',
            ...(data.message.options || {}),
          });
        }}
      />
      <PusherUserSubscriber
        event="action-import"
        onUpdate={data => {
          switch (data.action.type) {
            case 'checkStatus':
              dispatch(importDataActions.importStatusRequest());
              break;
          }
        }}
      />
      <>{React.Children.only(props.children)}</>
    </>
  );
}

export function PusherUserSubscriber(props: {
  event: string;
  onUpdate: Function;
  [key: string]: any;
}) {
  const userData = useSelector(selectUserData);
  const pusherProvider = useSelector(selectPusherProvider);

  return (
    <>
      {userData && pusherProvider.connected && (
        <Pusher
          channel={`private-App.User.${userData.id}`}
          event={props.event}
          onUpdate={props.onUpdate}
        />
      )}
    </>
  );
}
