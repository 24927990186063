import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.customersSlice || initialState;

export const selectCustomersSlice = createSelector(
  [selectSlice],
  state => state,
);
export const selectCustomers = createSelector(
  [selectSlice],
  state => state.customers,
);
export const selectCustomer = createSelector(
  [selectSlice],
  state => state.customer,
);

export const selectSaveCustomer = createSelector(
  [selectSlice],
  state => state.save,
);

export const selectDeleteCustomer = createSelector(
  [selectSlice],
  state => state.delete,
);
