/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { green, grey, red } from '@mui/material/colors';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, {
  Chart,
  SeriesBarOptions,
  SeriesEventsOptionsObject,
  XAxisLabelsOptions,
} from 'highcharts';
import { useLocation } from 'react-router';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from 'utils/formatCurrency';
import { isEqual } from 'lodash';
import { usePrevious } from 'utils/usePrevious';
import { FilterableReportTable } from '../../../../components/FilterableReportTable';
import { useDebounce } from '../../../../../utils/useDebounce';

interface Props extends ReportGraphicProps {}

interface CustomXAxisLabelsOptions extends XAxisLabelsOptions {
  events?: any;
}

//CustomEvents(Highcharts);

export const CommCodeAnalysis = React.memo(function CommCodeAnalysis(
  props: Props,
) {
  const { data, reportSettings } = props.graphic.data!;
  let chart;

  const [ready, setReady] = React.useState<boolean>(false);

  const [options, setOptions] = React.useState<
    Highcharts.Options | undefined
  >();

  const [viewMode, setViewMode] = React.useState<keyof typeof viewModes>(
    reportSettings.viewMode || 'top5',
  );

  const [metric, setMetric] = React.useState<keyof typeof metricOptions>(
    reportSettings.metric || 'frequency',
  );

  const [selectedCommCodes, setSelectedCommCodes] = React.useState<string[]>(
    reportSettings.selectedCommCodes || null,
  );

  const debouncedSelectedCommCodes = useDebounce(
    selectedCommCodes,
    props.full ? 200 : 50,
  );

  const { search } = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 0);
  }, []);

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }

    if (!isEqual(reportSettings.selectedCommCodes, selectedCommCodes)) {
      setSelectedCommCodes(reportSettings.selectedCommCodes);
    }
  }, [props.graphic.data]);

  React.useEffect(() => {
    if (!props.graphic.loading) setOptions(getOptions());
  }, [props.graphic.loading]);

  React.useEffect(() => {
    if (viewMode && metric) setOptions(getOptions());
  }, [viewMode, metric, debouncedSelectedCommCodes]);

  React.useEffect(() => {
    if (props.graphic.data) props.onSettingsChange({ viewMode, metric });
  }, [viewMode, metric]);

  const prevSelectedCommCodes = usePrevious(selectedCommCodes);

  React.useEffect(() => {
    if (
      isEqual(selectedCommCodes, prevSelectedCommCodes) ||
      !prevSelectedCommCodes
    )
      return;
    if (props.full && selectedCommCodes) {
      props.onSettingsChange({ metric, viewMode, selectedCommCodes }, true);
    }
  }, [selectedCommCodes, prevSelectedCommCodes]);

  React.useEffect(() => {
    if (!props.full) {
      if (selectedCommCodes.length) {
        props.loadFullReport({ metric, viewMode, selectedCommCodes });
      }
    }
  }, [debouncedSelectedCommCodes]);

  if (!data) {
    return null;
  }

  const formatCurrency = variableFormatCurrency(100000000);

  const metricOptions = {
    customsValue: {
      label: 'Customs Value',
      formatter: value => formatCurrency(value),
    },
    customsDutyPaid: {
      label: 'Customs Duty Paid',
      formatter: formatCurrency,
    },
    frequency: {
      label: 'Frequency',
      formatter: value => value,
    },
  };

  const viewModes = {
    top5: {
      label: 'Top 5',
      colors: [
        green['800'],
        green['600'],
        green['400'],
        green['200'],
        green['100'],
      ],
    },
    bottom5: {
      label: 'Bottom 5',
      colors: [red['800'], red['600'], red['400'], red['200'], red['100']],
    },
  };

  const xAxisClick = code => {
    const scc = `${code}`;
    setSelectedCommCodes([scc]);
  };

  const seriesEvents: SeriesEventsOptionsObject = {
    click: function (ev) {
      xAxisClick(ev.point.name);
    },
  };

  const afterChartCreated = (highcharts: Chart) => {
    if (!chart) chart = highcharts;
  };

  const getOptions = (): Highcharts.Options => ({
    chart: {
      events: {},
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      labels: {
        useHTML: true,
        formatter: function () {
          return `<b>${this.value}</b>`;
        },

        events: {
          click: function (ev) {
            const object = data.values[viewMode][metric][this.pos];
            xAxisClick(object.commodity_code);
          },
        },
      } as CustomXAxisLabelsOptions,
    },
    yAxis: [
      {
        showEmpty: false,
        labels: {
          formatter: function () {
            return metricOptions[metric].formatter(this.value);
          },
        },
        title: { text: metricOptions[metric].label },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      outside: true,
      padding: 16,
    },
    series: [
      {
        name: `${metricOptions[metric].label} - ${viewModes[viewMode].label}`,
        events: {
          ...seriesEvents,
        },
        tooltip: {
          headerFormat: '',
          pointFormatter: function () {
            return `<b>${this.series.name}: ${metricOptions[metric].formatter(
              this.y,
            )}</b><br><br><b>${this.options.name}: </b>${
              this.options.description
            }`;
          },
        },
        dataLabels: [
          {
            enabled: true,
            inside: true,
            formatter: function () {
              return metricOptions[metric].formatter(this.y);
            },
            style: {
              fontSize: '16px',
            },
          },
        ],
        type: 'bar',
        data: data.values[viewMode][metric].map((v, i) => {
          return {
            name: v.commodity_code,
            description: v.description,
            y: v.value,
            color: selectedCommCodes.length
              ? v.commodity_code === selectedCommCodes[0]
                ? viewModes[viewMode].colors[i]
                : grey.A200
              : viewModes[viewMode].colors[i],
          };
        }),
      } as SeriesBarOptions,
    ],
  });

  if (props.full) {
    const sortDir = viewMode === 'top5' ? 'desc' : 'asc';
    return (
      <Box sx={{ px: 3, py: 3, width: '100%' }}>
        <FilterableReportTable
          selectedItems={selectedCommCodes}
          onSelectionChange={selection => {
            setSelectedCommCodes(selection as Array<string>);
          }}
          formatCurrency={formatCurrency}
          identifier={'commodity_code'}
          columns={[
            {
              label: 'Code',
              field: 'commodity_code',
            },
            {
              label: 'Customs Value',
              field: 'customs_value_agg',
              format: 'currency',
              sort: metric === 'customsValue' ? sortDir : undefined,
            },
            {
              label: 'Duty Paid',
              field: 'duty_paid_agg',
              format: 'currency',
              sort: metric === 'customsDutyPaid' ? sortDir : undefined,
            },
            {
              label: 'Frequency',
              field: 'frequency',
              format: 'number',
              sort: metric === 'frequency' ? sortDir : undefined,
            },
            {
              label: 'Min Duty Rate',
              field: 'duty_rate_min',
              format: 'percentage',
            },
            {
              label: 'Max Duty Rate',
              field: 'duty_rate_max',
              format: 'percentage',
            },
            {
              label: 'Duty Variance',
              field: 'duty_variance',
              format: 'percentage',
            },
            {
              label: 'Effective Duty Rate',
              field: 'effective_duty_rate',
              format: 'percentage',
            },
            {
              label: 'Description',
              field: 'description',
              filterable: true,
              wide: true,
            },
          ]}
          data={data.values.all_codes || []}
        />
      </Box>
    );
  }
  return (
    <Box sx={{ px: 3, py: 3, width: '100%' }}>
      <Box>
        <Grid
          container
          sx={{
            mb: 2,
            justifyContent: {
              xs: 'flex-start',
              lg: 'flex-end',
            },
          }}
          alignItems={'center'}
        >
          <Grid item xs={12} md={12} lg={'auto'}>
            <Typography component={'div'} variant={'subtitle2'}>
              Filter by
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={'auto'}>
            <Box
              sx={{
                ml: { xs: 0, md: 2 },
                p: 2,
                backgroundColor: 'grey.A100',
              }}
            >
              <FormControl>
                <RadioGroup row>
                  {Object.keys(viewModes).map(vmk => (
                    <FormControlLabel
                      key={`view-mode-options-${vmk}`}
                      componentsProps={{
                        typography: {
                          variant: 'body2',
                        },
                      }}
                      control={
                        <Radio
                          color="primary"
                          checked={viewMode === vmk}
                          onClick={() =>
                            setViewMode(vmk as keyof typeof viewModes)
                          }
                        />
                      }
                      label={`${viewModes[vmk].label}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={'auto'}>
            <Box
              sx={{
                ml: { xs: 0, md: 0.1 },
                p: 2,
                backgroundColor: 'grey.A100',
              }}
            >
              <FormControl>
                <RadioGroup row>
                  {Object.keys(metricOptions).map(mk => (
                    <FormControlLabel
                      key={`metric-options-${mk}`}
                      componentsProps={{
                        typography: {
                          variant: 'body2',
                        },
                      }}
                      control={
                        <Radio
                          color="primary"
                          checked={metric === mk}
                          onClick={() =>
                            setMetric(mk as keyof typeof metricOptions)
                          }
                        />
                      }
                      label={`${metricOptions[mk].label}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!!options && (
            <HighchartsReact
              key={'commCodeAnalysisChart'}
              highcharts={Highcharts}
              options={options}
              callback={afterChartCreated}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
});
