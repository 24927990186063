/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * ManagementTable
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import { v4 } from 'uuid';
import clsx from 'clsx';
import {
  Box,
  Breakpoint,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { usePrevious } from '../../../utils/usePrevious';
import { LoadingIndicator } from '../LoadingIndicator';
import { ErrorBoundary } from 'react-error-boundary';

const useStyles = makeStyles((theme: Theme) => ({}));

export interface TableColumn {
  value: string | Function;
  label?: string | React.ReactNode;
  classes?: string;
}

interface Props {
  rows: Array<{ id: string | number; [key: string]: any }>;
  columns: Array<TableColumn>;
  onClick: Function;
  page: number;
  onChangePage: Function;
  rowsPerPage: number;
  rowsPerPageOptions: Array<number>;
  count: number;
  loading: boolean;
  loadingComponent: any;
  rowClasses?: any;
  noDataMessage?: any;
  enableCheckboxSelection?: boolean;
  disableMultipleSelection?: boolean;
  enableSelectAll?: boolean;
  defaultRowsSelected?: Array<number | string>;
  onSelectionChange?: (ids: Array<string | number>) => void;
  disableHeader?: boolean;
  breakpoint?: Breakpoint;
}

export function ManagementTable(props: Props) {
  const {
    rows,
    onClick,
    page,
    onChangePage,
    rowsPerPage,
    rowsPerPageOptions,
    count,
    loading,
    loadingComponent,
    breakpoint = 'md',
    noDataMessage,
  } = props;

  const rowClasses = props.rowClasses || {};

  const [selectedRows, setSelectedRows] = React.useState<
    Array<number | string>
  >(props.defaultRowsSelected || []);

  const classes = useStyles();

  const toggleRowSelection = id => {
    const index = selectedRows.indexOf(id);
    if (index === -1) {
      if (!props.disableMultipleSelection || selectedRows.length === 0) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows([id]);
      }
    } else {
      setSelectedRows(
        selectedRows.slice(0, index).concat(selectedRows.slice(index + 1)),
      );
    }
  };

  const oldSelectedRows = usePrevious(selectedRows);
  React.useEffect(() => {
    if (props.onSelectionChange) props.onSelectionChange(selectedRows);
  }, [selectedRows]);

  React.useEffect(() => {
    if (!isEqual(props.defaultRowsSelected, selectedRows)) {
      setSelectedRows(props.defaultRowsSelected || []);
    }
  }, [props.defaultRowsSelected]);

  let checkboxColumn: Array<TableColumn> = [];

  if (props.enableCheckboxSelection) {
    checkboxColumn.push({
      classes: 'narrow-cell',
      value: row => (
        <IconButton
          onClick={() => toggleRowSelection(row.id)}
          size="large"
          sx={{
            opacity: row.id ? 1 : 0,
            pointerEvents: row.id ? 'auto' : 'none',
          }}
        >
          {selectedRows.indexOf(row.id) !== -1 ? (
            props.disableMultipleSelection ? (
              <RadioButtonChecked />
            ) : (
              <CheckBox />
            )
          ) : props.disableMultipleSelection ? (
            <RadioButtonUnchecked />
          ) : (
            <CheckBoxOutlineBlank />
          )}
        </IconButton>
      ),
    });
  }
  const columns = [...checkboxColumn, ...props.columns];
  const theme = useTheme();

  return (
    <TableContainer style={{ height: '100%' }}>
      {loading ? (
        loadingComponent ? (
          loadingComponent
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingIndicator minHeight={300} />
          </Box>
        )
      ) : (
        <React.Fragment>
          <Table
            sx={{
              width: '100%',
              [theme.breakpoints.down(breakpoint)]: {
                display: 'block',
                width: '100%',
              },
            }}
            stickyHeader
          >
            {!props.disableHeader && (
              <TableHead
                sx={{
                  '& .align-right': {
                    textAlign: 'right',
                  },
                  [theme.breakpoints.down(breakpoint)]: {
                    display: 'none',
                  },
                }}
              >
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={v4()}
                      sx={{
                        padding: theme.spacing(2),
                        color: theme.palette.grey['600'],
                        fontWeight: 'bold',
                      }}
                      className={clsx(column.classes)}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody
              sx={{
                [theme.breakpoints.down(breakpoint)]: {
                  display: 'block',
                  width: '100% !important',
                },
              }}
            >
              {rows.map(row => (
                <TableRow
                  key={v4()}
                  onClick={() => onClick(row)}
                  sx={{
                    [theme.breakpoints.up(breakpoint)]: {
                      '& .align-right': {
                        textAlign: 'right',
                      },
                      '& .no-wrap': {
                        whiteSpace: 'nowrap',
                      },
                      '& .narrow-cell': {
                        width: '1px',
                        whiteSpace: 'nowrap',
                        paddingLeft: theme.spacing(0.5),
                        paddingRight: theme.spacing(0.5),
                      },

                      '&.draft': {
                        backgroundColor: theme.palette.grey[100],
                      },
                      '&.bold > *': {
                        fontWeight: 'bold',
                      },
                    },
                    [theme.breakpoints.down(breakpoint)]: {
                      display: 'block',
                      width: '100% !important',
                      height: 'auto',
                      padding: `8px 0`,
                      borderBottom: `1px solid ${theme.palette.grey.A100}`,
                    },
                  }}
                  className={clsx(
                    row.classes,
                    ...Object.keys(rowClasses).map(keyClass => ({
                      [keyClass]: rowClasses[keyClass](row),
                    })),
                  )}
                  hover
                >
                  {columns.map(column => (
                    <TableCell
                      key={v4()}
                      sx={{
                        padding: theme.spacing(2),
                        paddingTop: theme.spacing(0.5),
                        paddingBottom: theme.spacing(0.5),
                        [theme.breakpoints.down(breakpoint)]: {
                          display: 'block',
                          width: '100%',
                          border: 'none',
                        },
                      }}
                      className={clsx(column.classes)}
                    >
                      {!!column.label && (
                        <Box
                          component={'span'}
                          sx={{
                            [theme.breakpoints.up(breakpoint)]: {
                              display: 'none',
                            },
                          }}
                        >
                          <b>{column.label}:</b>{' '}
                        </Box>
                      )}

                      {typeof column.value === 'function' ? (
                        <ErrorBoundary
                          fallback={<>&nbsp;</>}
                          onError={e => {
                            //  console.log(e);
                          }}
                        >
                          {column.value(row)}&nbsp;
                        </ErrorBoundary>
                      ) : (
                        get(row, column.value, '')
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {!rows.length && (
                <TableRow>
                  <TableCell colSpan={1000}>{noDataMessage}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {count > rowsPerPage &&
            {
              /*
                        <TablePagination
              
                          component="div"
                          page={page}
                          onChangePage={onChangePage}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={rowsPerPageOptions}
                          count={count}
                        />
                         */
            }}
        </React.Fragment>
      )}
    </TableContainer>
  );
}
