import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { manageSuppliersSaga } from './saga';
import { ISupplier, ISupplierAction, ManageSuppliersState } from './types';
import { apiCallInitialState } from '../../../../types/ApiCall';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: ManageSuppliersState = {
  suppliers: { ...apiCallInitialState, data: [] },
  actions: apiCallInitialState,
};

const slice = createSlice({
  name: 'manageSuppliers',
  initialState,
  reducers: {
    loadSuppliersRequest(state, action: PayloadAction<boolean | undefined>) {
      if (action.payload) {
        console.log('true');
        state.suppliers.data = [];
      }
      state.suppliers.loading = true;
      state.suppliers.error = undefined;
    },
    loadSuppliersSuccess(state, action: PayloadAction<Array<ISupplier>>) {
      state.suppliers.data = action.payload;
      state.suppliers.loading = false;
    },
    loadSuppliersFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.suppliers.error = action.payload;
      state.suppliers.loading = false;
    },
    supplierActionsRequest(
      state,
      action: PayloadAction<Array<ISupplierAction>>,
    ) {
      state.actions.loading = true;
      state.actions.error = undefined;
    },
    supplierActionsSuccess(state, action: PayloadAction<void>) {
      state.actions.loading = false;
    },
    supplierActionsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.actions.error = action.payload;
      state.actions.loading = false;
    },
  },
});

export const { actions: manageSuppliersActions } = slice;

export const useManageSuppliersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: manageSuppliersSaga });
  return { actions: slice.actions };
};
