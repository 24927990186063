/**
 *
 * CustomerForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Skeleton,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-mui';

import { formStyles } from 'styles/theme/themes';
import { PasswordField } from 'app/components/PasswordField';
import cn from 'classnames';
import { useRouteMatch } from 'react-router';
import { useCustomersSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSaveCustomer,
  selectCustomer,
  selectDeleteCustomer,
} from './slice/selectors';
import Yup from 'utils/yup';
import { MaterialUiFormikSelect } from '../../components/MaterialUiFormikSelect';
import { useSnackbar } from 'notistack';
import { red } from '@mui/material/colors';
import { useConfirm } from '../../components/ConfirmDialog';
import { ChevronLeft } from '@mui/icons-material';

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  contact_name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
  description: Yup.string(),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function CustomerForm() {
  interface MatchParams {
    id: string;
  }

  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const confirm = useConfirm();

  const { actions } = useCustomersSlice();
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  const saveCustomer = useSelector(selectSaveCustomer);
  const deleteCustomerData = useSelector(selectDeleteCustomer);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (match.params.id !== 'new') {
      loadCustomer(parseInt(match.params.id));
    }
    return () => {
      dispatch(actions.loadCustomerUnmount());
    };
  }, [dispatch, match.params.id]);

  React.useEffect(() => {
    if (customer.loading) return;

    if (customer.error) {
      history.push({
        pathname: match.path.replace(':id', ''),
      });
    }
  }, [customer.loading]);

  React.useEffect(() => {
    if (saveCustomer.loading) return;
    if (saveCustomer.error) {
      enqueueSnackbar(saveCustomer.error.message, {
        variant: 'error',
      });
    }
    if (saveCustomer.data) {
      enqueueSnackbar('Customer saved successfully', {
        variant: 'success',
      });
      if (match.params.id === 'new') {
        history.push({
          pathname: match.path.replace(':id', ''),
        });
      }
    }
  }, [saveCustomer.loading]);

  React.useEffect(() => {
    if (deleteCustomerData.loading) return;
    if (deleteCustomerData.error) {
      enqueueSnackbar(deleteCustomerData.error.message, {
        variant: 'error',
      });
    }
    if (deleteCustomerData.data) {
      enqueueSnackbar('Customer deleted', {
        variant: 'success',
      });
      history.push({
        pathname: match.path.replace(':id', ''),
      });
    }
  }, [deleteCustomerData.loading]);

  const loadCustomer = (id: number) => {
    dispatch(actions.loadCustomerRequest({ id }));
  };

  const deleteCustomer = (id: number) => {
    dispatch(actions.deleteCustomerRequest({ id }));
  };

  const { loading } = customer;
  const data = {
    id: null,
    name: '',
    contact_name: '',
    code: '',
    description: '',
    account_enabled: false,

    ...(customer.data || {}),
  };

  const classes = useStyles();
  const formClasses = formStyles();

  if (customer.loading && !customer.data)
    return (
      <Box sx={{ my: 4, height: 400 }}>
        <Skeleton height={400} variant={'rectangular'} />
      </Box>
    );
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={'h1'} color={'primary'}>
          {customer.data ? `Editing customer` : 'Add new customer'}
        </Typography>
        <Button
          variant="text"
          startIcon={<ChevronLeft />}
          sx={{ marginLeft: 'auto' }}
          component={Link}
          to="/portal/accounts/customers"
        >
          Back to customers
        </Button>
      </Box>

      <Paper sx={{ my: 4, p: 2 }}>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(actions.saveCustomerRequest(values));
          }}
        >
          {({ errors, values, submitForm, isSubmitting, setSubmitting }) => {
            if (isSubmitting && !(customer.loading || saveCustomer.loading)) {
              setSubmitting(false);
            }
            if (!isSubmitting && (customer.loading || saveCustomer.loading)) {
              setSubmitting(true);
            }

            return (
              <Form>
                <Box className={cn('has-actions')}>
                  <Box className={formClasses.formContent}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          type="text"
                          label="Name"
                          fullWidth
                          className={formClasses.field}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="code"
                          name="code"
                          type="text"
                          label="Code"
                          fullWidth
                          className={formClasses.field}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="contact_name"
                          name="contact_name"
                          type="text"
                          label="Contact Name"
                          fullWidth
                          className={formClasses.field}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          component={TextField}
                          id="description"
                          name="description"
                          type="textarea"
                          multiline
                          label="Description / Notes"
                          fullWidth
                          className={formClasses.field}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Field
                          name="account_enabled"
                          type="checkbox"
                          Label={{ label: 'Account enabled' }}
                          component={CheckboxWithLabel}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={formClasses.formActions} sx={{ mt: 4 }}>
                    <Button
                      component={Link}
                      variant="text"
                      size="small"
                      to="/portal/accounts/customers"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="align-right"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Save {values.id ? 'Changes' : 'New Customer'}
                    </Button>
                  </Box>
                  {!!values.id && (
                    <Box className={formClasses.formActions} sx={{ mt: 4 }}>
                      <Button
                        type="button"
                        variant="contained"
                        size="small"
                        color="primary"
                        sx={{ backgroundColor: red.A700 }}
                        onClick={() => {
                          confirm({
                            description:
                              'Deleting customer - this action is permanent. Are you sure?',
                          })
                            .then(() => deleteCustomer(values.id!))
                            .catch(null);
                        }}
                      >
                        Delete this customer
                      </Button>
                    </Box>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
}
