/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { Report, ReportApiCall, ReportGraphicApiCall } from '../slice/types';
import { ChevronRight } from '@mui/icons-material';
import { Overview } from './Overview';
import { CommCodeAnalysis } from './CommCodeAnalysis';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { WorldMap } from './WorldMap';
import { CPCAnalysis } from './CPCAnalysis';
import { PreferenceAnalysis } from './PreferenceAnalysis';
import { SupplierAnomalies } from './SupplierAnomalies';
import { grey } from '@mui/material/colors';
import { DeclarantAnalysis } from './DeclarantAnalysis';
import { SupplierAnalysis } from './SupplierAnalysis';
import { DutyUnderManagement } from './DutyUnderManagement';

interface Props {
  id: string;
  full: boolean;
  report: ReportApiCall;
  onSettingsChange: Function;
  loadTableData?: Function;
  loadFullReport: Function;
  reportSettings: { [key: string]: any };
}
export interface ReportGraphicProps extends Props {
  graphic: ReportGraphicApiCall;
}

export function ReportGraphic(props: Props) {
  const { search } = useLocation();

  const { id, report } = props;

  const theme = useTheme();

  const graphicProps: ReportGraphicProps = {
    ...props,
    graphic: report.graphic,
  };

  const getGraphic = () => {
    let graphic;
    let showFullReportButton = false;

    if (!report.graphic.data) {
      graphic = (
        <Box sx={{ flex: 1 }}>
          <Skeleton variant={'rectangular'} height={'100%'} />
        </Box>
      );
    } else {
      switch (id) {
        case 'duty-under-management':
          graphic = <DutyUnderManagement {...graphicProps} />;
          break;
        case 'overview':
          graphic = <Overview {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'commcode-analysis':
          graphic = <CommCodeAnalysis {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'cpc-analysis':
          graphic = <CPCAnalysis {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'declarant-analysis':
          graphic = <DeclarantAnalysis {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'supplier-analysis':
          graphic = <SupplierAnalysis {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'preference-analysis':
          graphic = <PreferenceAnalysis {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'world-map':
          graphic = <WorldMap {...graphicProps} />;
          showFullReportButton = true;
          break;
        case 'supplier-anomalies':
          graphic = <SupplierAnomalies {...graphicProps} />;
          showFullReportButton = true;
          break;
        /*
        
      */
        default:
          graphic = <Skeleton variant={'rectangular'} height={360} />;
      }
    }

    return { graphic, showFullReportButton };
  };

  const { graphic, showFullReportButton } = getGraphic();

  return (
    <Box
      sx={{
        width: '100%',
        pb: 4,
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ py: 3, px: 3, borderBottom: 1, borderColor: grey['300'] }}>
          <Typography variant="h4" sx={{}}>
            {!!report.graphic.data && !!report.graphic.data.title ? (
              report.graphic.data.title
            ) : (
              <Skeleton variant={'text'} />
            )}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', flex: 1, display: 'flex' }}>{graphic}</Box>

        {!props.full && showFullReportButton && (
          <Box
            sx={{
              px: 3,
              py: 3,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTopWidth: 1,
              borderTopColor: theme.palette.grey.A100,
              borderTopStyle: 'solid',
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              endIcon={<ChevronRight />}
              size="large"
              onClick={() => props.loadFullReport()}
            >
              View report data
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
