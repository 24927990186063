import * as React from 'react';
import { isEqual, get } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdjustedItems, AdjustmentIntent } from '../slice/types';
import { DutyMeasure, LineItem } from '../../ImportData/slice/types';
import {
  formatCurrency,
  formatCurrencyTextField,
} from '../../../../utils/formatCurrency';
import { LineAdjustmentFormProps } from './index';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { CommCodeFormatCustom } from '../../../components/InputMasks';

const fields = {
  commodity_code: {
    label: 'Commodity code',
  },
  preference: {
    label: 'Preference',
  },
  ecsu_1: {
    label: 'Ecsu 1',
  },
  ecsu_2: {
    label: 'Ecsu 2',
  },
};

type ValuesType = {
  [k in keyof typeof fields]: string;
};

interface Props extends LineAdjustmentFormProps {}

export function MeasureAdjustmentForm(props: Props) {
  const [step, setStep] = React.useState<number>(1);
  const defaultValues = props.adjustmentIntent.changeable_fields.reduce(
    (acc: any, curr) => {
      acc[curr.field] = curr.default_value;
      return acc;
    },
    {},
  );

  const [values, setValues] = React.useState<ValuesType>(defaultValues);

  const [measure, setMeasure] = React.useState<number>(0);

  type CalculatedValueType = {
    line_item_id: number | string;
    item_customs_duty_paid: number | string;
    item_vat_value: number | string;
    vat_rate: number;
    item_vat_paid: number | string;
  };
  const [calculatedValues, setCalculatedValues] = React.useState<
    CalculatedValueType[]
  >([]);

  const getCalculatedValueByLineItemId = id => {
    return (calculatedValues || []).find(cv => cv.line_item_id === id);
  };

  const setCalculatedValueByLineItemId = (id, value: CalculatedValueType) => {
    const index = (calculatedValues || []).findIndex(
      cv => cv.line_item_id === id,
    );
    const newCalculatedValues = [...calculatedValues];
    if (index > -1) {
      newCalculatedValues[index] = value;
    } else {
      newCalculatedValues.push(value);
    }
    setCalculatedValues(newCalculatedValues);
  };

  const makeCalculatedValue = (
    lineItem: LineItem,
    newCustomsDutyPaid: number | false,
    vat_rate: number,
  ) => {
    if (newCustomsDutyPaid === false) {
      return {
        line_item_id: lineItem.id,
        item_vat_value: lineItem.item_vat_value,
        item_vat_paid: lineItem.item_vat_paid || 0,
        item_customs_duty_paid: '',
        vat_rate: vat_rate,
      } as CalculatedValueType;
    } else {
      const additional_duty =
        newCustomsDutyPaid - (lineItem.item_customs_duty_paid || 0);

      return {
        line_item_id: lineItem.id,
        item_vat_value: +lineItem.item_vat_value + additional_duty,
        item_vat_paid:
          +(lineItem.item_vat_paid || 0) +
          +(additional_duty * (vat_rate / 100)),
        item_customs_duty_paid: newCustomsDutyPaid,
        vat_rate: vat_rate,
      } as CalculatedValueType;
    }
  };

  const allCalculatedValuesSet = () => {
    return calculatedValues.reduce((acc: boolean, cv) => {
      return acc && cv.item_customs_duty_paid !== '';
    }, true);
  };

  React.useEffect(() => {
    if (props.adjustedItems) {
      setCalculatedValues(
        props.adjustedItems.adjusted_items.map(ai => {
          return makeCalculatedValue(
            ai,
            get(ai, `metadata.duty_measures[${measure}].duty_value`, false),
            ai.vat_rate,
          );
        }),
      );
    }
  }, [measure, props.adjustedItems]);

  React.useEffect(() => {
    if (!props.adjustedItemsLoading) {
      if (!props.adjustedItems) setStep(1);
      else if (step === 1) setStep(2);
    }
  }, [props.adjustedItemsLoading]);

  if (props.adjustedItemsLoading || props.confirmAdjustmentLoading) {
    return <LoadingIndicator minHeight={150} />;
  }

  if (step === 2) {
    const item = props.adjustedItems?.adjusted_items[0];
    if (!item) return null;
    return (
      <>
        <DialogContentText sx={{ mb: 3 }}>
          Set the customs duty measure to be applied to
          {props.adjustedItems!.adjusted_items.length > 1
            ? ` ${props.adjustedItems!.adjusted_items.length} items`
            : ` the line item`}
        </DialogContentText>
        <Box>
          {get(item, 'metadata.duty_measures', []).length ? (
            <Box>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Measure</FormLabel>
                <RadioGroup
                  aria-label="measure"
                  name="measure"
                  value={measure}
                  onChange={ev => {
                    setMeasure(+ev.target.value);
                  }}
                >
                  {item.metadata?.duty_measures.map((measure, index) => (
                    <FormControlLabel
                      value={index}
                      control={<Radio />}
                      label={`${measure.description} (${measure.duty_expression})`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          ) : (
            <Box> No duty measure data available.</Box>
          )}
        </Box>
        <DialogActions>
          <Button
            sx={{ marginRight: 'auto' }}
            variant="text"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
          <Button onClick={() => setStep(3)}>
            Review/amend line adjustments
          </Button>
        </DialogActions>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <DialogContentText sx={{ mb: 3 }}>
          Review/amend new adjusted values for
          {props.adjustedItems!.adjusted_items.length > 1
            ? ` ${props.adjustedItems!.adjusted_items.length} items`
            : ` the line item`}
        </DialogContentText>
        {props.adjustedItems?.adjusted_items.map(item => {
          let cv =
            getCalculatedValueByLineItemId(item.id) ||
            makeCalculatedValue(item, false, item.vat_rate);
          return (
            <Card elevation={0} variant={'outlined'} sx={{ mt: 1 }}>
              <CardHeader
                disableTypography
                title={
                  <Typography variant={'body1'}>
                    Line ref: {item.line_code}
                  </Typography>
                }
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs sm={6}>
                    <TextField
                      label="Customs duty payable"
                      helperText={`Original value: ${formatCurrency(
                        item.item_customs_duty_paid!,
                        {},
                        item.base_currency,
                      )}`}
                      type={'number'}
                      inputProps={{ step: 0.01 }}
                      value={cv.item_customs_duty_paid}
                      onChange={ev => {
                        if (ev.target.value !== '') {
                          setCalculatedValueByLineItemId(
                            item.id,
                            makeCalculatedValue(
                              item,
                              +ev.target.value,
                              cv.vat_rate,
                            ),
                          );
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} />

                  <Grid item xs sm={4}>
                    <TextField
                      label="Calculated VAT rate (%)"
                      disabled
                      type="number"
                      value={cv.vat_rate}
                      onChange={ev => {
                        if (
                          ev.target.value !== '' &&
                          cv.item_customs_duty_paid !== ''
                        ) {
                          setCalculatedValueByLineItemId(
                            item.id,
                            makeCalculatedValue(
                              item,
                              +cv.item_customs_duty_paid,
                              +ev.target.value,
                            ),
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs sm={4}>
                    <TextField
                      label="VAT Value"
                      disabled
                      {...formatCurrencyTextField(cv.item_vat_value)}
                    />
                  </Grid>
                  <Grid item xs sm={4}>
                    <TextField
                      label="VAT Payable"
                      disabled
                      {...formatCurrencyTextField(cv.item_vat_paid)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        <DialogActions>
          <Button
            sx={{ marginRight: 'auto' }}
            variant="text"
            onClick={() => setStep(2)}
          >
            Back
          </Button>
          <Button
            onClick={() => props.confirmAdjustment(calculatedValues)}
            disabled={!allCalculatedValuesSet()}
          >
            Confirm adjustment
          </Button>
        </DialogActions>
      </>
    );
  }

  const errors: { [k in keyof typeof fields]?: string } = {};

  const pattern = new RegExp(/^[0-9]{10}$/);
  if (!pattern.test(values['commodity_code'])) {
    errors.commodity_code = 'Commodity code needs to be 10 digits.';
  }

  // Show first item
  return (
    <>
      <DialogContentText sx={{ mb: 3 }}>
        Set the new values for
        {props.adjustmentIntent.count
          ? ` ${props.adjustmentIntent.count} items`
          : ` the line item`}
      </DialogContentText>
      <Box>
        <Grid container spacing={2}>
          {Object.keys(fields).map(k => (
            <Grid item xs sm={6}>
              <TextField
                fullWidth
                value={values[k]}
                label={fields[k].label}
                name={k}
                InputProps={
                  k === 'commodity_code'
                    ? {
                        inputComponent: CommCodeFormatCustom as any,
                      }
                    : {}
                }
                error={!!errors[k]}
                onChange={ev =>
                  setValues({
                    ...values,
                    [k]: ev.target.value,
                  })
                }
                helperText={errors[k]}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <DialogActions>
        <Button
          disabled={!!Object.keys(errors).length}
          onClick={() => props.getAdjustedItems(values)}
        >
          Proceed
        </Button>
      </DialogActions>
    </>
  );
}
