/**
 *
 * AuthProvider
 *
 */

import React from 'react';
import { useDispatch, useSelector, createSelectorHook } from 'react-redux';
import { useAuthProviderSlice } from './slice';
import { selectAuthProvider, selectUserData } from './slice/selectors';
import { Box, CircularProgress } from '@mui/material';
import { UserPermission } from './slice/types';

interface Props {
  children: React.ReactChild;
}

export function AuthProvider(props: Props) {
  const { actions } = useAuthProviderSlice();
  const authProvider = useSelector(selectAuthProvider);
  const dispatch = useDispatch();

  // Try user load once on load
  React.useEffect(() => {
    dispatch(actions.loadUserDataRequest());
  }, [dispatch, actions]);

  if (!authProvider.initialised) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress color="primary" size={60} />
      </Box>
    );
  }

  return <>{React.Children.only(props.children)}</>;
}
