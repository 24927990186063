import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function formatParsedISO(date, fmt = 'dd/MM/yyyy') {
  let d = '';
  try {
    const utcDate = utcToZonedTime(parseISO(date as string), 'Etc/UTC');
    d = format(utcDate, fmt);
  } catch (e) {}
  return d;
}
