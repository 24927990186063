import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.loggingProvider || initialState;

export const selectLoggingProvider = createSelector(
  [selectSlice],
  state => state,
);

export const selectLoggingProviderLogUserActions = createSelector(
  [selectSlice],
  state => state.logUserActions,
);

export const selectLoggingProviderUserActionQueue = createSelector(
  [selectSlice],
  state => state.userActionQueue,
);
