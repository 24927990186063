import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { adjustmentReportsSaga } from './saga';
import {
  AdjustmentReport,
  AdjustmentReportsState,
  AdjustmentReportStatus,
  AdjustmentReportUpdatePayload,
} from './types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: AdjustmentReportsState = {
  loadAdjustmentReports: {
    loading: false,
    data: [],
  },
  loadAdjustmentReport: {
    loading: false,
    data: null,
  },
  exportAdjustmentReport: {
    loading: false,
    data: null,
  },
  updateAdjustmentReport: {
    loading: false,
    data: null,
  },
};

const slice = createSlice({
  name: 'adjustmentReports',
  initialState,
  reducers: {
    initialise(state, action: PayloadAction<void>) {
      state.loadAdjustmentReports = initialState.loadAdjustmentReports;
      state.loadAdjustmentReport = initialState.loadAdjustmentReport;
      state.updateAdjustmentReport = initialState.updateAdjustmentReport;
    },
    loadAdjustmentReportsRequest(
      state,
      action: PayloadAction<{ status: AdjustmentReportStatus }>,
    ) {
      state.loadAdjustmentReports.loading = true;
      state.loadAdjustmentReports.error = undefined;
    },
    loadAdjustmentReportsSuccess(
      state,
      action: PayloadAction<{ data: AdjustmentReport[] }>,
    ) {
      state.loadAdjustmentReports.loading = false;
      state.loadAdjustmentReports.data = action.payload.data;
      state.loadAdjustmentReports.error = undefined;
    },
    loadAdjustmentReportsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadAdjustmentReports.loading = false;
      state.loadAdjustmentReports.error = action.payload;
    },
    loadAdjustmentReportRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadAdjustmentReport.loading = true;
      state.loadAdjustmentReport.error = undefined;
    },
    loadAdjustmentReportSuccess(
      state,
      action: PayloadAction<{ data: AdjustmentReport }>,
    ) {
      state.loadAdjustmentReport.loading = false;
      state.loadAdjustmentReport.data = action.payload.data;
      state.loadAdjustmentReport.error = undefined;
    },
    loadAdjustmentReportFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadAdjustmentReport.loading = false;
      state.loadAdjustmentReport.error = action.payload;
    },
    exportAdjustmentReportRequest(
      state,
      action: PayloadAction<{ id: number }>,
    ) {
      state.exportAdjustmentReport.loading = true;
      state.loadAdjustmentReport.error = undefined;
    },
    exportAdjustmentReportSuccess(
      state,
      action: PayloadAction<{ data: AdjustmentReport }>,
    ) {
      state.exportAdjustmentReport.loading = false;
      state.exportAdjustmentReport.data = action.payload.data;
      state.exportAdjustmentReport.error = undefined;
    },
    exportAdjustmentReportFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.exportAdjustmentReport.loading = false;
      state.exportAdjustmentReport.error = action.payload;
    },
    updateAdjustmentReportRequest(
      state,
      action: PayloadAction<AdjustmentReportUpdatePayload>,
    ) {
      state.updateAdjustmentReport.loading = true;
      state.updateAdjustmentReport.error = undefined;
    },
    updateAdjustmentReportSuccess(state, action: PayloadAction<any>) {
      state.updateAdjustmentReport.loading = false;
      state.updateAdjustmentReport.data = action.payload;
      state.updateAdjustmentReport.error = undefined;
    },
    updateAdjustmentReportFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.updateAdjustmentReport.loading = false;
      state.updateAdjustmentReport.error = action.payload;
    },
  },
});

export const { actions: adjustmentReportsActions } = slice;

export const useAdjustmentReportsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: adjustmentReportsSaga });
  return { actions: slice.actions };
};
