import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.report || initialState;

export const selectReport = createSelector([selectSlice], state => state);
export const selectAllReports = createSelector(
  [selectSlice],
  state => state.reports,
);
export const selectExport = createSelector(
  [selectSlice],
  state => state.export,
);
