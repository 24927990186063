import {
  call,
  cancel,
  fork,
  setContext,
  getContext,
  cancelled,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { reportActions as actions } from '.';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';
import axios from 'axios';
import { selectAllReports, selectReport } from './selectors';

function* internalWorker(worker, getKey, ...args) {
  const tasks = yield getContext('takeLatestByTasks');
  const key = getKey(...args);
  const previousTask = tasks[key];
  if (previousTask) {
    yield cancel(previousTask);
  }

  tasks[key] = yield fork(worker, ...args);
}

function* takeLatestBy(patternOrChannel, worker, getKey, ...args) {
  const takeLatestByTasks = yield getContext('takeLatestByTasks');
  if (!takeLatestByTasks) yield setContext({ takeLatestByTasks: {} });
  yield takeEvery(patternOrChannel, internalWorker, worker, getKey, ...args);
}

const CancelToken = axios.CancelToken;

function* loadReport(action) {
  const source = CancelToken.source();
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `reports/show`,
      method: 'post',
      data: {
        ...action.payload,
        customers: currentCustomers,
      },
      cancelToken: source.token,
    });
    if (action.type === actions.reportRequest.type) {
      yield put(actions.reportSuccess({ id: action.payload.id, ...payload }));
    }
    yield put(actions.reportDataSuccess({ id: action.payload.id, ...payload }));
  } catch (error) {
    if (action.type === actions.reportRequest.type) {
      yield put(
        actions.reportFailed({ id: action.payload.id, error: error.payload }),
      );
    }
    yield put(
      actions.reportDataFailed({ id: action.payload.id, error: error.payload }),
    );
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled');
    }
  }
}

function* exportData(action) {
  const source = CancelToken.source();
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `reports/show`,
      method: 'post',
      data: {
        ...action.payload,
        type: 'export',
        customers: currentCustomers,
      },
      cancelToken: source.token,
    });

    yield put(actions.exportSuccess({ id: action.payload.id, ...payload }));
  } catch (error) {
    yield put(actions.exportFailed());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled');
    }
  }
}

export function* reportSaga() {
  yield takeLatest(actions.exportRequest, exportData);
  yield takeLatestBy(
    [actions.reportRequest.type, actions.reportDataRequest.type],
    loadReport,
    action => {
      return action.payload.id;
    },
  );
}
