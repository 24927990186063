/**
 *
 * Input Upload element
 *
 */
import * as React from 'react';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Fab,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useSnackbar } from 'notistack';
import { Filename } from '../Filename';
import { IFile } from 'types/types';

interface Props extends Omit<Partial<HTMLAttributes<HTMLDivElement>>, 'title'> {
  loading: boolean;
  progress: number;
  onFileChange: (file?: File) => void;
  documentFile?: IFile;
  title?: ReactNode;
  imageBoxProps?: Pick<BoxProps, 'sx'>;
  maxFileSize?: number;
}

export function FileUploadFormElement(props: Props) {
  const {
    loading,
    progress,
    onFileChange,
    documentFile,
    title = 'Upload file',
    maxFileSize = 2 * 1024 * 1024,
  } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [stage, setStage]: [stage: number, setStage: Function] = React.useState(
    documentFile ? 3 : 1,
  );

  const [file, setFile]: [
    file: File | undefined,
    setFile: Function,
  ] = React.useState(undefined);

  const removeFile = () => {
    setStage(1);
    setFile(undefined);
  };

  const fileChange = e => {
    const [f] = e.target.files || e.dataTransfer.files;
    if (f) {
      if (f.size > maxFileSize) {
        const fileSizeInMB = (maxFileSize / (1024 * 1024)).toFixed(1);
        enqueueSnackbar(`Document must be smaller than ${fileSizeInMB}MB`, {
          variant: 'error',
        });
        removeFile();
        e.target.value = null;
        return;
      }
      setFile(f);
      setStage(3);
    }
  };

  useEffect(() => {
    if (stage === 4 && !loading) {
      setStage(1);
    }
  }, [loading]);

  useEffect(() => {
    if (documentFile) {
      setStage(3);
    }
  }, [documentFile]);

  useEffect(() => {
    try {
      if (file) {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        if (!loading) {
          onFileChange(file);
        }
      } else if (!file && !!documentFile) {
        onFileChange();
      }
    } catch (e) {
      console.log(e);
    }
  }, [file]);

  const currentFileName = file
    ? file!.name
    : documentFile
    ? documentFile.filename
    : undefined;

  const content = useMemo(() => {
    switch (stage) {
      case 1:
      case 2:
        return (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            <Fab color="primary" size={'small'}>
              <Add />
            </Fab>
            <Box
              sx={{
                ml: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" color="primary">
                {title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textDecoration: 'underline' }}
              >
                Select from folder
              </Typography>
            </Box>

            <input
              disabled={loading}
              type="file"
              accept={['*'].join(', ')}
              onChange={fileChange}
              style={{
                opacity: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                cursor: 'pointer',
              }}
            />
          </Box>
        );

      case 3:
        return (
          <Paper
            elevation={0}
            sx={{
              width: 'auto',
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              p: 1,
              backgroundColor: 'grey.A100',
            }}
          >
            {!!currentFileName && (
              <Filename
                name={currentFileName}
                extension={currentFileName.split('.').pop()!}
              />
            )}
            <IconButton
              disabled={loading}
              size="small"
              color="error"
              onClick={() => removeFile()}
              sx={{ ml: 4 }}
            >
              <Close />
            </IconButton>
          </Paper>
        );

      case 4:
        return (
          <LoadingIndicator
            minHeight={300}
            ProgressProps={{
              variant: 'determinate',
              value: progress * 100,
            }}
            message={
              <>
                <Typography variant="h5">
                  Uploading - {(progress || 0).toFixed(0)}%
                </Typography>
              </>
            }
          />
        );
    }
  }, [props, file, stage]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
      }}
    >
      {content}
    </Box>
  );
}
