/**
 *
 * DashboardItem
 *
 */
import * as React from 'react';
import { HTMLAttributes } from 'react';
import { Box } from '@mui/material';
import { useVisible } from 'utils/useVisible';

interface Props extends HTMLAttributes<any> {
  minHeight?: number;
}

export function DashboardItem(props: Props) {
  const { minHeight = 400 } = props;
  const [seen, setSeen] = React.useState<boolean>(false);
  const [targetRef, isVisible] = useVisible((vi: number) => vi > 0.5);
  React.useEffect(() => {
    if (isVisible) setSeen(true);
    return () => {};
  }, [isVisible, setSeen]);
  return (
    <Box sx={{ minHeight, display: 'flex', height: '100%' }} ref={targetRef}>
      {(isVisible || seen) && (
        <Box sx={{ flex: 1, width: '100%' }}>{props.children}</Box>
      )}
    </Box>
  );
}
