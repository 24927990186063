import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.importData || initialState;

export const selectImportData = createSelector([selectSlice], state => state);

export const selectImportDataStatus = createSelector(
  [selectSlice],
  state => state.importStatus,
);

export const selectImportDataStatusData = createSelector(
  [selectSlice],
  state => state.importStatus.data,
);

export const selectImportDataUpload = createSelector(
  [selectSlice],
  state => state.importData,
);

export const selectRollbackImport = createSelector(
  [selectSlice],
  state => state.rollbackImport,
);

export const selectConfirmImport = createSelector(
  [selectSlice],
  state => state.confirmImport,
);
