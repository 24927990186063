/**
 *
 * LookupActionSelect
 *
 */
import * as React from 'react';
import { MenuItem, Select } from '@mui/material';

interface Props {
  lm: any;
  onChange: Function;
}

interface Option {
  action: {
    type: 'create-new' | 'use-existing' | 'replace-existing';
    code: string;
    old_value?: string;
    new_value: string;
  };
  description: any;
}

export function LookupActionSelect(props: Props) {
  const [value, setValue]: [any, Function] = React.useState(0);

  // todo remove this!
  React.useEffect(() => {
    setTimeout(() => setValue(2), 1000);
  }, [setValue]);

  React.useEffect(() => {
    if (value !== undefined) {
      props.onChange(options[value].action);
    }
  }, [value]);

  const options: Array<Option> = [
    {
      action: {
        type: 'create-new',
        code: props.lm.lookup.key,
        new_value: props.lm.lookup.value,
      },
      description: (
        <span>
          Create new entry with <b>{props.lm.lookup.value}</b>
        </span>
      ),
    },
  ];

  props.lm.lookup.lookup_matches.forEach(lm => {
    options.push({
      action: {
        type: 'use-existing',
        code: props.lm.lookup.key,
        old_value: props.lm.lookup.value,
        new_value: lm.code,
      },
      description: (
        <span>
          Use existing value <b>{lm.code}</b>
        </span>
      ),
    });
    options.push({
      action: {
        type: 'replace-existing',
        code: props.lm.lookup.key,
        old_value: lm.code,
        new_value: props.lm.lookup.value,
      },
      description: (
        <span>
          Use <b>{props.lm.lookup.value}</b> instead of <b>{lm.code}</b>
        </span>
      ),
    });
  });

  return (
    <Select
      variant="outlined"
      fullWidth
      style={{ maxWidth: 280 }}
      onChange={ev => setValue(ev.target.value)}
      displayEmpty
      value={value}
      renderValue={(value: any) =>
        value !== undefined
          ? options[value].description
          : '--------- Select action ---------'
      }
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={index}>
          {option.description}
        </MenuItem>
      ))}
    </Select>
  );
}
