/**
 *
 * Portal
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PortalFooter from 'app/components/PortalFooter';
import PortalAppBar from 'app/components/PortalAppBar';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ImportData } from 'app/containers/ImportData';
import { selectUserData } from 'app/containers/AuthProvider/slice/selectors';
import { selectImportDataStatusData } from 'app/containers/ImportData/slice/selectors';
import { Report } from '../Report';
import { selectCurrentCustomers } from '../CustomerSelector/slice/selectors';
import { TabbedContent } from '../../components/TabbedContent';
import { AmendedItems } from '../AmendedItems';
import { Dashboard } from '../Dashboard';
import { FullReport } from '../FullReport';
import { AdjustmentReports } from '../AdjustmentReports';
import { Customers } from '../Customers';
import { Users } from '../Users';
import { AccountSettings } from '../AccountSettings';
import { CustomerSelector } from '../CustomerSelector';
import { LineAdjustmentManager } from '../LineAdjustmentManager';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { UserPermission } from '../AuthProvider/slice/types';
import { hasPermission } from '../../../utils/user';
import { usePageTracking } from '../../../utils/usePageTracking';
import { ManageSuppliers } from '../ManageSuppliers';
import { portalActions, usePortalSlice } from './slice';
import { useLocation } from 'react-router';
import { ImportMassAdjustment } from '../ImportMassAdjustment';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flex: '1 1 auto',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.grey[300],
  },
  header: {
    flex: '0 1 auto',
  },
  footer: {
    flex: '0 1 auto',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

export function Portal(props: Props) {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { actions } = usePortalSlice();
  const [route, setRoute] = React.useState({
    to: pathname,
    from: pathname,
  });

  const setMenu = (openState: boolean) =>
    dispatch(portalActions.setMenuOpen(openState));

  const userData = useSelector(selectUserData);
  const importDataStatus = useSelector(selectImportDataStatusData);
  const currentCustomers = useSelector(selectCurrentCustomers);
  usePageTracking();

  React.useEffect(() => {
    setMenu(false);
    setRoute(prev => ({ to: pathname, from: prev.to }));
  }, [pathname]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <CustomerSelector>
      <LineAdjustmentManager>
        <>
          <Helmet>
            <title>Portal</title>
            <meta name="description" content="Description of Portal" />
          </Helmet>
          <PortalAppBar
            importDataStatus={importDataStatus}
            userData={userData!}
            currentCustomers={currentCustomers}
          />
          <div>
            <Box className={classes.container}>
              <Box className={classes.header}></Box>
              <Box className={classes.content}>
                <Box width={'100%'}>
                  <Switch>
                    <Route
                      exact
                      path={'/portal'}
                      render={rProps => <Dashboard {...rProps} />}
                    />
                    <Route
                      exact
                      path={`${match.url}/report/full/:id`}
                      component={FullReport}
                    />
                    <Route
                      path={`${match.url}/report`}
                      render={rProps => (
                        <TabbedContent
                          {...rProps}
                          tabs={[
                            {
                              default: true,
                              label: 'All Reports',
                              path: '/all',
                              render: rProps => (
                                <Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      mt: 4,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant={'h1'}
                                      color={'primary'}
                                    >
                                      All reports
                                    </Typography>
                                  </Box>
                                  <Report
                                    id={'main-report'}
                                    type={'full'}
                                    {...rProps}
                                  />
                                </Box>
                              ),
                            },
                            {
                              label: 'Amended Items',
                              path: '/amended-items',
                              hidden: currentCustomers.length !== 1,
                              component: AmendedItems,
                            },
                            {
                              label: 'Pending Approval',
                              path: '/pending-approval',
                              hidden: currentCustomers.length !== 1,
                              component: rP => (
                                <AdjustmentReports
                                  {...rP}
                                  status={'pending approval'}
                                />
                              ),
                            },
                            {
                              label: 'Accepted Reports',
                              path: '/accepted',
                              hidden: currentCustomers.length !== 1,
                              component: rP => (
                                <AdjustmentReports
                                  {...rP}
                                  status={'accepted'}
                                />
                              ),
                            },
                            {
                              label: 'Import Mass Adjustment',
                              path: '/import',
                              hidden: currentCustomers.length !== 1,
                              component: ImportMassAdjustment,
                            },
                          ]}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/accounts`}
                      render={rProps => (
                        <TabbedContent
                          {...rProps}
                          tabs={[
                            {
                              default: true,
                              label: 'My Account Settings',
                              path: '/update',
                              component: AccountSettings,
                            },
                            {
                              label: 'Customers',
                              path: '/customers',
                              hidden: !hasPermission(
                                userData,
                                UserPermission.ADMINISTER_SYSTEM,
                              ),
                              component: Customers,
                            },
                            {
                              label: 'Users',
                              path: '/users',
                              hidden: !hasPermission(
                                userData,
                                UserPermission.ADMINISTER_SYSTEM,
                              ),
                              component: Users,
                            },
                          ]}
                        />
                      )}
                    />
                    <ProtectedRoute
                      path={`${match.url}/suppliers/:action?`}
                      component={ManageSuppliers}
                      isAccessible={
                        currentCustomers.length === 1 &&
                        hasPermission(userData, UserPermission.UPLOAD_DATA)
                      }
                    />
                    <ProtectedRoute
                      path={`${match.url}/import`}
                      component={ImportData}
                      isAccessible={
                        currentCustomers.length === 1 &&
                        hasPermission(userData, UserPermission.UPLOAD_DATA)
                      }
                    />
                    <Route render={rProps => <Redirect to={match.url} />} />
                  </Switch>
                </Box>
              </Box>
              <div className={classes.footer}>
                <PortalFooter
                  userData={userData!}
                  currentCustomers={currentCustomers}
                />
              </div>
            </Box>
          </div>
        </>
      </LineAdjustmentManager>
    </CustomerSelector>
  );
}
