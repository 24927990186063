/**
 *
 * AccountSettings
 *
 */
import * as React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserPermissions } from '../AuthProvider/slice/selectors';
import { formStyles } from '../../../styles/theme/themes';
import { Field, Form, Formik } from 'formik';
import cn from 'classnames';
import { PasswordField } from '../../components/PasswordField';
import Yup from '../../../utils/yup';
import { useAccountSettingsSlice } from './slice';
import { selectChangePassword } from './slice/selectors';
import { useSnackbar } from 'notistack';

interface Props {}

export function AccountSettings(props: Props) {
  const userPermissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();
  const { actions } = useAccountSettingsSlice();
  const changePassword = useSelector(selectChangePassword);
  const schema = Yup.object().shape({
    old_password: Yup.string().required('Current password required'),
    new_password: Yup.string()
      .required('New password required')
      .checkPassword(),
    password_confirmation: Yup.string()
      .required('Confirm password required')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  });

  const formClasses = formStyles();

  const resetPassword = values => {
    dispatch(actions.changePasswordRequest(values));
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (changePassword.loading) return;
    if (changePassword.data) {
      enqueueSnackbar('Password changed successfully.', {
        variant: 'success',
      });
      dispatch(actions.changePasswordMount());
    } else if (changePassword.error) {
      enqueueSnackbar(changePassword.error.message, {
        variant: 'error',
      });
    } else {
      closeSnackbar();
    }
  }, [changePassword.loading, enqueueSnackbar]);

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h1" color={'primary'}>
        My Account Settings
      </Typography>
      <Paper sx={{ mt: 2, p: 4 }}>
        <Formik
          initialValues={{
            old_password: '',
            new_password: '',
            password_confirmation: '',
          }}
          validationSchema={schema}
          validateOnChange
          onSubmit={(values, { setSubmitting }) => {
            resetPassword(values);
          }}
        >
          {({ submitForm, isSubmitting, setSubmitting, dirty, resetForm }) => {
            if (isSubmitting !== changePassword.loading) {
              setSubmitting(changePassword.loading);
            }

            if (changePassword.data && dirty) {
              resetForm();
            }

            return (
              <Form>
                <Box className={cn('has-actions')}>
                  <Box className={formClasses.formContent}>
                    <Field
                      component={PasswordField}
                      id="old_password"
                      name="old_password"
                      label="Current password"
                      fullWidth
                      className={formClasses.field}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      component={PasswordField}
                      id="new_password"
                      name="new_password"
                      label="New password"
                      fullWidth
                      className={formClasses.field}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Field
                      component={PasswordField}
                      id="password_confirmation"
                      name="password_confirmation"
                      label="Confirm new password"
                      fullWidth
                      className={formClasses.field}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                          fullWidth
                        >
                          Save new password
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Box>
  );
}
