/**
 *
 * LoggingProvider
 *
 */
import * as React from 'react';
import { useLoggingProviderSlice } from './slice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoggingProviderLogUserActions,
  selectLoggingProviderUserActionQueue,
} from './slice/selectors';

interface Props {}

export function LoggingProvider(props: Props) {
  const { actions } = useLoggingProviderSlice();
  const userActionQueue = useSelector(selectLoggingProviderUserActionQueue);
  const logUserActions = useSelector(selectLoggingProviderLogUserActions);
  const dispatch = useDispatch();

  const sendUserActionLog = useCallback(() => {
    if (logUserActions.loading) return;
    if (!userActionQueue.length) return;
    dispatch(actions.logUserActionsRequest());
  }, [userActionQueue, logUserActions]);

  let interval;

  useEffect(() => {
    interval = setInterval(sendUserActionLog, 5000);
    return () => {
      clearInterval(interval);
    };
  });

  return null;
}
