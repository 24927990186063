import * as React from 'react';
import { isEqual, get } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdjustedItems, AdjustmentIntent } from '../slice/types';
import { DutyMeasure, LineItem } from '../../ImportData/slice/types';
import {
  formatCurrency,
  formatCurrencyTextField,
} from '../../../../utils/formatCurrency';
import { LineAdjustmentFormProps } from './index';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Autocomplete } from '@mui/material';
import {
  CommCodeFormatCustom,
  CurrencyFormatCustom,
} from '../../../components/InputMasks';

const fields = {
  item_price: {
    label: 'Item Price',
  },
};

type ValuesType = {
  [k in keyof typeof fields]: string;
};

interface Props extends LineAdjustmentFormProps {}

export function ItemPriceAdjustmentForm(props: Props) {
  const [step, setStep] = React.useState<number>(1);

  const defaultValues = props.adjustmentIntent.changeable_fields.reduce(
    (acc: any, curr) => {
      acc[curr.field] = curr.default_value;
      return acc;
    },
    {},
  );

  const [values, setValues] = React.useState<ValuesType>(defaultValues);

  React.useEffect(() => {
    if (!props.adjustedItemsLoading) {
      if (!props.adjustedItems) setStep(1);
      else if (step === 1) setStep(2);
    }
  }, [props.adjustedItemsLoading]);

  if (props.adjustedItemsLoading || props.confirmAdjustmentLoading) {
    return <LoadingIndicator minHeight={150} />;
  }

  if (step === 2) {
    const calculatedValues = props.adjustedItems?.adjusted_items.map(ai => ({
      line_item_id: ai.id,
      item_customs_value: ai.item_customs_value,
      item_customs_duty_paid: ai.item_customs_duty_paid,
      item_vat_value: ai.item_vat_value,
      item_vat_paid: ai.item_vat_paid,
    }));

    return (
      <>
        <DialogContentText sx={{ mb: 3 }}>
          Review the new values below.
        </DialogContentText>
        <Box>
          {props.adjustedItems?.adjusted_items.map(item => {
            return (
              <Card elevation={0} variant={'outlined'} sx={{ mt: 1 }}>
                <CardHeader
                  disableTypography
                  title={
                    <Typography variant={'body1'}>
                      Line ref: {item.line_code}
                    </Typography>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs sm={3}>
                      <TextField
                        label="Customs value"
                        {...formatCurrencyTextField(item.item_customs_value)}
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={3}>
                      <TextField
                        label="Customs duty paid"
                        {...formatCurrencyTextField(
                          item.item_customs_duty_paid,
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={3}>
                      <TextField
                        label="VAT value"
                        {...formatCurrencyTextField(item.item_vat_value)}
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={3}>
                      <TextField
                        label="VAT paid"
                        {...formatCurrencyTextField(item.item_vat_paid)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        <DialogActions>
          <Button
            sx={{ marginRight: 'auto' }}
            variant="text"
            onClick={() => setStep(1)}
          >
            Back
          </Button>
          <Button onClick={() => props.confirmAdjustment(calculatedValues)}>
            Confirm adjustment
          </Button>
        </DialogActions>
      </>
    );
  }

  // Show form for currency adjustment
  return (
    <>
      <DialogContentText sx={{ mb: 3 }}>
        Set the new values for
        {props.adjustmentIntent.count
          ? ` ${props.adjustmentIntent.count} items`
          : ` the line item`}
      </DialogContentText>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs sm={6}>
            <TextField
              fullWidth
              {...formatCurrencyTextField(values.item_price)}
              label={`${fields.item_price.label}`}
              name={'item_price'}
              onChange={ev =>
                setValues({
                  ...values,
                  item_price: ev.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <DialogActions>
        <Button onClick={() => props.getAdjustedItems(values)}>Proceed</Button>
      </DialogActions>
    </>
  );
}
