import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { customersSliceActions as actions } from '.';
import { authProviderActions } from '../../AuthProvider/slice';

function* loadCustomers(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `customers`,
      method: 'get',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.loadCustomersSuccess(payload));
  } catch (error) {
    yield put(actions.loadCustomersFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadCustomer(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `customers/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    const customers = yield call(apiRequest, {
      url: 'customers',
      method: 'get',
    });

    yield put(actions.loadCustomerSuccess({ ...payload, customers }));
  } catch (error) {
    yield put(actions.loadCustomerFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveCustomer(action) {
  try {
    const payload = yield call(apiRequest, {
      url: action.payload.id ? `customers/${action.payload.id}` : 'customers',
      method: action.payload.id ? 'put' : 'post',
      data: {
        ...action.payload,
      },
    });

    yield put(authProviderActions.loadUserDataRequest());
    yield put(actions.saveCustomerSuccess(payload));
  } catch (error) {
    yield put(actions.saveCustomerFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* deleteCustomer(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `customers/${action.payload.id}`,
      method: 'delete',
      data: {},
    });

    yield put(authProviderActions.loadUserDataRequest());
    yield put(actions.deleteCustomerSuccess(payload));
  } catch (error) {
    yield put(actions.deleteCustomerFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* customersSliceSaga() {
  yield takeLatest(actions.loadCustomersRequest.type, loadCustomers);
  yield takeLatest(actions.loadCustomerRequest.type, loadCustomer);
  yield takeLatest(actions.saveCustomerRequest.type, saveCustomer);
  yield takeLatest(actions.deleteCustomerRequest.type, deleteCustomer);
}
