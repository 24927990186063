/**
 *
 * EnvironmentIndicator
 *
 */
import * as React from 'react';
import { Button } from '@mui/material';
import { useConfirm } from '../../components/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useEnvironmentIndicatorSlice } from './slice';
import { selectEnvironmentIndicator } from './slice/selectors';
import { selectUserData } from '../AuthProvider/slice/selectors';
import { get } from 'lodash';
import { UserPermission } from '../AuthProvider/slice/types';
import { hasPermission } from '../../../utils/user';

interface Props {}

export function EnvironmentIndicator(props: Props) {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { actions } = useEnvironmentIndicatorSlice();
  const userData = useSelector(selectUserData);
  const resetState = useSelector(selectEnvironmentIndicator);

  return (
    <>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div
          style={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            textTransform: 'uppercase',
            background: 'white',
            border: '2px solid red',
            boxShadow: '0 0 0 1px white',
            borderRadius: 8,
            padding: 8,
            zIndex: 1000,
            opacity: 0.8,
            fontSize: 10,
            fontWeight: 600,
          }}
        >
          {process.env.REACT_APP_ENV} Environment
          {hasPermission(userData, UserPermission.ADMINISTER_SYSTEM) &&
            process.env.REACT_APP_ENV === 'demo' && (
              <>
                <br />
                <Button
                  style={{ position: 'relative' }}
                  variant={'outlined'}
                  size={'small'}
                  onClick={() =>
                    confirm({
                      description:
                        'Reset all demo data to base set? This action cannot be reverted.',
                    })
                      .then(() => {
                        dispatch(actions.resetDemoRequest());
                      })
                      .catch(e => {
                        console.log(e);
                      })
                  }
                >
                  Reset Demo Data
                </Button>
              </>
            )}
        </div>
      )}
    </>
  );
}
