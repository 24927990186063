import * as React from 'react';
import {
  Box,
  Button,
  Hidden,
  IconButton,
  Radio,
  Skeleton,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridValueFormatterParams,
  LicenseInfo,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { ChangeAction } from '../LineAdjustmentManager/slice/types';
import { ReportTable } from './slice/types';
import { formatCurrency } from '../../../utils/formatCurrency';
import { useConfirm } from '../../components/ConfirmDialog';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { formatParsedISO } from '../../../utils/dates';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { useSelector } from 'react-redux';
import { selectUserData } from '../AuthProvider/slice/selectors';
import { hasPermission } from '../../../utils/user';
import { UserPermission } from '../AuthProvider/slice/types';

LicenseInfo.setLicenseKey(
  'f77587a7b5c342ce53f544dfe56897ebT1JERVI6NDAyMTgsRVhQSVJZPTE2Nzk1ODYwODIwMDAsS0VZVkVSU0lPTj0x',
);

interface Props {
  report: ReportTable;
  onRowClick?: Function;
  onClickToExportDataSheet: (lineItemIds: number[]) => void;
  onClickToEdit: (changeType: ChangeAction, lineItemIds: number[]) => void;
  onPageChange: Function;
  onPageSizeChange: Function;
  onSortChange: Function;
  onExportConfirm: Function;
  rowHeight?: number;
  headerHeight?: number;
  loading?: Boolean;
  disableAdjustments?: Boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  selectedColumn: {
    backgroundColor: theme.palette.grey['200'],
    '.rowSelected &': {
      borderColor: green.A700,
      borderStyle: 'dashed',
      borderWidth: 1,
      backgroundColor: 'white',
    },
  },
  confirmed: {
    backgroundColor: theme.palette.grey.A100,
    '&:hover': {
      backgroundColor: `${theme.palette.grey.A200} !important`,
    },
  },
  amended: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  pendingApproval: {
    backgroundColor: green['200'],
    '&:hover': {
      backgroundColor: `${green['400']} !important`,
    },
  },
  root: {
    '& .MuiDataGrid-row': {
      '&.rowSelected': {
        backgroundColor: theme.palette.grey['200'],
      },
    },
  },
}));

export function DataTable(props: Props) {
  const { report, headerHeight, rowHeight, onRowClick } = props;
  const [selectedColumn, setSelectedColumn]: [
    selectedColumn: string | undefined,
    setSelectedColumn: Function,
  ] = React.useState();
  const [selectedRows, setSelectedRows]: [
    selectedRows: number[],
    setSelectedRows: Function,
  ] = React.useState([]);
  const [tableColumns, setTableColumns] = React.useState<GridColDef[]>([]);

  const userData = useSelector(selectUserData);

  const confirm = useConfirm();
  const classes = useStyles();

  const ref = useGridApiRef();

  const data = report.data;
  const columns = report.columns.map(col => {
    // disable change if no perm
    return {
      ...col,
      change: !hasPermission(userData, UserPermission.CREATE_ADJUSTMENTS)
        ? undefined
        : col.change,
    };
  });

  const rows = data.data.map(row => ({
    ...row,
    ...row.current_state,
    ...row.metadata,
  }));

  const toggleRowSelection = id => {
    const index = selectedRows.indexOf(+id);
    if (index === -1) {
      selectRows([...selectedRows, +id]);
    } else {
      selectRows(
        selectedRows.slice(0, index).concat(selectedRows.slice(index + 1)),
      );
    }
  };

  const selectRows = (ids: Array<number>) => {
    setSelectedRows(
      rows
        .filter(
          row => row.change_state === 'clean' && ids.indexOf(row.id) !== -1,
        )
        .map(row => row.id),
    );
  };

  const getTableColumns = () => {
    const checkboxColumn = {
      field: 'checkbox',
      sortable: false,
      resizable: false,
      width: 48,
      filterable: false,
      disableColumnMenu: true,
    } as GridColDef;
    renderColumn(checkboxColumn);

    const checkboxColumnArray: Array<GridColDef> = [];

    if (!props.disableAdjustments) {
      checkboxColumnArray.push(checkboxColumn);
    }

    const cols = [
      ...checkboxColumnArray,
      ...columns.map((c, i) => {
        const column: GridColDef = {
          field: c.field,
          headerName: c.label,
          sortable: !!c.sortable,
          flex: undefined,
          hide: c.hidden,
          width: c.width || 140,
        };

        if (c.format === 'date') {
          column.type = 'date';
          column.valueFormatter = params => formatParsedISO(params.value);
        }

        if (c.format === 'numeric') {
          column.type = 'number';
        }

        if (c.format === 'currency') {
          column.type = 'number';
          column.valueFormatter = (params: GridValueFormatterParams) => {
            const row: any = ref.current.getRow(params.id!);

            if (row[c.currency_field || 'base_currency']) {
              return formatCurrency(
                params.value ? +params.value : 0,
                {},
                row[c.currency_field || 'base_currency'],
              );
            }
            return '';
          };
        }

        renderColumn(column);
        return column;
      }),
    ];

    return cols;
  };

  const renderColumn = (column: GridColDef) => {
    // Set up checkbox selection first
    if (column.field === 'checkbox') {
      column.renderHeader = p => <Box />;
      column.renderCell = ({ value, row }) => (
        <IconButton
          onClick={() => {}}
          disabled={row.change_state !== 'clean'}
          size="small"
        >
          {selectedRows.indexOf(row.id) !== -1 ? (
            <CheckBox />
          ) : (
            <CheckBoxOutlineBlank />
          )}
        </IconButton>
      );
    }
    const colDef = columns.find(c => c.field === column.field);
    if (!colDef) return;

    if (colDef.change && !props.disableAdjustments) {
      column.renderCell = ({ value, row }) => {
        let renderedValue = value;
        if (
          colDef.format === 'currency' &&
          row[colDef.currency_field || 'base_currency']
        ) {
          renderedValue = formatCurrency(
            value || 0,
            {},
            row[colDef.currency_field || 'base_currency'],
          );
        }
        return (
          <Button
            disabled={row.change_state !== 'clean'}
            fullWidth
            sx={{
              px: 0,
              justifyContent:
                colDef.format === 'currency' ? 'flex-end' : 'center',
            }}
            onClick={e => {
              e.stopPropagation();
              props.onClickToEdit(colDef.change as ChangeAction, [row.id]);
            }}
            startIcon={<Edit />}
          >
            {renderedValue || ' '}
          </Button>
        );
      };
      column.cellClassName = (params: GridCellParams) => {
        return clsx({
          [classes.selectedColumn]: params.colDef.field === selectedColumn,
        });
      };
    }

    column.renderHeader = colProps => (
      <Box sx={{ display: 'flex' }}>
        {!!colDef.change &&
          colDef.change !== 'price' &&
          !props.disableAdjustments && (
            <Radio
              checked={selectedColumn === column.field}
              onClick={ev => {
                ev.stopPropagation();
                if (selectedColumn !== column.field)
                  setSelectedColumn(column.field);
                else setSelectedColumn();
              }}
            />
          )}

        <span>{colProps.colDef.headerName}</span>
      </Box>
    );
  };

  React.useEffect(() => {
    if (!props.loading) selectRows(selectedRows);

    if (!tableColumns.length && report) {
      setTableColumns(getTableColumns());
    }
  }, [props.loading, props.disableAdjustments]);

  useCustomerSelector(() => {
    setTableColumns(getTableColumns());
  });

  React.useEffect(() => {
    //setTableColumns(getTableColumns());
    if (!columns.length) return;
    ref.current.getAllColumns().forEach(column => renderColumn(column));
  }, [selectedColumn, selectedRows]);

  if (!report) return <Skeleton height={300} />;

  return (
    <DataGridPro
      apiRef={ref}
      initialState={{ pinnedColumns: { left: ['checkbox'] } }}
      className={classes.root}
      columns={tableColumns}
      page={Math.max(data.meta.current_page - 1, 0)}
      rowCount={data.meta.total}
      pageSize={data.meta.per_page}
      paginationMode="server"
      sortingMode="server"
      disableColumnFilter
      onRowClick={(params, ev) => {
        if (params.row.change_state === 'clean')
          toggleRowSelection(params.row.id);
      }}
      disableSelectionOnClick
      hideFooterSelectedRowCount
      onPageChange={page => {
        props.onPageChange(page + 1, data.meta.per_page);
      }}
      onSortModelChange={sortModel => {
        props.onSortChange(sortModel);
      }}
      rowsPerPageOptions={[100, 200, 500, 1000, 2000]}
      onPageSizeChange={s => {
        props.onPageChange(1, data.meta.per_page);
        props.onPageSizeChange(s);
      }}
      getRowClassName={params =>
        clsx({
          rowSelected: selectedRows.indexOf(params.row.id) !== -1,
          [classes.confirmed]: params.row.change_state === 'confirmed',
          [classes.amended]: params.row.change_state === 'amended',
          [classes.pendingApproval]:
            params.row.change_state === 'pending approval',
        })
      }
      pagination
      disableColumnMenu
      columnBuffer={6}
      disableMultipleColumnsSorting
      rows={rows}
      loading={!!props.loading}
      components={{
        LoadingOverlay: () => (
          <LoadingIndicator
            minHeight={'100%'}
            message={'Loading data'}
            sx={{
              position: 'relative',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
          />
        ),
        Toolbar: () => (
          <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            {!props.disableAdjustments && (
              <>
                <Button
                  startIcon={<CheckBox />}
                  onClick={() => {
                    selectRows(rows.map(row => row.id));
                  }}
                >
                  <Hidden lgDown>Select all</Hidden>
                </Button>
                <Button
                  disabled={selectedRows.length === 0}
                  startIcon={<CheckBoxOutlineBlank />}
                  onClick={() => {
                    selectRows([]);
                  }}
                >
                  <Hidden lgDown>Clear selection</Hidden>
                </Button>
                {!!hasPermission(
                  userData,
                  UserPermission.CREATE_ADJUSTMENTS,
                ) && (
                  <Button
                    disabled={selectedRows.length > 30 || !selectedColumn}
                    startIcon={<Edit />}
                    onClick={() => {
                      props.onClickToEdit(
                        columns.find(c => c.field === selectedColumn)!.change!,
                        selectedRows,
                      );
                    }}
                  >
                    <Hidden lgDown>
                      Adjust selected{' '}
                      {selectedRows.length
                        ? `(${selectedRows.length} items)`
                        : ''}
                    </Hidden>
                  </Button>
                )}
                {!!hasPermission(
                  userData,
                  UserPermission.CREATE_ADJUSTMENTS,
                ) && (
                  <Button
                    disabled={!selectedRows.length}
                    startIcon={<Edit />}
                    onClick={() => {
                      props.onClickToExportDataSheet(selectedRows);
                    }}
                  >
                    <Hidden lgDown>
                      Get mass adjustment sheet{' '}
                      {selectedRows.length
                        ? `(${selectedRows.length} items)`
                        : ''}
                    </Hidden>
                  </Button>
                )}
              </>
            )}
            <GridToolbarDensitySelector style={{ marginLeft: 'auto' }} />
            <GridToolbarColumnsButton />
            <GridToolbarExport
              onClick={() => {
                confirm({
                  description:
                    'This will produce an Excel document and send it to your account email address. Are you sure?',
                })
                  .then(() => props.onExportConfirm())
                  .catch(() => {});
              }}
            />
          </GridToolbarContainer>
        ),
      }}
    />
  );
}
