import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { environmentIndicatorSaga } from './saga';
import { EnvironmentIndicatorState } from './types';
import { ResponseErrorPayload } from '../../../../utils/request';
import { apiCallInitialState } from '../../../../types/ApiCall';

export const initialState: EnvironmentIndicatorState = {
  resetDemo: apiCallInitialState,
};

const slice = createSlice({
  name: 'environmentIndicator',
  initialState,
  reducers: {
    resetDemoRequest(state, action: PayloadAction<void>) {
      state.resetDemo.loading = true;
      state.resetDemo.data = false;
    },
    resetDemoSuccess(state, action: PayloadAction<void>) {
      state.resetDemo.loading = false;
      state.resetDemo.data = true;
    },
    resetDemoFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.resetDemo.loading = false;
      state.resetDemo.error = action.payload;
      state.resetDemo.data = false;
    },
  },
});

export const { actions: environmentIndicatorActions } = slice;

export const useEnvironmentIndicatorSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: environmentIndicatorSaga });
  return { actions: slice.actions };
};
