import { useRef, useState, useCallback, useEffect } from 'react';

const defaultIntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: Array.from(
    {
      length: 100,
    },
    (v, i) => i * 0.01,
  ),
};
const useVisible = (cb, options = {}) => {
  const targetRef = useRef(null);
  const observerRef = useRef(null);
  const [visible, setVisible] = useState(0); // TODO: visible types refactor

  const status = typeof cb === 'function' ? cb(visible) : visible;
  const observerCallback = useCallback(entries => {
    entries.forEach(entry => {
      setVisible(entry.intersectionRatio);
    });
  }, []);
  useEffect(() => {
    if (observerRef.current) return;
    if (!targetRef.current) return;
    observerRef.current = new IntersectionObserver(observerCallback, {
      ...defaultIntersectionObserverInit,
      ...options,
    });
    observerRef.current.observe(targetRef.current);
  });

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [observerRef, targetRef]);
  return [targetRef, status];
};

export { useVisible };
