/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import {
  Box,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useHasChanged } from 'utils/usePrevious';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { FilterableReportTable } from '../../../../components/FilterableReportTable';
import { useMemo } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import key from 'weak-key';

interface Props extends ReportGraphicProps {}

export function DutyUnderManagement(props: Props) {
  const { data } = props.graphic.data!;
  const { reportSettings } = props;

  const compactCurrency = variableFormatCurrency(1000000000, data.currency);

  const entries = useMemo(() => {
    return [
      {
        title: 'Actual',
        value: compactCurrency(get(data, 'values.actual', 0)),
        size: 'large',
      },
      {
        title: 'Period',
        value: `${get(data, 'values.months', 0)}/36`,
        size: 'large',
        divider: true,
      },
      {
        title: 'Duty paid',
        value: compactCurrency(get(data, 'values.duty_paid', 0)),
        size: 'small',
      },
      {
        title: 'FTA claimed',
        value: compactCurrency(get(data, 'values.fta_claimed', 0)),
        size: 'small',
      },
      {
        title: 'Other reliefs claimed',
        value: compactCurrency(get(data, 'values.other_reliefs_claimed', 0)),
        size: 'small',
      },
      {
        title: 'Special Procedures',
        value: compactCurrency(
          get(data, 'values.special_procedures_savings', 0),
        ),
        size: 'small',
      },
    ];
  }, [data]);
  return (
    <Box sx={{ p: 4, width: '100%' }}>
      <Grid container spacing={2} justifyContent={'space-between'}>
        {entries.map((entry, index) => (
          <React.Fragment key={key(entry)}>
            <Grid
              item
              xs={12}
              sm={entry.size === 'large' ? 6 : 3}
              lg={entry.size === 'large' ? 2.5 : 1.66}
            >
              <Stack
                direction={{ xs: 'column', lg: 'row' }}
                justifyContent={'space-between'}
                sx={{
                  height: '100%',
                }}
              >
                <Box>
                  <Typography variant={'subtitle2'}>{entry.title}</Typography>
                  <Typography variant={entry.size === 'large' ? 'h2' : 'h4'}>
                    {entry.value}
                  </Typography>
                </Box>
                {entry.divider && (
                  <>
                    <Hidden lgDown>
                      <Box
                        sx={{
                          borderLeft: 1,
                          borderColor: 'divider',
                          mx: 4,
                          height: '100%',
                          alignSelf: 'stretch',
                        }}
                      ></Box>
                    </Hidden>
                  </>
                )}{' '}
              </Stack>
            </Grid>
            {entry.divider && (
              <Hidden lgUp>
                <Grid xs={12} item>
                  <Divider sx={{ mt: 3, mb: 1 }} />
                </Grid>
              </Hidden>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}
