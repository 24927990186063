/**
 *
 * ProtectedRoute
 *
 */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteProps {
  isAccessible: boolean;
  redirectToPath?: string;
  render?: never;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export function ProtectedRoute(props: Props) {
  const { isAccessible, redirectToPath, component: Component, ...rest } = props;

  return (
    <Route
      render={rProps =>
        isAccessible ? (
          <Component {...rProps} />
        ) : (
          <Redirect
            to={{
              pathname: redirectToPath,
              search:
                redirectToPath === '/login'
                  ? `?redirect=${rProps.location.pathname}`
                  : null,
              state: { from: rProps.location },
            }}
          />
        )
      }
      {...rest}
    />
  );
}
