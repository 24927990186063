/**
 *
 * CustomerSelectButton
 *
 */
import React from 'react';
import { Button, Hidden, Typography } from '@mui/material';
import { ArrowDropDown, People } from '@mui/icons-material';

interface Props {
  text: string;
  onClick: Function;
}

export function CustomerSelectButton(props: Props) {
  return (
    <Button
      onClick={ev => {
        props.onClick();
      }}
      variant="contained"
      style={{ backgroundColor: 'white', color: 'gray' }}
      startIcon={<People style={{ color: 'gold' }} />}
      endIcon={<ArrowDropDown style={{ color: 'black' }} />}
    >
      <Hidden mdDown>
        <Typography
          variant="subtitle2"
          style={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.text}
        </Typography>
      </Hidden>
    </Button>
  );
}
