import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.usersSlice || initialState;

export const selectUsersSlice = createSelector([selectSlice], state => state);
export const selectUsers = createSelector([selectSlice], state => state.users);

export const selectUser = createSelector([selectSlice], state => state.user);

export const selectSaveUser = createSelector(
  [selectSlice],
  state => state.save,
);
