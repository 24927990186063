/**
 *
 * FilterBar
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { identity, isEmpty, isEqual, pickBy, startCase } from 'lodash';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from '../../components/DateRangePicker';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { selectCurrentCustomers } from '../CustomerSelector/slice/selectors';
import { useFilterSlice } from './slice';
import { selectAvailableFilters, selectFilterSlice } from './slice/selectors';
import { CommCodeFormatCustom } from '../../components/InputMasks';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';

// import { useLocalStorage } from 'usehooks-ts';

interface Props {
  values: { [key: string]: any };
  fields?: Array<any>;
}

export function FilterBar(props: Props) {
  let fields = props.fields;
  if (!fields) {
    fields = [
      'date_range',
      'line_code',
      'divider',
      'commodity_code',
      'preference',
      'cpc',
      'divider',
      'origin_country',
      'dispatch_country',
      'divider',
      'importers',
      'supplier',
      'declarants',
      'branch_code',
    ];
  }

  const [filters, setFilters] = useLocalStorage<{ [key: string]: any }>(
    'filters',
    {},
  );

  useEffectOnce(() => {
    dispatch(actions.setFilters(filters));
  });

  const ref = useRef<HTMLDivElement>(null);

  const boxHeight = useMemo(() => {
    if (!ref.current) return 0;
    return ref.current.clientHeight;
  }, [ref.current]);

  const currentCustomers = useSelector(selectCurrentCustomers);

  const {
    filters: savedFilters,
    loadAvailableFieldFilters: {
      data: {
        adjustment_types,
        preferences,
        importers,
        dispatchCountries,
        originCountries,
        cpcs,
        suppliers,
        branch_codes,
        declarants,
      },
    },
  } = useSelector(selectFilterSlice);

  const dispatch = useDispatch();
  const { actions } = useFilterSlice();

  const filtersActive = !isEmpty(filters);
  const [open, setOpen] = React.useState<boolean>(filtersActive);

  useEffect(() => {
    dispatch(actions.loadAvailableFieldFiltersRequest());
  }, [actions, currentCustomers, dispatch]);

  const setFilterValue = (obj: { [key: string]: any }) => {
    setFilters(pickBy({ ...filters, ...obj }, identity));
  };

  const clearFilters = () => {
    setFilters({});
  };

  const getField = useCallback(
    field => {
      switch (field) {
        case 'date_range':
          return (
            <Grid item sm={12} lg>
              <DateRangePicker
                values={[filters.date_from, filters.date_to]}
                onChange={values => {
                  setFilterValue({ date_from: values[0], date_to: values[1] });
                }}
              />
            </Grid>
          );
        case 'branch_code':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!branch_codes.length}
                filterSelectedOptions
                fullWidth
                options={branch_codes.map(c => c)}
                value={filters.branch_codes || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    branch_codes: value.length ? value : undefined,
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Branch Codes"
                    variant="outlined"
                  />
                )}
                multiple
              />
            </Grid>
            /*
                                    <Grid item xs sm={4}>
                                      <TextField
                                        fullWidth
                                        label="Branch code"
                                        value={filters.branch_code || ''}
                                        onChange={ev => {
                                          setFilterValue({ branch_code: ev.target.value });
                                        }}
                                      />
                                    </Grid> */
          );
        case 'commodity_code':
          return (
            <Grid item xs sm={4}>
              <TextField
                fullWidth
                label="Commodity code"
                value={filters.commodity_code || ''}
                onChange={ev => {
                  setFilterValue({ commodity_code: ev.target.value });
                }}
                InputProps={{
                  inputComponent: CommCodeFormatCustom as any,
                }}
              />
            </Grid>
          );
        case 'consignor':
          return (
            <Grid item xs sm={4}>
              <TextField
                fullWidth
                label="Consignor"
                value={filters.consignor || ''}
                onChange={ev => {
                  setFilterValue({ consignor: ev.target.value });
                }}
              />
            </Grid>
          );

        case 'declarants':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!declarants.length}
                filterSelectedOptions
                fullWidth
                options={declarants}
                value={filters.declarants || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    declarants: value.length ? value : undefined,
                  });
                }}
                multiple
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Declarants"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          );
        case 'importer':
          return (
            <Grid item xs sm={4}>
              <TextField
                fullWidth
                label="Importer EORI"
                value={filters.importer || ''}
                onChange={ev => {
                  setFilterValue({ importer: ev.target.value });
                }}
              />
            </Grid>
          );

        case 'importers':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!importers.length}
                filterSelectedOptions
                fullWidth
                options={importers.map(c => c)}
                value={filters.importers || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    importers: value.length ? value : undefined,
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Importer EORI"
                    variant="outlined"
                  />
                )}
                multiple
              />
            </Grid>
          );
        case 'line_code':
          return (
            <Grid item xs sm={4}>
              <TextField
                fullWidth
                label="Line code (EPU/Line/Item)"
                value={filters.line_code || ''}
                onChange={ev => {
                  setFilterValue({ line_code: ev.target.value });
                }}
              />
            </Grid>
          );

        case 'origin_country':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!originCountries.length}
                filterSelectedOptions
                fullWidth
                options={originCountries.map(c => c.code)}
                getOptionLabel={(option: any) =>
                  (originCountries.find(c => c.code === option) || {}).name ||
                  ''
                }
                value={filters.origin_countries || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    origin_countries: value.length ? value : undefined,
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Origin Countries"
                    variant="outlined"
                  />
                )}
                multiple
              />
            </Grid>
          );
        case 'dispatch_country':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!dispatchCountries.length}
                filterSelectedOptions
                fullWidth
                options={dispatchCountries.map(c => c.code)}
                getOptionLabel={(option: any) =>
                  (dispatchCountries.find(c => c.code === option) || {}).name ||
                  ''
                }
                value={filters.dispatch_countries || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    dispatch_countries: value.length ? value : undefined,
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Dispatch Countries"
                    variant="outlined"
                  />
                )}
                multiple
              />
            </Grid>
          );
        case 'supplier':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!suppliers.length}
                filterSelectedOptions
                fullWidth
                options={suppliers.map(o => {
                  return o.id;
                })}
                value={filters.suppliers || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    suppliers: value.length ? value : undefined,
                  });
                }}
                getOptionLabel={(option: any) =>
                  (suppliers.find(c => c.id === option) || {}).name || ''
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Suppliers/Consignors"
                    variant="outlined"
                  />
                )}
                multiple
              />
            </Grid>
          );

        case 'preference':
          return (
            <Grid item xs={12} sm={4} key={'filter-preference'}>
              <Autocomplete
                disabled={!preferences.length}
                filterSelectedOptions
                fullWidth
                options={preferences}
                value={filters.preferences || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    preferences: value.length ? value : undefined,
                  });
                }}
                multiple
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Preferences"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          );
        case 'cpc':
          return (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disabled={!cpcs.length}
                filterSelectedOptions
                fullWidth
                options={cpcs}
                value={filters.cpcs || []}
                onChange={(ev, value) => {
                  setFilterValue({ cpcs: value.length ? value : undefined });
                }}
                multiple
                renderInput={params => (
                  <TextField {...params} label="CPCs" variant="outlined" />
                )}
              />
            </Grid>
          );
        case 'adjustment_type':
          return (
            <Grid item xs={12} sm={4} key="filter-adjustment-type">
              <Autocomplete
                disabled={!adjustment_types.length}
                filterSelectedOptions
                fullWidth
                options={adjustment_types}
                getOptionLabel={(option: any) => startCase(`${option}`)}
                value={filters.adjustment_type || []}
                onChange={(ev, value) => {
                  setFilterValue({
                    adjustment_type: value ? value : undefined,
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Adjustment type"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          );
        case 'divider':
          return (
            <Grid item xs={12} key={v4()}>
              <Divider variant={'middle'} />
            </Grid>
          );
        default:
          return <></>;
      }
    },
    [
      filters,
      originCountries,
      dispatchCountries,
      cpcs,
      preferences,
      adjustment_types,
      importers,
    ],
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {open ? (
          <Button
            variant={'text'}
            onClick={() => setOpen(false)}
            startIcon={<KeyboardArrowUp fontSize="large" />}
          >
            Hide filters
          </Button>
        ) : (
          <Button
            variant={'text'}
            onClick={() => setOpen(true)}
            startIcon={<KeyboardArrowDown fontSize="large" />}
          >
            Show filters
          </Button>
        )}
        {!!filtersActive && (
          <Button
            sx={{ marginLeft: 'auto' }}
            variant={'text'}
            onClick={() => clearFilters()}
            startIcon={<Close fontSize="large" />}
          >
            Clear filters
          </Button>
        )}
      </Box>

      <Box
        sx={{
          maxHeight: open ? boxHeight + 40 : '0px',
          overflow: 'hidden',
          transition: 'all 0.4s ease-out',
        }}
      >
        <Box sx={{ pt: 1 }} ref={ref}>
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <React.Fragment key={`${field}-${index}`}>
                {getField(field)}
              </React.Fragment>
            ))}
            <Grid item xs={12} sm={3}>
              <Button
                sx={{ width: '100%', height: '100%' }}
                disabled={isEqual(filters, savedFilters)}
                variant={'contained'}
                onClick={() => {
                  dispatch(actions.setFilters(filters));
                }}
              >
                Apply filter changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
