/**
 *
 * Dashboard
 *
 */
import * as React from 'react';
import { Report } from '../Report';
import { Box, Container, Grid, GridSize, Paper, Stack } from '@mui/material';
import { FilterBar } from '../FilterBar';
import { DashboardItem } from './DashboardItem';
import { Helmet } from 'react-helmet-async';

interface Props {}

export function Dashboard(props: Props) {
  const gridItems: Array<{
    id: string;
    minWidth: GridSize;
    minHeight?: number;
  }> = [
    /*
     */
    {
      id: 'duty-under-management',
      minWidth: 12,
      minHeight: 240,
    },
    {
      id: 'overview',
      minWidth: 12,
    },
    {
      id: 'supplier-analysis',
      minWidth: 4,
    },
    {
      id: 'supplier-anomalies',
      minWidth: 4,
    },
    {
      id: 'declarant-analysis',
      minWidth: 4,
    },
    {
      id: 'world-map',
      minWidth: 12,
    },
    {
      id: 'commcode-analysis',
      minWidth: 12,
    },
    {
      id: 'cpc-analysis',
      minWidth: 6,
    },

    {
      id: 'preference-analysis',
      minWidth: 6,
    },

    /*
     */
  ];
  return (
    <Container>
      <Helmet title={'Home Dashboard'}></Helmet>
      <Box>
        <FilterBar values={{}} />
        <Grid container spacing={2} sx={{ my: 0 }} alignItems="stretch">
          {gridItems.map(item => {
            return (
              <Grid
                item
                sx={{ mb: 0 }}
                xs={12}
                lg={item.minWidth}
                alignSelf={'stretch'}
                key={`report-${item.id}`}
              >
                <DashboardItem minHeight={item.minHeight}>
                  <Report id={item.id} type="abridged" />
                </DashboardItem>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}
