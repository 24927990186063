import {
  call,
  cancelled,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { apiRequest, setToken } from 'utils/request';
import { authProviderActions as actions } from '.';
import { loggingProviderActions } from '../../LoggingProvider/slice';

const ignoreActionTypes = () => [
  actions.userTokenRefreshRequest.type,
  actions.userLogoutRequest.type,
];

function monitorableAction(action: { type: string }) {
  return (
    action.type.includes('Request') &&
    ignoreActionTypes().every(fragment => !action.type.includes(fragment))
  );
}

function identifyAction(action) {
  return action.type.slice(0, -7);
}
function getSuccessType(action) {
  return `${identifyAction(action)}Success`;
}
function getFailType(action) {
  return `${identifyAction(action)}Failed`;
}

function* monitor(monitoredAction) {
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (fail && fail.payload && fail.payload.code === 403) {
    yield put(actions.userLogoutRequest());
  }

  if (fail && fail.payload && fail.payload.code === 401) {
    yield put(actions.userTokenRefreshRequest());
    const { success } = yield race({
      success: take(action => actions.userTokenRefreshSuccess.match(action)),
      fail: take(action => actions.userTokenRefreshFailed.match(action)),
    });

    if (success) {
      yield put(monitoredAction);
    } else {
      yield put(actions.userLogoutRequest());
    }
  }
}

function* login(action: {
  type: string;
  payload: { email: string; password: string; redirect?: string };
}) {
  const { email, password, redirect } = action.payload;
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/login',
      method: 'post',
      data: {
        email,
        password,
        redirect,
      },
    });

    if (payload.token) {
      yield call(setToken, payload.token);
    }
    yield put(actions.loadUserDataRequest());
    yield put(actions.userLoginSuccess({}));
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'auth',
        action: 'login-success',
        params: '',
      }),
    );
  } catch (error) {
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'auth',
        action: 'login-failed',
        params: '',
        data: { email },
      }),
    );
    yield put(actions.userLoginFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* autoLogin(action: { type: string; payload: { key: string } }) {
  const { key } = action.payload;
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/autologin',
      method: 'post',
      data: {
        key,
      },
    });

    if (payload.token) {
      yield call(setToken, payload.token);
    }
    yield put(actions.loadUserDataRequest());
    yield put(actions.autoLoginSuccess({}));
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'auth',
        action: 'login-success',
        params: '',
      }),
    );
  } catch (error) {
    yield put(actions.autoLoginFailed(error.payload));
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'auth',
        action: 'autologin-failed',
        params: '',
        data: { key },
      }),
    );
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* logout(action) {
  try {
    yield call(apiRequest, {
      url: 'auth/logout',
      method: 'post',
    });
    yield call(setToken, undefined);
    yield put(actions.userLogoutSuccess());
  } catch (error) {
    yield put(actions.userLogoutFailed(error.payload));
    yield call(setToken, undefined);
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* forgotPassword(action: { type: string; payload: { email: string } }) {
  try {
    const { email } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'auth/recovery',
      method: 'post',
      data: {
        email,
      },
    });

    yield put(actions.forgotPasswordSuccess(payload));
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* resetPassword(action: {
  type: string;
  payload: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
  };
}) {
  const { email, token, password, password_confirmation } = action.payload;
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/reset',
      method: 'post',
      data: {
        email,
        token,
        password,
        password_confirmation,
      },
    });
    if (payload.token) {
      yield call(setToken, payload.token);
      yield put(actions.loadUserDataRequest());
    }

    yield put(actions.resetPasswordSuccess(payload));
    yield put(
      loggingProviderActions.pushActionToQueue({
        category: 'auth',
        action: 'login-success',
        params: '',
      }),
    );
  } catch (error) {
    yield put(actions.resetPasswordFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadUserData(action) {
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/me',
      method: 'get',
    });
    yield put(actions.loadUserDataSuccess(payload));
  } catch (error) {
    yield put(actions.loadUserDataFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* tokenRefresh(action) {
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/refresh',
      method: 'post',
    });
    if (payload.token) {
      yield call(setToken, payload.token);
    }
    yield put(actions.userTokenRefreshSuccess());
  } catch (error) {
    yield put(actions.userTokenRefreshFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* authProviderSaga() {
  yield takeEvery(monitorableAction, monitor);
  yield takeLeading(actions.userLoginRequest.type, login);
  yield takeLeading(actions.autoLoginRequest.type, autoLogin);
  yield takeLeading(actions.userTokenRefreshRequest.type, tokenRefresh);
  yield takeLatest(actions.userLogoutRequest.type, logout);
  yield takeLatest(actions.loadUserDataRequest.type, loadUserData);
  yield takeLatest(actions.forgotPasswordRequest.type, forgotPassword);
  yield takeLatest(actions.resetPasswordRequest.type, resetPassword);
}
