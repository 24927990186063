/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useHasChanged } from 'utils/usePrevious';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { FilterableReportTable } from '../../../../components/FilterableReportTable';

interface Props extends ReportGraphicProps {}

export function DeclarantAnalysis(props: Props) {
  const { data } = props.graphic.data!;
  const { reportSettings } = props;

  const [selectedDeclarants, setSelectedDeclarants] = React.useState<string[]>(
    reportSettings.selectedAnomaly || [],
  );

  const formatCurrency = variableFormatCurrency(1000000000, data.currency);

  const selectedDeclarantsChanged = useHasChanged(selectedDeclarants);

  React.useEffect(() => {
    if (!selectedDeclarantsChanged) return;
    if (props.full) {
      props.onSettingsChange({ selectedDeclarants }, true);
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!props.full ? (
          <Box>
            <List>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.declarants',
                    '',
                  )} Declarants`}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.unique_entries',
                    '',
                  )} Unique Entries`}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={`${formatCurrency(
                    get(data, 'values.totals.estimated_declaration_costs', ''),
                  )} Estimated Declaration Costs`}
                />
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={`${get(
                    data,
                    'values.totals.anomalies',
                    '',
                  )} Anomalies`}
                />
              </ListItem>
            </List>
          </Box>
        ) : (
          <Box>
            <FilterableReportTable
              selectedItems={selectedDeclarants}
              onSelectionChange={selection => {
                setSelectedDeclarants(selection as Array<string>);
              }}
              formatCurrency={formatCurrency}
              identifier={'declarant_name'}
              columns={[
                {
                  label: 'Name',
                  field: 'declarant_name',
                  sort: 'asc',
                },
                {
                  label: 'Customs Value',
                  field: 'customs_value',
                  format: 'currency',
                },
                {
                  label: 'Duty Paid',
                  field: 'duty_paid',
                  format: 'currency',
                },
                {
                  label: 'VAT Paid',
                  field: 'vat_paid',
                  format: 'currency',
                },
                {
                  label: 'Unique Entries',
                  field: 'unique_entries',
                  format: 'number',
                },
                {
                  label: 'Anomalies',
                  field: 'anomalies',
                  format: 'number',
                },
                {
                  label: 'Anomaly %',
                  field: 'anomaly_percentage',
                  format: 'percentage',
                },
                {
                  label: 'Est Decl Costs',
                  field: 'estimated_declaration_costs',
                  format: 'currency',
                },
              ]}
              data={data.values.all_declarants || []}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
