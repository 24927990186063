/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { get, isEqual } from 'lodash';
import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useHasChanged } from 'utils/usePrevious';
import { ReportGraphicProps } from '../index';
import { variableFormatCurrency } from '../../../../../utils/formatCurrency';
import { useMemo } from 'react';
import key from 'weak-key';

interface Props extends ReportGraphicProps {}

export function SupplierAnomalies(props: Props) {
  const { data } = props.graphic.data!;
  const { reportSettings } = props;

  const [selectedType, setSelectedType] = React.useState<
    'freight' | 'mass' | 'currency' | null
  >(props.full ? reportSettings.selectedType : null);

  const [selectedAnomaly, setSelectedAnomaly] = React.useState<string | null>(
    reportSettings.selectedAnomaly || null,
  );

  const formatCurrency = variableFormatCurrency(10000000000, data.currency);

  const freight: {
    supplier_count: number;
    anomaly_count: number;
    data: Array<{
      supplier: string;
      supplier_id: number;
      norm: 'low' | 'zero' | 'high';
      spread: [number, number, number];
      anomalies: { low?: number; high?: number; zero?: number };
    }>;
  } = get(data, 'values.freight');

  const mass: {
    supplier_count: number;
    anomaly_count: number;
    data: Array<{
      supplier: string;
      supplier_id: number;
      large_mass_count: number;
      small_mass_count: number;
      masses_count: number;
      median_mass: number;
    }>;
  } = get(data, 'values.mass');

  const currency: {
    supplier_count: number;
    anomaly_count: number;
    data: Array<{
      supplier: string;
      supplier_id: number;
      norm_code: string;
      norm_counter: string;
      anomalies: [{ code: string; counter: number; potential_value: number }];
    }>;
  } = get(data, 'values.currency');

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }

    if (!props.full) return;

    if (
      !isEqual(props.graphic.data.reportSettings.selectedType, selectedType)
    ) {
      setSelectedType(props.graphic.data.reportSettings.selectedType);
    }
  }, [props.graphic.data]);

  React.useEffect(() => {
    if (!props.full && selectedType) {
      props.loadFullReport({
        selectedType,
        selectedAnomaly,
      });
    }
  }, [selectedType]);

  const selectedTypeChanged = useHasChanged(selectedType);
  const selectedAnomalyChanged = useHasChanged(selectedAnomaly);

  const displayMass = (mass: number) => {
    if (mass > 1000) {
      return `${(mass / 1000).toFixed(2)}t`;
    }
    return `${mass.toFixed(2)}kg`;
  };

  React.useEffect(() => {
    if (!selectedAnomalyChanged && !selectedTypeChanged) return;
    if (props.full) {
      props.onSettingsChange({ selectedType, selectedAnomaly }, true);
    }
  });

  const makeAnomalyKey = (type, supplier) => {
    return `${type}|${supplier}`;
  };

  const onTypeClick = (type: 'freight' | 'mass' | 'currency') => {
    if (selectedType === type) {
      setSelectedType(null);
    } else {
      setSelectedType(type);
    }
    setSelectedAnomaly(null);
  };

  const onAnomalyClick = (
    type: 'freight' | 'mass' | 'currency',
    supplier: string | number,
  ) => {
    const key = makeAnomalyKey(type, supplier);
    if (selectedAnomaly === key) {
      setSelectedAnomaly(null);
    } else {
      setSelectedAnomaly(key);
    }
  };

  const items = useMemo(() => {
    switch (selectedType) {
      case 'freight':
        return freight.data.map(item => ({
          primary: item.supplier,
          secondary: (
            <>
              <b>Norm: {item.norm === 'zero' ? 'Inclusive' : 'Exclusive'}</b> /{' '}
              {Object.keys(item.anomalies)
                .filter(type => !!item.anomalies[type])
                .map(
                  type =>
                    `${item.anomalies[type]} ${
                      type === 'zero' ? 'inclusive' : 'exclusive'
                    } items`,
                )
                .join(' / ')}
            </>
          ),
          tertiary: item.supplier_id,
        }));
      case 'mass':
        return mass.data.map(item => ({
          primary: item.supplier,
          secondary: (
            <>
              Median mass: {displayMass(+item.median_mass)} ({item.masses_count}{' '}
              total items) /{' '}
              {['large', 'small']
                .filter(size => item[`${size}_mass_count`])
                .map(size => `${item[`${size}_mass_count`]} ${size}`)
                .join(' / ')}
            </>
          ),
          tertiary: item.supplier_id,
        }));
      case 'currency':
        return currency.data.map(item => ({
          primary: item.supplier,
          secondary: (
            <>
              <b>
                Norm: {item.norm_code} used {item.norm_counter} times
              </b>
              <br />
              {item.anomalies.map((a, i) => (
                <Box key={key(a)}>
                  {a.code} used {a.counter} times (
                  {formatCurrency(a.potential_value)} )
                </Box>
              ))}
            </>
          ),
          tertiary: item.supplier_id,
        }));
      default:
        return [];
    }
  }, [selectedType, currency, mass, freight]);

  return props.full ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <List
            disablePadding
            sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}
          >
            {!!freight.anomaly_count && (
              <ListItemButton
                sx={{ flex: '0 0 33.33%' }}
                divider
                selected={selectedType === 'freight'}
                onClick={() => onTypeClick('freight')}
              >
                <Box>
                  <Typography variant={'h5'}>Freight anomalies</Typography>
                  <Typography variant={'body1'}>
                    {freight.supplier_count} suppliers {freight.anomaly_count}{' '}
                    anomalies
                  </Typography>
                </Box>
              </ListItemButton>
            )}
            {!!mass.anomaly_count && (
              <ListItemButton
                sx={{ flex: '0 0 33.33%' }}
                divider
                selected={selectedType === 'mass'}
                onClick={() => onTypeClick('mass')}
              >
                <Box>
                  <Typography variant={'h5'}>Mass anomalies</Typography>
                  <Typography variant={'body1'}>
                    {mass.supplier_count} suppliers, {mass.anomaly_count}{' '}
                    anomalies
                  </Typography>
                </Box>
              </ListItemButton>
            )}
            {!!currency.anomaly_count && (
              <ListItemButton
                sx={{ flex: '0 0 33.33%' }}
                divider
                selected={selectedType === 'currency'}
                onClick={() => onTypeClick('currency')}
              >
                <Box>
                  <Typography variant={'h5'}>Currency anomalies</Typography>
                  <Typography variant={'body1'}>
                    {currency.supplier_count} suppliers,{' '}
                    {currency.anomaly_count} anomalies
                  </Typography>
                </Box>
              </ListItemButton>
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: 350,
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'auto',
            }}
          >
            <Grid spacing={0} container justifyContent={'flex-start'}>
              {items.map(item => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={makeAnomalyKey(selectedType, item.tertiary)}
                >
                  <ListItemButton
                    selected={
                      selectedAnomaly ===
                      makeAnomalyKey(selectedType, item.tertiary)
                    }
                    onClick={() => onAnomalyClick(selectedType!, item.tertiary)}
                  >
                    <ListItemIcon>
                      {selectedAnomaly ===
                      makeAnomalyKey(selectedType, item.tertiary) ? (
                        <RadioButtonChecked />
                      ) : (
                        <RadioButtonUnchecked />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={item.primary}
                      secondary={item.secondary}
                    />
                  </ListItemButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <List
          disablePadding
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {!!freight.anomaly_count && (
            <ListItemButton
              sx={{ flex: '0 0 33.33%' }}
              divider
              selected={selectedType === 'freight'}
              onClick={() => onTypeClick('freight')}
            >
              <Box>
                <Typography variant={'h5'}>Freight anomalies</Typography>
                <Typography variant={'h6'}>
                  {freight.supplier_count} suppliers
                </Typography>
                <Typography variant={'h6'}>
                  {freight.anomaly_count} anomalies
                </Typography>
              </Box>
            </ListItemButton>
          )}
          {!!mass.anomaly_count && (
            <ListItemButton
              sx={{ flex: '0 0 33.33%' }}
              divider
              selected={selectedType === 'mass'}
              onClick={() => onTypeClick('mass')}
            >
              <Box>
                <Typography variant={'h5'}>Mass anomalies</Typography>
                <Typography variant={'h6'}>
                  {mass.supplier_count} suppliers
                </Typography>
                <Typography variant={'h6'}>
                  {mass.anomaly_count} anomalies
                </Typography>
              </Box>
            </ListItemButton>
          )}
          {!!currency.anomaly_count && (
            <ListItemButton
              sx={{ flex: '0 0 33.33%' }}
              divider
              selected={selectedType === 'currency'}
              onClick={() => onTypeClick('currency')}
            >
              <Box>
                <Typography variant={'h5'}>Currency anomalies</Typography>
                <Typography variant={'h6'}>
                  {currency.supplier_count} suppliers
                </Typography>
                <Typography variant={'h6'}>
                  {currency.anomaly_count} anomalies
                </Typography>
              </Box>
            </ListItemButton>
          )}
        </List>
      </Grid>
    </Grid>
  );
}
