/**
 *
 * ImportDataForm
 *
 */
import * as React from 'react';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LineImport, LineItem } from '../slice/types';
import { LookupActionSelect } from './LookupActionSelect';
import { GridDensityTypes, DataGridPro } from '@mui/x-data-grid-pro';
import { ExpandMore } from '@mui/icons-material';

interface Props {
  line_import: LineImport;
  confirmRequest: Function;
  rollbackRequest: Function;
}

interface Action {
  type: 'create-new' | 'use-existing' | 'replace-existing';
  code: string;
  old_value?: string;
  new_value: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentItem: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  gridRoot: {
    borderWidth: 0,
    borderTopWidth: 1,
  },
}));

export function ConfirmImportForm(props: Props) {
  const classes = useStyles();
  const [lookups, setLookups]: [
    lookups: Array<{
      count: number;
      action?: Action;
      lookupcode: string;
      lookup: {
        value: string;
        key: string;
        lookup_matches: Array<{
          code: string;
          proximity: number;
          usage_count: number;
        }>;
      };
    }>,
    setLookups: Function,
  ] = useState([]);

  React.useEffect(() => {
    setLookups(props.line_import.lookups);
  }, [setLookups, props.line_import]);

  const awaitingActions = lookups.reduce((acc, cur) => {
    if (!cur.action) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const setLookupAction = (li: number, la: Action | undefined) => {
    console.log(li, la);
    setLookups([
      ...lookups.slice(0, li),
      { ...lookups[li], action: la },
      ...lookups.slice(li + 1),
    ]);
  };

  const proceed = () => {
    if (awaitingActions) return;
    // set step to next and show confirmation message
  };

  const confirm = () => {
    if (awaitingActions) return;

    props.confirmRequest(lookups);
  };

  const rollback = () => {
    props.rollbackRequest();
  };

  return (
    <Box>
      <Typography variant="h4" color={awaitingActions ? 'error' : 'primary'}>
        Review Data{' '}
        {!!awaitingActions && (
          <span> - awaiting actions ({awaitingActions})</span>
        )}
      </Typography>

      {props.line_import.line_items.length ? (
        <>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Review your import here. When you are ready to include the data in
            your CAT account, click confirm.
          </Typography>

          <Box sx={{ my: 4 }}>
            <Accordion
              sx={{ backgroundColor: 'grey.A100' }}
              square
              elevation={0}
              defaultExpanded
              TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={'h6'}>
                  Importing {props.line_import.line_item_count} lines of data
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Box sx={{ height: 300 }}>
                  <DataGridPro
                    className={classes.gridRoot}
                    hideFooter
                    disableSelectionOnClick
                    columnBuffer={6}
                    density={GridDensityTypes.Compact}
                    disableColumnReorder
                    columns={Object.keys(
                      props.line_import.line_items[0].current_state || {},
                    ).map(key => ({
                      field: key,
                      headerName: key,
                    }))}
                    rows={props.line_import.line_items.map((li: LineItem) => ({
                      id: li.id,
                      ...li.current_state,
                    }))}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          {!!lookups.length && (
            <Box sx={{ my: 4 }}>
              <Accordion
                sx={{ backgroundColor: 'grey.A100' }}
                square
                elevation={0}
                defaultExpanded
                TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h6'}>
                    Potential duplicate labels detected
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field / Value</TableCell>
                        <TableCell>Occurrences</TableCell>
                        <TableCell>Suggested Matches</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lookups.map((lm, li) => {
                        return (
                          <TableRow key={`lm-${lm.lookupcode}`}>
                            <TableCell>
                              {lm.lookup.key} / {lm.lookup.value}
                            </TableCell>
                            <TableCell>{lm.count}</TableCell>
                            <TableCell>
                              {lm.lookup.lookup_matches
                                .map(match => match.code)
                                .join(', ')}
                            </TableCell>
                            <TableCell>
                              <LookupActionSelect
                                lm={lm}
                                onChange={(action: Action) => {
                                  setLookupAction(li, action);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

          {!!props.line_import.errors.length && (
            <Box sx={{ my: 4 }}>
              <Accordion
                TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant={'h6'}>
                    Potential errors / warnings detected
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field / Value</TableCell>
                        <TableCell>Occurrences</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.line_import.errors.map(error => {
                        return (
                          <TableRow key={`error-${error.errorcode}`}>
                            <TableCell>
                              {error.error.key} /{' '}
                              {error.error.value || '[Blank]'}
                            </TableCell>
                            <TableCell>{error.count}</TableCell>

                            <TableCell>{error.error.message}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={rollback}
              sx={{ mr: 2 }}
            >
              Rollback
            </Button>
            <Button
              disabled={!!awaitingActions}
              variant="contained"
              color="primary"
              onClick={confirm}
            >
              Confirm
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ py: 4 }}>
            <Typography variant={'body1'}>
              The uploaded data file has no suitable line items to import. This
              is very likely because the data has already been imported. Please
              review the data file before clicking the rollback button.
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={rollback}
              sx={{ mr: 2 }}
            >
              Rollback Import
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
