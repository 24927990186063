/**
 *
 * FlagIcon
 *
 */
import * as React from 'react';
import 'flagpack/dist/flagpack.css';
import clsx from 'clsx';

interface Props {
  code: string;
  size?: 'sm' | 'md' | 'lg';
}

export function FlagIcon(props: Props) {
  const size = props.size || 'md';
  const getFontSize = () => {
    switch (size) {
      case 'lg':
        return '2em';
      case 'sm':
        return '1em';
    }
    return '1.5em';
  };

  return (
    <span
      className={clsx('fp', 'fp-square', (props.code || '').toLowerCase())}
    />
  );

  /*
  if (hasFlag(props.code)) {
    const Flag = Flags[props.code];
    return (
      <Box sx={{ fontSize: getFontSize() }} component={'span'}>
        <Flag
          style={{
            width: '1em',
          }}
        />
      </Box>
    );
  }
  return null;

   */
}
