/**
 *
 * LoadingIndicator
 *
 */
import * as React from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

interface Props extends BoxProps {
  message?: React.ReactNode | string;
  minHeight?: number | string;
  size?: number;
  thickness?: number;
  ProgressProps?: CircularProgressProps;
}

export function LoadingIndicator(props: Props) {
  const {
    ProgressProps,
    message,
    size = 60,
    thickness = 8,
    minHeight,
    sx,
    ...rProps
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        py: 8,
        minHeight: minHeight || 300,
        ...sx,
      }}
      {...rProps}
    >
      {!!message && <Box sx={{ mb: 6 }}>{message}</Box>}
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          color="secondary"
          thickness={thickness}
          size={size}
          {...ProgressProps}
          variant={'determinate'}
          value={100}
        />
        <CircularProgress
          style={{ position: 'absolute', left: 0 }}
          color="primary"
          thickness={thickness}
          size={size}
          {...ProgressProps}
        />
      </Box>
    </Box>
  );
}
