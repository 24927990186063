import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { apiCallInitialState } from 'types/ApiCall';
import { filterSliceSaga } from './saga';
import { FilterSliceState } from './types';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: FilterSliceState = {
  filters: {},
  loadAvailableFieldFilters: {
    ...apiCallInitialState,
    data: {
      preferences: [],
      originCountries: [],
      dispatchCountries: [],
      cpcs: [],
      suppliers: [],
      adjustment_types: [],
      importers: [],
      branch_codes: [],
      declarants: [],
    },
  },
};

const slice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    loadAvailableFieldFiltersRequest(state, action: PayloadAction<void>) {
      state.loadAvailableFieldFilters.loading = true;
      state.loadAvailableFieldFilters.error = undefined;
    },
    loadAvailableFieldFiltersSuccess(state, action: PayloadAction<any>) {
      state.loadAvailableFieldFilters.loading = false;
      state.loadAvailableFieldFilters.data = action.payload;
    },
    loadAvailableFieldFiltersFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadAvailableFieldFilters.loading = false;
      state.loadAvailableFieldFilters.error = action.payload;
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
  },
});

export const { actions: filterSliceActions } = slice;

export const useFilterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: filterSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFilterSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
