/**
 *
 * ImportMassAdjustments
 *
 */
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useEffectOnce } from '../../../utils/useEffectOnce';
import { useImportMassAdjustmentsSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectImportMassAdjustments } from './slice/selectors';
import { FileUploadFormElement } from '../../components/FileUploadFormElement';
import { getBase64 } from '../../../utils/saga/createFileUploadChannel';
import { useSnackbar } from 'notistack';
import { useHasChanged } from '../../../utils/usePrevious';
import { useEffect, useMemo, useState } from 'react';
import {
  DataGridPro,
  gridClasses,
  GridEnrichedColDef,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { IAdjustment, IAdjustmentAction } from './slice/types';
import { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { Box, Paper } from '@mui/material';
import { formatParsedISO } from '../../../utils/dates';
import { formatCurrency } from '../../../utils/formatCurrency';

interface Props {
  adjustments: Array<IAdjustment>;
}

export function MassAdjustmentPreview(props: Props) {
  const { adjustments } = props;
  const ref = useGridApiRef();

  const formatRowCurrency = (params: GridValueFormatterParams) => {
    return (+(params.value || 0)).toFixed(2);
  };

  // convert actions into a set of table rows for preview
  const rows = useMemo(() => {
    try {
      return adjustments.reduce(
        (acc: Array<{ [key: string]: any }>, adjustment) => {
          const row: { [key: string]: any } = { ...adjustment.line };
          row.type = 'base';
          row.id = `${adjustment.id}-base`;
          acc.push(row);
          (adjustment.actions || []).forEach(action => {
            const row: { [key: string]: any } = { ...adjustment.line };

            row.type = action.type;
            row.item_no_suffix =
              (row.item_no_suffix || '') + (action.suffix || '');

            if (action.fields) {
              row.changes = (action.fields || []).map(f => f.field);
              row.id = `${adjustment.id}${action.suffix || ''}`;

              (action.fields || []).forEach(f => (row[f.field] = f.to));

              acc.push(row);
            }
          });

          return acc;
        },
        [],
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }, [adjustments]);

  const columns: GridColumns = useMemo(
    () =>
      [
        {
          field: 'entry_date',
          type: 'date',
          valueFormatter: params => formatParsedISO(params.value),
        },
        {
          field: 'epu',
        },
        {
          field: 'entry_no',
        },
        {
          field: 'item_no',
          type: 'number',
        },
        {
          field: 'item_no_suffix',
        },
        {
          field: 'invoice_currency',
        },
        {
          field: 'item_price',
          type: 'number',
          valueFormatter: formatRowCurrency,
        },
        {
          field: 'item_customs_value',
          type: 'number',
          valueFormatter: formatRowCurrency,
        },
        {
          field: 'item_customs_duty',
          type: 'number',
          valueFormatter: formatRowCurrency,
        },
        {
          field: 'item_vat_value',
          type: 'number',
          valueFormatter: formatRowCurrency,
        },
        {
          field: 'item_vat',
          type: 'number',
          valueFormatter: formatRowCurrency,
        },
        {
          field: 'item_net_mass',
          type: 'number',
        },
        {
          field: 'commodity_code',
        },
        {
          field: 'ecsu_1',
        },
        {
          field: 'ecsu_2',
        },
        {
          field: 'preference',
        },
        {
          field: 'customs_procedure_code',
        },
      ]
        .map(
          c =>
            ({
              ...c,
              sortable: false,
              hideable: false,
              pinnable: false,
              disableColumnMenu: true,
            } as GridEnrichedColDef),
        )
        .filter(c => c),
    [adjustments],
  );

  return (
    <>
      <Box
        sx={{
          minHeight: 350,
          maxHeight: 'calc(80vh - 200px)',
          height: rows.length * 50,
        }}
      >
        <DataGridPro
          apiRef={ref}
          sx={{
            [`& .${gridClasses.row}.type-base`]: {
              backgroundColor: 'grey.200',
            },
            [`& .${gridClasses.row}.type-change`]: {
              backgroundColor: 'white',
            },
            [`& .${gridClasses.row}.type-new`]: {
              backgroundColor: 'white',
            },
            [`& .${gridClasses.cell}.changed`]: {
              outlineColor: 'grey.100',
              outlineWidth: 1,
              outlineStyle: 'dotted',
              fontWeight: 600,
              backgroundColor: 'warning.main',
              color: 'white',
            },
          }}
          columns={columns}
          rows={rows}
          getRowClassName={params => `type-${params.row.type}`}
          getCellClassName={o => {
            if (
              o.row.type !== 'base' &&
              (o.row.changes || []).indexOf(o.field) > -1
            ) {
              return 'changed';
            }
            return '';
          }}
          density={'compact'}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}
