/**
 *
 * LineAdjustmentManager
 *
 */
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLineFieldSlice } from './slice';
import {
  selectAdjustedItems,
  selectAdjustmentIntent,
  selectConfirmAdjustment,
} from './slice/selectors';
import { Case, Default, Switch } from '../../components/Switch';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { LineAdjustmentForm } from './LineAdjustmentForm';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';

interface Props {
  children: React.ReactChild;
}

export function LineAdjustmentManager(props: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { actions } = useLineFieldSlice();
  const adjustmentIntent = useSelector(selectAdjustmentIntent);
  const adjustedItems = useSelector(selectAdjustedItems);
  const confirmAdjustment = useSelector(selectConfirmAdjustment);

  React.useEffect(() => {
    if (adjustmentIntent.data || adjustmentIntent.loading) {
      setOpen(true);
    }
  }, [adjustmentIntent]);

  React.useEffect(() => {
    if (!open) dispatch(actions.adjustmentManagerMount());
  }, [open]);

  React.useEffect(() => {
    if (confirmAdjustment.error) {
      enqueueSnackbar(confirmAdjustment.error.message, {
        variant: 'error',
      });
    }
  }, [confirmAdjustment.error, enqueueSnackbar, closeSnackbar]);

  React.useEffect(() => {
    if (confirmAdjustment.data) {
      enqueueSnackbar('Line data adjustment completed successfully.', {
        variant: 'success',
      });
      setOpen(false);
    }
  }, [confirmAdjustment.data, enqueueSnackbar, closeSnackbar]);

  let component;
  switch (true) {
    case confirmAdjustment.data:
      component = <Box>Adjustment completed successfully!</Box>;
      break;
    case !!adjustmentIntent.data:
      component = (
        <LineAdjustmentForm
          adjustmentIntent={adjustmentIntent.data!}
          adjustedItems={adjustedItems.data}
          getAdjustedItems={newValue =>
            dispatch(
              actions.adjustedItemsRequest({
                key: adjustmentIntent.data!.key,
                change: adjustmentIntent.data?.change,
                update: newValue,
              }),
            )
          }
          adjustedItemsLoading={adjustedItems.loading}
          confirmAdjustment={calculatedValues => {
            dispatch(
              actions.confirmAdjustmentRequest({
                key: adjustedItems.data!.key,
                change: adjustmentIntent.data!.change,
                values: calculatedValues,
              }),
            );
          }}
          confirmAdjustmentLoading={confirmAdjustment.loading}
        />
      );
      break;
    default:
      component = <LoadingIndicator minHeight={150} />;
  }

  return (
    <>
      {React.Children.only(props.children)}
      <>
        <Dialog
          disableEscapeKeyDown
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') setOpen(false);
          }}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          maxWidth="sm"
          fullScreen={fullScreen}
        >
          <Helmet title={'Line Item Adjustment'}></Helmet>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DialogTitle id="responsive-dialog-title">
              {'Line Item Adjustment'}
            </DialogTitle>
            <IconButton
              onClick={() => setOpen(false)}
              color="primary"
              sx={{ marginLeft: 'auto' }}
              size="large"
            >
              <Close />
            </IconButton>
          </Box>

          <DialogContent sx={{ borderTop: 1, borderTopColor: grey['300'] }}>
            <Box>{component}</Box>
          </DialogContent>
        </Dialog>
      </>
    </>
  );
}
