import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import key from 'weak-key';
import { PersonAdd, Search } from '@mui/icons-material';
import { ManagementTable } from '../../components/ManagementTable';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import * as React from 'react';
import { useUsersSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from './slice/selectors';
import { useLocation, useRouteMatch } from 'react-router';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { useDebounce } from '../../../utils/useDebounce';

export function List() {
  const match = useRouteMatch();
  const location = useLocation();
  const { actions } = useUsersSlice();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const [pageSize, setPageSize] = React.useState<number>(100);
  const [page, setPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<
    [field: string, direction?: GridSortDirection] | undefined
  >(undefined);
  const [filters, setFilters] = React.useState<{ [key: string]: any }>({
    customer_id: get(location, 'state.customer_id'),
    name: '',
  });

  const debouncedFilters = useDebounce(filters, 700);
  React.useEffect(() => {
    loadUsers();
  }, [dispatch, debouncedFilters]);

  const loadUsers = () => {
    dispatch(
      actions.loadUsersRequest({
        ...filters,
        page: { number: page, size: pageSize },
        sort: sort,
      }),
    );
  };
  return (
    <Box sx={{ my: 4 }}>
      <Grid
        container
        spacing={2}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Grid item>
          <Typography variant="h1" color={'primary'}>
            All users
          </Typography>
        </Grid>
        <Grid item sm>
          <Paper>
            <TextField
              placeholder={'Search by user name'}
              InputProps={{
                sx: { p: 1 },
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={filters.name}
              onChange={ev => setFilters({ ...filters, name: ev.target.value })}
              variant={'standard'}
            />
          </Paper>
        </Grid>{' '}
        <Grid item sm>
          <Paper>
            <Select
              value={filters.customer_id || ''}
              label={'Filter by customer'}
              fullWidth
              variant={'standard'}
              onChange={ev =>
                setFilters({ ...filters, customer_id: ev.target.value })
              }
              sx={{ py: 1, px: 2 }}
              displayEmpty
            >
              <MenuItem value={''}>--- Filter by customer ---</MenuItem>
              {(users.customers || []).map(customer => (
                <MenuItem key={key(customer)} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </Paper>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            size={'large'}
            startIcon={<PersonAdd color={'secondary'} />}
            component={Link}
            to={`${match.path}/new`}
          >
            Add new user
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ my: 2 }}>
        <ManagementTable
          rows={users.data}
          columns={[
            {
              value: v => `${v.first_name} ${v.last_name}`,
              label: 'Name',
            },
            {
              value: 'email',
              label: 'Email',
            },
            {
              value: v => {
                if (v.role_name === 'Administrator') {
                  return v.role_name;
                }
                return `${v.role_name} user${
                  !!v.customer_name ? ` (${v.customer_name})` : ''
                }`;
              },
              label: 'Role',
            },
            {
              value: v => (v.account_enabled ? 'Active' : 'Disabled'),
              label: 'Status',
            },
            {
              classes: 'align-right',
              value: v => {
                return (
                  <Button
                    sx={{ my: 1 }}
                    component={Link}
                    to={`${match.path}/${v.id}`}
                    variant={'contained'}
                    color={'primary'}
                  >
                    Edit User
                  </Button>
                );
              },
            },
          ]}
          onClick={() => {}}
          page={1}
          onChangePage={() => {}}
          rowsPerPage={100}
          rowsPerPageOptions={[100]}
          count={5}
          loading={users.loading}
          loadingComponent={
            <LoadingIndicator minHeight={300} message={'Loading data'} />
          }
        />
      </Paper>
    </Box>
  );
}
