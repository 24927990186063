import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { amendedItemsActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';

function* loadAdjustments(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/adjustments`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.loadAdjustmentsSuccess(payload));
  } catch (error) {
    yield put(actions.loadAdjustmentsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* revertAdjustments(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/revert-adjustments`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.revertAdjustmentsSuccess(payload));
  } catch (error) {
    yield put(actions.revertAdjustmentsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* createAdjustmentReport(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/create-adjustment-report`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.createAdjustmentReportSuccess(payload));
  } catch (error) {
    yield put(actions.createAdjustmentReportFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* amendedItemsSaga() {
  yield takeLatest(actions.loadAdjustmentsRequest.type, loadAdjustments);
  yield takeLatest(actions.revertAdjustmentsRequest.type, revertAdjustments);
  yield takeLatest(
    actions.createAdjustmentReportRequest.type,
    createAdjustmentReport,
  );
}
