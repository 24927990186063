import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { apiCallInitialState, ResultMetaData } from 'types/ApiCall';
import { usersSliceSaga } from './saga';
import { User, UsersSliceState } from './types';
import { ResponseErrorPayload } from '../../../../utils/request';
import { Customer } from '../../CustomerSelector/slice/types';

export const initialState: UsersSliceState = {
  users: {
    loading: false,
    data: [],
    customers: [],
  },
  save: apiCallInitialState,
  delete: apiCallInitialState,
  user: {
    loading: false,
    data: undefined,
    customers: [],
  },
};

const slice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    loadUsersRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.users.loading = true;
      state.users.error = undefined;
    },
    loadUsersSuccess(
      state,
      action: PayloadAction<{
        data: Array<User>;
        meta: ResultMetaData;
        customers: { data: Array<Customer> };
      }>,
    ) {
      state.users.loading = false;
      state.users.data = action.payload.data;
      state.users.meta = action.payload.meta;
      state.users.customers = action.payload.customers.data;
    },
    loadUsersFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.users.loading = false;
      state.users.error = action.payload;
    },
    loadUserRequest(state, action: PayloadAction<{ id: number | 'new' }>) {
      state.user.loading = true;
      state.user.error = undefined;
    },
    loadUserUnmount(state, action: PayloadAction<void>) {
      state.user.loading = false;
      state.user.error = undefined;
      state.user.data = undefined;
      state.save.loading = false;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    loadUserSuccess(
      state,
      action: PayloadAction<{
        data: User;
        customers: { data: Array<Customer> };
      }>,
    ) {
      state.user.loading = false;
      state.user.data = action.payload.data;
      state.user.customers = action.payload.customers.data;
    },
    loadUserFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.user.loading = false;
      state.user.error = action.payload;
    },
    deleteUserRequest(state, action: PayloadAction<{ id: number }>) {
      state.delete.loading = true;
      state.delete.error = undefined;
    },
    deleteUserSuccess(
      state,
      action: PayloadAction<{
        status: 'success';
      }>,
    ) {
      state.delete.loading = false;
      state.delete.data = action.payload.status;
    },
    deleteUserFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
    saveUserRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.save.loading = true;
      state.save.error = undefined;
    },
    saveUserUnmount(state, action: PayloadAction<void>) {
      state.save.loading = true;
      state.save.error = undefined;
      state.save.data = undefined;
    },
    saveUserSuccess(state, action: PayloadAction<{ data: User }>) {
      state.save.loading = false;
      state.save.data = action.payload.data;
    },
    saveUserFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.save.loading = false;
      state.save.error = action.payload;
    },
  },
});

export const { actions: usersSliceActions } = slice;

export const useUsersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: usersSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useUsersSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
