import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { lineFieldActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';

function* adjustmentIntent(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/adjustment-intent`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.adjustmentIntentSuccess(payload));
  } catch (error) {
    yield put(actions.adjustmentIntentFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* adjustedItems(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/adjusted-items`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.adjustedItemsSuccess(payload));
  } catch (error) {
    yield put(actions.adjustedItemsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* confirmAdjustment(action) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `line-data/confirm-adjustment`,
      method: 'post',
      data: {
        ...action.payload,
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.confirmAdjustmentSuccess(payload));
  } catch (error) {
    yield put(actions.confirmAdjustmentFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* lineFieldSaga() {
  yield takeLatest(actions.adjustmentIntentRequest.type, adjustmentIntent);
  yield takeLatest(actions.adjustedItemsRequest.type, adjustedItems);
  yield takeLatest(actions.confirmAdjustmentRequest.type, confirmAdjustment);
}
