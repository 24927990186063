import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.adjustmentReports || initialState;

export const selectAdjustmentReports = createSelector(
  [selectSlice],
  state => state,
);
export const selectLoadAdjustmentReports = createSelector(
  [selectSlice],
  state => state.loadAdjustmentReports,
);
export const selectLoadAdjustmentReport = createSelector(
  [selectSlice],
  state => state.loadAdjustmentReport,
);
export const selectUpdateAdjustmentReport = createSelector(
  [selectSlice],
  state => state.updateAdjustmentReport,
);
