/**
 *
 * ReportGraphic
 *
 */
import * as React from 'react';
import { useRef } from 'react';
import { red } from '@mui/material/colors';
import { get, isEqual } from 'lodash';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HighchartsReact from 'highcharts-react-official';
import { usePrevious } from 'utils/usePrevious';
import Highcharts, { SeriesPieOptions } from 'highcharts';
import { variableFormatCurrency } from 'utils/formatCurrency';
import { ManagementTable } from '../../../../components/ManagementTable';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { ReportGraphicProps } from '../index';

interface Props extends ReportGraphicProps {}

const useStyles = makeStyles((theme: Theme) => ({
  tableContainerClass: {},
  selectedColumn: {
    backgroundColor: theme.palette.grey['200'],
    '.Mui-selected &': {
      backgroundColor: 'red',
    },
  },
  amendedCell: {
    backgroundColor: theme.palette.secondary.dark,
  },
  pendingApprovalCell: {
    backgroundColor: theme.palette.secondary.dark,
  },
  root: {
    border: 'none',
    '& .MuiDataGrid-row': {
      '&.commCodeSelected': {
        backgroundColor: theme.palette.grey['200'],
      },
    },
  },
}));

export function CPCAnalysis(props: Props) {
  const { data } = props.graphic.data!;
  const { reportSettings } = props;
  const theme = useTheme();

  const classes = useStyles();
  const ref = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  const [options, setOptions] = React.useState<
    Highcharts.Options | undefined
  >();

  const [viewMode, setViewMode] = React.useState<keyof typeof viewModes>(
    reportSettings.viewMode || 'top5',
  );

  const [metric, setMetric] = React.useState<keyof typeof metricOptions>(
    reportSettings.metric || 'customsDutySaved',
  );

  const [cpc, setCpc] = React.useState<string | null>(
    reportSettings.cpc || undefined,
  );

  const [commCode, setCommCode] = React.useState<string | null | undefined>(
    reportSettings.commCode || undefined,
  );

  const [hiddenPoints, setHiddenPoints] = React.useState<string[]>(
    reportSettings.hiddenPoints || [],
  );

  React.useEffect(() => {
    if (!props.graphic.data) {
      return;
    }

    if (!isEqual(props.graphic.data.reportSettings.cpc, cpc)) {
      setCpc(props.graphic.data.reportSettings.cpc);
    }
  }, [props.graphic.data]);

  React.useEffect(() => {
    if (!props.graphic.loading) setOptions(getOptions());
  }, [props.graphic.loading]);

  React.useEffect(() => {
    if (props.graphic.data) {
      props.onSettingsChange({
        viewMode,
        metric,
        cpc,
        commCode,
        hiddenPoints,
      });
    }
    setOptions(getOptions());
  }, [viewMode, metric, hiddenPoints]);

  const oldCpc = usePrevious(cpc);
  const oldCommCode = usePrevious(commCode);
  React.useEffect(() => {
    setOptions(getOptions());
    if (
      props.full &&
      (!isEqual(cpc, oldCpc) || !isEqual(commCode, oldCommCode))
    ) {
      props.onSettingsChange(
        {
          viewMode,
          metric,
          cpc,
          commCode,
          hiddenPoints,
        },
        true,
      );
    }

    if (!cpc && !commCode) return;

    if (!props.full && (cpc || commCode)) {
      props.loadFullReport({
        viewMode,
        metric,
        cpc,
        commCode,
        hiddenPoints,
      });
    }
  }, [cpc, commCode]);

  if (!data) {
    return null;
  }

  const formatCurrency = variableFormatCurrency(100000000);

  const toggleCommCode = code => {
    if (commCode === code) {
      setCommCode(undefined);
    } else {
      setCommCode(code);
    }
  };

  const metricOptions = {
    customsValue: {
      label: 'Customs Value',
      formatter: value => formatCurrency(value),
    },
    customsDutySaved: {
      label: 'Customs Duty Saved',
      formatter: formatCurrency,
    },
    frequency: {
      label: 'Frequency',
      formatter: value => value,
    },
  };

  const viewModes = {
    top5: {
      label: 'Top 5',
    },
    bottom5: {
      label: 'Bottom 5',
    },
  };

  const getOptions = (): Highcharts.Options => {
    return {
      credits: {
        enabled: false,
      },
      chart: {
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: '',
      },
      tooltip: {
        outside: true,
        padding: 16,
        headerFormat: '',
        pointFormatter: function () {
          return `<b>${this.series.name}: ${metricOptions[metric].formatter(
            this.y,
          )}</b><br><br><b>${this.options.name}:</b> ${
            this.options.description
          }`;
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          // @ts-ignore
          colors: Highcharts.getOptions().colors.map(function (color) {
            return {
              radialGradient: {
                cx: 0.5,
                cy: 0.3,
                r: 0.6,
              },
              stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.2).get('rgb')], // darken
              ],
            };
          }),
          slicedOffset: 20,
          point: {
            events: {
              click: function (event) {
                /*
                if (this.selected) {
                  setCpc(null);
                } else {
                  setCommCode(null);
                  setCpc(this.name);
                }
                 */
                event.preventDefault();
              },

              legendItemClick: function (event) {
                setHiddenPoints(
                  this.series.points
                    .filter(
                      p =>
                        (event.target.name !== p.name && !p.visible) ||
                        (event.target.name === p.name && event.target.visible),
                    )
                    .map(p => p.name),
                );
                event.preventDefault();
              },
            },
          },
          type: 'pie',
          name: `${metricOptions[metric].label}`,
          colorByPoint: true,
          data: data.values[viewMode][metric].map((v, i) => ({
            name: v.customs_procedure_code,
            color: v.color,
            description: v.description,
            y: v.value,
            sliced: cpc === v.customs_procedure_code,
            selected: cpc === v.customs_procedure_code,
            visible: hiddenPoints.indexOf(v.customs_procedure_code) === -1,
          })),
        } as SeriesPieOptions,
      ],
    };
  };

  const tableRows = [
    ...(data.values.codes_in_use || []).map(entry => ({
      id: entry.customs_procedure_code,
      customs_procedure_code: entry.customs_procedure_code,
      duty_saved: entry.duty_saved,
      no_lines: entry.no_lines,
    })),
    (data.values.codes_in_use || []).reduce(
      (acc, entry) => {
        acc.duty_saved += +entry.duty_saved;
        acc.no_lines += +entry.no_lines;
        return acc;
      },
      {
        id: null,
        customs_procedure_code: 'Total',
        duty_saved: 0,
        no_lines: 0,
        classes: 'bold',
      },
    ),
  ];

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box>
        <Grid
          container
          sx={{
            mb: 2,
            justifyContent: {
              xs: 'flex-start',
              lg: 'flex-end',
            },
          }}
          alignItems={'center'}
        >
          <Grid item xs={12} md={12} lg={'auto'}>
            <Typography component={'div'} variant={'subtitle2'}>
              Filter by
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={'auto'}>
            <Box
              sx={{
                ml: { xs: 0, lg: 2 },
                p: 2,
                backgroundColor: 'grey.A100',
              }}
            >
              <FormControl>
                <RadioGroup row>
                  {Object.keys(metricOptions).map(mk => (
                    <FormControlLabel
                      key={`metric-options-${mk}`}
                      control={
                        <Radio
                          color="primary"
                          checked={metric === mk}
                          onClick={() =>
                            setMetric(mk as keyof typeof metricOptions)
                          }
                        />
                      }
                      label={`${metricOptions[mk].label}`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={props.full ? 5 : 12}>
          <Box>
            {!!options && (
              <HighchartsReact
                ref={ref}
                style={{ width: '100%' }}
                key={'cpcAnalysisChart'}
                highcharts={Highcharts}
                options={options}
              />
            )}
          </Box>
        </Grid>
        {!!props.full && (
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  [theme.breakpoints.down('md')]: {
                    position: 'relative',
                  },
                }}
              >
                <ManagementTable
                  breakpoint={'sm'}
                  enableCheckboxSelection
                  disableMultipleSelection
                  defaultRowsSelected={cpc ? [cpc] : []}
                  loading={false}
                  onSelectionChange={ids => {
                    setCpc(ids.length ? (ids[0] as string) : null);
                    if (ids.length) setMetric('customsDutySaved');
                  }}
                  page={1}
                  onChangePage={() => {}}
                  rowsPerPage={1000}
                  rowsPerPageOptions={[]}
                  count={1000}
                  loadingComponent={<LoadingIndicator />}
                  rowClasses={{}}
                  noDataMessage={'No duty savings.'}
                  onClick={r => {
                    //toggleCommCode(r.id as string);
                  }}
                  columns={[
                    {
                      value: 'customs_procedure_code',
                      label: 'CPC',
                    },
                    {
                      value: row => formatCurrency(row.duty_saved),
                      label: 'Duty saved',
                      classes: 'align-right',
                    },
                    {
                      value: 'no_lines',
                      label: 'Lines',
                      classes: 'align-right',
                    },
                  ]}
                  rows={tableRows}
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
