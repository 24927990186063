import React, { useState } from 'react';
import { Autocomplete, TextField, Box, Checkbox, Chip } from '@mui/material';

interface Supplier {
  id: number | null | undefined;
  name: string;
}

interface CustomAutocompleteProps {
  candidateSupplierList: Supplier[];
  selectedSuppliers: Supplier[];
  onChange: (selected: Supplier[]) => void;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  candidateSupplierList,
  selectedSuppliers,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  // Filter options based on the input value
  const filterOptions = candidateSupplierList.filter(option =>
    option.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  // Handle adding/removing selections
  const handleSelect = (option: Supplier) => {
    const isSelected = selectedSuppliers.some(s => s.id === option.id);
    const updatedSelections = isSelected
      ? selectedSuppliers.filter(s => s.id !== option.id) // Remove
      : [...selectedSuppliers, option]; // Add
    onChange(updatedSelections);
  };

  return (
    <Box>
      <Autocomplete
        sx={{ backgroundColor: 'white' }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        options={filterOptions}
        fullWidth
        renderOption={(props, option) => {
          const isSelected = selectedSuppliers.some(s => s.id === option.id);
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              onClick={() => handleSelect(option)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              style={{ ...props.style, padding: '0' }} // Ensure props.style doesn't override
            >
              <Checkbox
                checked={isSelected}
                sx={{ m: 0, mr: 1 }}
                size={'small'}
              />

              {option.name}
            </Box>
          );
        }}
        renderInput={params => (
          <TextField {...params} label="Select supplier names to assign" />
        )}
        getOptionLabel={option => option.name || ''}
      />

      {/* Selected items displayed as chips below */}
      <Box sx={{ marginTop: 2 }}>
        {selectedSuppliers.map(supplier => (
          <Chip
            key={supplier.id}
            label={supplier.name}
            onDelete={() => handleSelect(supplier)}
            sx={{ margin: '4px' }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CustomAutocomplete;
