import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { importMassAdjustmentsSaga } from './saga';
import {
  IAdjustment,
  IImportedMassAdjustment,
  ImportMassAdjustmentsState,
} from './types';
import { ResponseErrorPayload } from 'utils/request';
import { apiCallInitialState, uploadApiCallInitialState } from 'types/ApiCall';
import { AdjustmentReport } from '../../AdjustmentReports/slice/types';

export const initialState: ImportMassAdjustmentsState = {
  importMassAdjustment: uploadApiCallInitialState,
  confirmMassAdjustment: apiCallInitialState,
};

const slice = createSlice({
  name: 'importMassAdjustments',
  initialState,
  reducers: {
    initialise(state, action: PayloadAction<void>) {
      state.importMassAdjustment = initialState.importMassAdjustment;
      state.confirmMassAdjustment = initialState.confirmMassAdjustment;
    },

    importMassAdjustmentRequest(
      state,
      action: PayloadAction<{
        fileContents: string;
        filename: string;
        filesize: number;
        filetype: string;
      }>,
    ) {
      state.importMassAdjustment.loading = true;
      state.importMassAdjustment.error = undefined;
    },
    importMassAdjustmentSuccess(
      state,
      action: PayloadAction<IImportedMassAdjustment>,
    ) {
      state.importMassAdjustment.loading = false;
      state.importMassAdjustment.data = action.payload;
      state.importMassAdjustment.error = undefined;
    },
    importMassAdjustmentFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.importMassAdjustment.loading = false;
      state.importMassAdjustment.error = action.payload;
    },
    importMassAdjustmentProgress(
      state,
      action: PayloadAction<{ progress: number }>,
    ) {
      state.importMassAdjustment.progress = action.payload.progress;
    },

    confirmMassAdjustmentRequest(
      state,
      action: PayloadAction<{ key: string }>,
    ) {
      state.confirmMassAdjustment.loading = true;
      state.confirmMassAdjustment.error = undefined;
    },
    confirmMassAdjustmentSuccess(
      state,
      action: PayloadAction<AdjustmentReport>,
    ) {
      state.confirmMassAdjustment.loading = false;
      state.confirmMassAdjustment.data = action.payload;
      state.confirmMassAdjustment.error = undefined;
    },
    confirmMassAdjustmentFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.confirmMassAdjustment.loading = false;
      state.confirmMassAdjustment.error = action.payload;
    },
  },
});

export const { actions: importMassAdjustmentsActions } = slice;

export const useImportMassAdjustmentsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: importMassAdjustmentsSaga });
  return { actions: slice.actions };
};
