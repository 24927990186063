/**
 *
 * ManageSuppliers
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useManageSuppliersSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectManageSuppliersActions,
  selectManageSuppliersLoadSuppliers,
} from './slice/selectors';
import { Box, Container, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useCustomerSelector } from '../CustomerSelector/useCustomerSelector';
import { useHasChanged } from '../../../utils/usePrevious';
import { ManageSupplier } from './ManageSupplier';
import { AssignSuppliers } from './AssignSuppliers';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

interface Props {}

export function ManageSuppliers(props: Props) {
  interface MatchParams {
    action: string;
  }

  const match = useRouteMatch<MatchParams>();
  const { actions } = useManageSuppliersSlice();
  const suppliers = useSelector(selectManageSuppliersLoadSuppliers);
  const supplierActions = useSelector(selectManageSuppliersActions);
  const dispatch = useDispatch();

  const [supplierName, setSupplierName] = useState<string>('');

  const [assignSupplierData, setAssignSupplierData] = useState<
    { fromIndex: number; toIndex?: number } | undefined
  >();

  const [selectedSupplier, setSelectedSupplier] = useState<{
    id: number | null | undefined;
    name: string;
  } | null>();

  const [assignedSupplier, setAssignedSupplier] = useState<{
    id: number | null | undefined;
    name: string;
  } | null>();

  const currentSupplier = useMemo(() => {
    if (!selectedSupplier) return;
    return suppliers.data.find(s => s.id === selectedSupplier.id);
  }, [selectedSupplier, suppliers.data]);

  const editSupplierLabel = () => {
    if (supplierName !== currentSupplier?.name) {
      dispatch(
        actions.supplierActionsRequest([
          {
            type: 'edit-supplier-label',
            name: supplierName,
            supplier_id: currentSupplier?.id || 0,
          },
        ]),
      );
    }
  };

  const resetName = useCallback(
    (name: string) => {
      dispatch(
        actions.supplierActionsRequest([
          {
            type: 'reset-name',
            name,
          },
        ]),
      );
    },
    [suppliers.data],
  );

  const assignName = useCallback(() => {
    if (!assignedSupplier || !currentSupplier) return;
    dispatch(
      actions.supplierActionsRequest([
        {
          type: 'assign-name',
          name: currentSupplier.name,
          supplier_id: assignedSupplier.id!,
        },
      ]),
    );
  }, [suppliers.data, assignedSupplier, currentSupplier]);

  const supplierList = useMemo(() => {
    return suppliers.data.map(supplier => ({
      id: supplier.id,
      name: `[${supplier.id}] ${supplier.name} (${
        supplier.supplier_names.length
      } name${supplier.supplier_names.length > 1 ? 's' : ''} assigned)`,
    }));
  }, [suppliers.data]);

  const load = (clearData?: boolean) => {
    dispatch(actions.loadSuppliersRequest(clearData));
  };

  const actionsLoadingChanged = useHasChanged(supplierActions.loading);

  useEffect(() => {
    if (actionsLoadingChanged && !supplierActions.loading) {
      load();
    }
  });

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!currentSupplier) {
      setSelectedSupplier(null);
    } else {
      setSelectedSupplier(supplierList.find(s => s.id === currentSupplier.id));
    }
    if (assignedSupplier && currentSupplier) {
      if (
        !supplierList
          .filter(s => s.id !== currentSupplier.id)
          .find(s => s.id === assignedSupplier.id)
      ) {
        setAssignedSupplier(null);
      }
    }
  }, [supplierList]);

  useCustomerSelector(() => load(true));

  useEffect(() => {
    if (currentSupplier) {
      setSupplierName(currentSupplier.name);
      setAssignedSupplier(null);
    }
  }, [currentSupplier]);

  return (
    <Container sx={{ my: 4 }}>
      <Paper square>
        <Box sx={{ p: 2 }}>
          <Typography variant={'h3'}>Supplier Management</Typography>{' '}
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: `grey.A100`, mb: 4 }}>
          <Tabs
            sx={{ px: 2 }}
            onClick={ev => {
              console.log(ev);
            }}
            value={match.params.action}
          >
            <Tab
              value="assign"
              label={'Assign supplier names'}
              component={Link}
              to={'/portal/suppliers/assign'}
            />

            <Tab
              value="manage"
              label={'Manage suppliers'}
              component={Link}
              to={'/portal/suppliers/manage'}
            />
          </Tabs>
        </Box>
        <Box>
          <Switch>
            <Route
              exact
              path={`/portal/suppliers/assign`}
              render={rProps => (
                <AssignSuppliers
                  suppliers={suppliers}
                  supplierActions={supplierActions}
                  actionRequest={supplierActions => {
                    dispatch(actions.supplierActionsRequest(supplierActions));
                  }}
                />
              )}
            />
            <Route
              exact
              path={`/portal/suppliers/manage`}
              render={rProps => (
                <ManageSupplier
                  suppliers={suppliers}
                  supplierActions={supplierActions}
                  actionRequest={supplierActions => {
                    dispatch(actions.supplierActionsRequest(supplierActions));
                  }}
                />
              )}
            />
            <Route render={rProps => <Redirect to={`${match.url}/assign`} />} />
          </Switch>
        </Box>
      </Paper>
    </Container>
  );
}
