/**
 *
 * FullReport
 *
 */
import * as React from 'react';
import { useLocation, useRouteMatch, match } from 'react-router';
import { Report } from '../Report';
import { ReactDOM, useEffect } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loggingProviderActions } from '../LoggingProvider/slice';

interface Props {}

export function FullReport(props: Props) {
  interface MatchParams {
    id: string;
  }
  const match = useRouteMatch<MatchParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loggingProviderActions.pushActionToQueue({
        category: 'analysis',
        action: 'report-view',
        params: match.params.id,
      }),
    );
  }, [dispatch]);

  return (
    <Container>
      <Report id={match.params.id} type={'full'} />
    </Container>
  );
}
