import {
  adaptV4Theme,
  createTheme,
  responsiveFontSizes,
  Theme as MuiTheme,
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { red, yellow } from '@mui/material/colors';
const palette = {
  primary: {
    main: '#3e164a',
  },
  secondary: {
    main: yellow['800'],
  },
  error: {
    main: red['A700'],
  },
};

let muiTheme = createTheme({
  palette,
  typography: {
    fontSize: 12,
    fontFamily: 'inherit',
    htmlFontSize: 16,

    h1: {
      fontSize: 28,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
      color: palette.primary.main,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
      color: palette.primary.main,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
      color: palette.primary.main,
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
      color: palette.primary.main,
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
      color: palette.primary.main,
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },

    subtitle2: {
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

export { muiTheme };

export const formStyles = makeStyles((theme: MuiTheme) => ({
  root: {},
  field: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  formContent: {},

  formContainer: {
    '&.has-actions': {
      paddingBottom: theme.spacing(10),
    },
    position: 'relative',
    zIndex: 1,
  },

  formActions: {
    width: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    '& .align-right': {
      marginLeft: 'auto',
    },
  },
}));

const lightTheme = {
  primary: muiTheme.palette.primary.main,
  text: 'rgba(58,52,51,1)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
};

const darkTheme: Theme = {
  primary: 'rgba(220,120,95,1)',
  text: 'rgba(241,233,231,1)',
  textSecondary: 'rgba(241,233,231,0.6)',
  background: 'rgba(0,0,0,1)',
  backgroundVariant: 'rgba(28,26,26,1)',
  border: 'rgba(241,233,231,0.15)',
  borderLight: 'rgba(241,233,231,0.05)',
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
