import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { apiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authProviderSaga } from './saga';

// The initial state of the AuthProvider container
export const initialState: ContainerState = {
  login: apiCallInitialState,
  loadUser: apiCallInitialState,
  logout: apiCallInitialState,
  forgotPassword: apiCallInitialState,
  resetPassword: apiCallInitialState,
  activateAccount: apiCallInitialState,
  initialised: false,
  loggedIn: false,
  refreshing: false,
  loading: false,
};

const slice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {
    userTokenRefreshRequest(state, action: PayloadAction<void>) {
      state.refreshing = true;
    },
    userTokenRefreshSuccess(state, action: PayloadAction<void>) {
      state.refreshing = false;
    },
    userTokenRefreshFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.refreshing = false;
    },
    userLoginRequest(state, action: PayloadAction<any>) {
      state.login.loading = true;
      state.login.error = undefined;
    },
    userLoginSuccess(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.login.loading = false;
      state.login.error = undefined;
    },
    userLoginFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.login.loading = false;
      state.login.error = action.payload;
    },
    autoLoginRequest(state, action: PayloadAction<{ key: string }>) {
      state.login.loading = true;
      state.login.error = undefined;
    },
    autoLoginSuccess(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.login.loading = false;
      state.login.error = undefined;
    },
    autoLoginFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.login.loading = false;
      state.login.error = action.payload;
    },
    userLogoutRequest(state, action: PayloadAction<void>) {
      state.logout.loading = true;
      state.logout.error = undefined;
    },
    userLogoutSuccess(state, action: PayloadAction<void>) {
      state.loggedIn = false;
      state.logout.loading = false;
      state.logout.error = undefined;
      state.loadUser.data = undefined;
    },
    userLogoutFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loggedIn = false;
      state.logout.loading = false;
      state.logout.error = action.payload;
      state.loadUser.data = undefined;
    },
    loadUserDataRequest(state, action: PayloadAction<void>) {
      state.loadUser.loading = true;
      state.loadUser.error = undefined;
    },
    loadUserDataSuccess(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.initialised = true;
      state.loadUser.loading = false;
      state.loadUser.data = action.payload.data;
      state.loadUser.error = undefined;
    },
    loadUserDataFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadUser.loading = false;
      state.initialised = true;
      state.loadUser.error = action.payload;
    },
    forgotPasswordRequest(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = true;
      state.forgotPassword.error = undefined;
    },
    forgotPasswordSuccess(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = false;
      state.forgotPassword.data = action.payload;
      state.forgotPassword.error = undefined;
    },
    forgotPasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.forgotPassword.error = action.payload;
      state.forgotPassword.loading = false;
    },
    resetPasswordRequest(state, action: PayloadAction<any>) {
      state.resetPassword.loading = true;
      state.resetPassword.error = undefined;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.resetPassword.loading = false;
      state.resetPassword.data = action.payload;
      state.resetPassword.error = undefined;
    },
    resetPasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.resetPassword.error = action.payload;
      state.resetPassword.loading = false;
    },
    activateAccountRequest(
      state,
      action: PayloadAction<{ activation_code: string }>,
    ) {
      state.activateAccount.loading = true;
      state.activateAccount.error = undefined;
    },
    activateAccountSuccess(state, action: PayloadAction<any>) {
      state.activateAccount.loading = false;
      state.activateAccount.data = action.payload;
      state.activateAccount.error = undefined;
    },
    activateAccountFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.activateAccount.error = action.payload;
      state.activateAccount.loading = false;
    },
  },
});

export const { actions: authProviderActions } = slice;

export const useAuthProviderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authProviderSaga });
  return { actions: slice.actions };
};
