import { buffers, END, eventChannel } from 'redux-saga';
import { getApiUrl, getToken } from 'utils/request';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

// TODO - no point in having this removed from the axios request logic - reintegrate

export function createUploadFileChannel(endpoint: string, payload: any) {
  console.log(payload);
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    xhr.timeout = 1000 * 60 * 15;
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    const sendFailed = ({ message = 'Upload failed', code = 500 }) => {
      emitter({ err: { message, code } });
      emitter(END);
    };

    const onFailure = (e: ProgressEvent) => {
      sendFailed({ message: 'Upload failed' });
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true, response });
          emitter(END);
        } else {
          // @ts-ignore
          sendFailed(JSON.parse(response || '{}'));
        }
      }
    };

    xhr.open('POST', getApiUrl(endpoint), true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + getToken());
    xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');

    xhr.send(JSON.stringify(payload));
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
