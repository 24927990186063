import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.manageSuppliers || initialState;

export const selectManageSuppliers = createSelector(
  [selectSlice],
  state => state,
);

export const selectManageSuppliersLoadSuppliers = createSelector(
  [selectSlice],
  state => state.suppliers,
);

export const selectManageSuppliersActions = createSelector(
  [selectSlice],
  state => state.actions,
);
