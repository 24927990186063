import {
  take,
  call,
  put,
  select,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import { manageSuppliersActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectCurrentCustomers } from '../../CustomerSelector/slice/selectors';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { ISupplierAction } from './types';

function* loadSuppliers() {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    const payload = yield call(apiRequest, {
      url: `suppliers/show`,
      method: 'get',
      data: {
        customer_id: currentCustomers[0],
      },
    });

    yield put(actions.loadSuppliersSuccess(payload.data));
  } catch (error) {
    yield put(actions.loadSuppliersFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* supplierActions(action: PayloadAction<ISupplierAction[]>) {
  try {
    const currentCustomers = yield select(selectCurrentCustomers);
    yield call(apiRequest, {
      url: `suppliers/actions`,
      method: 'post',
      data: {
        customer_id: currentCustomers[0],
        actions: action.payload,
      },
    });

    yield put(actions.supplierActionsSuccess());
  } catch (error) {
    yield put(actions.supplierActionsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* manageSuppliersSaga() {
  yield takeLatest(actions.loadSuppliersRequest.type, loadSuppliers);
  yield takeLatest(actions.supplierActionsRequest.type, supplierActions);
}
