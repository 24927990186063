import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.lineAdjustmentManager || initialState;

export const selectAdjustmentIntent = createSelector(
  [selectSlice],
  state => state.adjustmentIntent,
);

export const selectAdjustedItems = createSelector(
  [selectSlice],
  state => state.adjustedItems,
);

export const selectConfirmAdjustment = createSelector(
  [selectSlice],
  state => state.confirmAdjustment,
);
